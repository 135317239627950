import React from "react";
import { reduxForm, Field, hasSubmitSucceeded } from "redux-form";
import { useSelector } from "react-redux";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";

import TextInput from "../../../components/core/form/TextInput";
import LoadingButton from "../../../components/core/buttons/LoadingButton";
import PrimaryButton from "../../../components/core/buttons/PrimaryButton";
import { required, VALIDATION_ERRORS } from "../../../utils/validation";
import { ENTER_NEW_PASSWORD } from "../../../constants/forms";
import { ROUTE_SIGNIN } from "../../../routes/routesStrings";
import { updatePassword } from "../../../actionCreators/auth";
import { withHandleFormError } from "../../../hoc";
import { THEME_ICONS } from "../../../styles/icons";

const validate = ({ password, confirmedPassword }) => {
    const errors = {};

    if (password !== confirmedPassword) {
        errors.confirmedPassword = VALIDATION_ERRORS.passwordConfirm;
    }

    return errors;
};

const SuccessIcon = styled("i")(({ theme, backgroundColor }) => ({
    ...THEME_ICONS.success,
}));

const EnterNewPasswordSuccess = ({ onClick }) => {
    return (
        <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography align="center" variant="h2">
                Password was successfully changed
            </Typography>
            <SuccessIcon />
            <PrimaryButton size="medium" onClick={onClick}>
                go to Login page
            </PrimaryButton>
        </Stack>
    );
};

const EnterNewPassword = ({ handleSubmit, submitting, form }) => {
    const hasSubmitSuccess = useSelector(hasSubmitSucceeded(form));
    const navigate = useNavigate();

    const toLoginPage = () => navigate(ROUTE_SIGNIN);

    if (hasSubmitSuccess) return <EnterNewPasswordSuccess onClick={toLoginPage} />;

    return (
        <form onSubmit={handleSubmit}>
            <Stack direction="column" alignItems="center">
                <Typography>Enter your new password (Must be at least 5 characters)</Typography>
            </Stack>
            <Stack sx={{ my: 2 }}>
                <Field name="password" type="password" label="Password" component={TextInput} validate={required} />
            </Stack>
            <Stack sx={{ my: 2 }}>
                <Field
                    name="confirmedPassword"
                    type="password"
                    label="Confirm Password"
                    component={TextInput}
                    validate={required}
                />
            </Stack>
            <Stack direction="row" justifyContent="center" sx={{ my: 2 }}>
                <LoadingButton loading={submitting} type="submit" size="large">
                    APPLY
                </LoadingButton>
            </Stack>
        </form>
    );
};

export default compose(
    reduxForm({
        form: ENTER_NEW_PASSWORD,
        validate,
        onSubmit: (values, dispatch, { token }) => dispatch(updatePassword({ ...values, token })),
    }),
    withHandleFormError,
)(EnterNewPassword);
