import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import clsx from "clsx";
import { connect } from "react-redux";
import { change, formValueSelector } from "redux-form";

import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

import UnitOfMeasure from "./UnitOfMeasure";
import { UNIT_OF_MEASURE_BY_ID } from "../../constants/global";
import { getUnitOfMeasureValue } from "../../utils";

const useStyles = makeStyles((theme) => ({
    inBuildSwitcher: {
        width: "auto",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: "nowrap",
    },
    infiniteIcon: {
        height: 34,
        width: 30,
        position: "relative",
        top: 2,
        marginRight: 4,
        cursor: "pointer",

        "&.-disabled": {
            color: theme.palette.secondary.superLight,
        },

        "&.-active, &:hover": {
            color: theme.palette.general.priceGreen,
        },
    },
}));

const UnlimitedAdornment = (props) => {
    const classes = useStyles();
    const {
        dispatch,
        form,
        formValues: { unlimited, unitOfMeasure: unitValue },
        quantityName,
        disabled,
        setUnlimitedHandler,
    } = props;
    const unitOfMeasure = unitValue ? (_.isString(unitValue) ? getUnitOfMeasureValue(unitValue) : +unitValue) : null;

    const setUnlimited = () => {
        if (disabled) {
            return false;
        }

        const value = !unlimited;
        dispatch(change(form, "unlimited", value));
        dispatch(change(form, quantityName, null));
        setUnlimitedHandler && setUnlimitedHandler(value);
    };

    return (
        <Grid container className={clsx(classes.inBuildSwitcher)}>
            {!!unitOfMeasure && (
                <React.Fragment>
                    <Grid item>
                        <UnitOfMeasure value={UNIT_OF_MEASURE_BY_ID[unitOfMeasure]} />
                    </Grid>
                    <Grid item>&nbsp;&nbsp;/&nbsp;&nbsp;</Grid>
                </React.Fragment>
            )}
            <Grid item>
                <AllInclusiveIcon
                    onClick={setUnlimited}
                    title={"Unlimited"}
                    className={clsx(classes.infiniteIcon, unlimited && "-active", disabled && "-disabled")}
                />
            </Grid>
        </Grid>
    );
};

UnlimitedAdornment.propTypes = {
    form: PropTypes.string.isRequired,
    quantityName: PropTypes.string.isRequired,
    unlimited: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    setUnlimitedHandler: PropTypes.func,
    disabled: PropTypes.bool,
};

UnlimitedAdornment.defaultProps = {
    unlimited: false,
};

export default connect((state, props) => {
    const formSelector = formValueSelector(props.form);

    return {
        formValues: formSelector(state, "unitOfMeasure", "unlimited"),
    };
})(UnlimitedAdornment);
