import React from "react";
import { useLocation } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { Container, Stack } from "@mui/material";

import { Logo } from "../../assets";
import { EnterEmail, EnterNewPassword } from "./views";

const ContentStyled = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));

function ForgotPassword(props) {
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get("id");

    return (
        <Container maxWidth="sm">
            <ContentStyled>
                <Stack direction="row" justifyContent="center" sx={{ mb: 10 }}>
                    <Logo />
                </Stack>
                {token ? <EnterNewPassword token={token} /> : <EnterEmail />}
            </ContentStyled>
        </Container>
    );
}

export default ForgotPassword;
