export const SITES_FILTERS = [
    {
        key: "accessType",
        label: "Site Types",
        single: true,
        items: [
            {
                label: "Private",
                value: 2,
            },
            {
                label: "Public",
                value: 1,
            },
        ],
    },
    {
        key: "status",
        label: "Site Status",
        items: [
            {
                label: "Active",
                value: 1,
            },
            {
                label: "Archive",
                value: 3,
            },
        ],
    },
];

export const DEFAULT_SITES_FILTERS = {
    accessType: [2],
    status: [1],
};
