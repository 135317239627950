import moment from "moment";
import { DEFAULT_DATE_TIME_FORMAT, UNIT_OF_MEASURE_LABELS } from "../constants/global";

export class RequestFormInitialValuesDTO {
    constructor(values, timezone) {
        this.jobContact = values?.jobContact?.fullName;
        this.material = {
            label: values.poLineItem.payload.name,
            value: values.poLineItem.id,
        };
        this.totalQuantity = values.totalQuantity || null;
        this.unlimited = values.unlimited;
        this.extRef = values.extRef;
        this.unitOfMeasure = {
            label: UNIT_OF_MEASURE_LABELS[values.unitOfMeasure],
            value: values.unitOfMeasure,
        };
        this.dropOffSites = values.dropOffSites && values?.dropOffSites.map((i) => ({ value: i.id, label: i.name }));
        this.startDate = values.startDate
            ? moment(values.startDate).tz(timezone).format(DEFAULT_DATE_TIME_FORMAT)
            : moment(new Date()).tz(timezone).format(DEFAULT_DATE_TIME_FORMAT);
        this.requestId = values.id;
        this.requestNotes = values.requestNotes;
    }
}
