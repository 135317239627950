import React from "react";

import TabsCustom from "../../../components/core/TabsCustom";
import { useActions } from "../../../hooks";
import { styled } from "@mui/material/styles";
import { selectMyTeamActiveOrder } from "../../../slices/myCompanySlice";
import { useSelector } from "react-redux";
import { LAST_LOGIN_ORDER } from "./MyTeamList";

const StyledMyTeamTabsContainer = styled("div")(({ theme }) => ({
    width: "50%",
    [theme.breakpoints.down(600)]: {
        width: "100%",
    },
}));

const MyTeamTabs = () => {
    const activeOrder = useSelector(selectMyTeamActiveOrder);
    const { refreshMyTeamList, updateMyTeamActiveTab, resetMyTeamSortingParam } = useActions();

    const handleTabChange = (updatedTab) => {
        updateMyTeamActiveTab(updatedTab);
        if (activeOrder.includes(LAST_LOGIN_ORDER)) {
            resetMyTeamSortingParam();
        }
        refreshMyTeamList();
    };

    return (
        <StyledMyTeamTabsContainer>
            <TabsCustom
                variant="fullWidth"
                tabsHeaders={[{ label: "Active" }, { label: "Pending" }, { label: "Rejected" }]}
                onChangeTab={handleTabChange}
            />
        </StyledMyTeamTabsContainer>
    );
};

export default MyTeamTabs;
