import { getFormValues } from "redux-form";

import * as api from "../api/myCompany";
import { fetchUsers, userSignUpDecisionRequest } from "../api/myCompany";
import {
    actions,
    selectMyCompaniesMeta,
    selectMyTeamActiveOrder,
    selectMyTeamActiveTab,
    selectMyTeamMeta,
} from "../slices/myCompanySlice";
import { handleError } from "../utils";
import { MY_COMPANIES_FILTERS, MY_TEAM_FILTERS } from "../constants/forms";
import {
    ACTIVE_TAB_INDEX,
    ARCHIVE_TAB_INDEX,
    IS_ADMIN_USER,
    PENDING_TAB_INDEX,
    USER_ROLE_SUB_COMPANY_CUSTOMER,
} from "../constants/global";
import { selectAccountRole, selectCompanyId } from "../slices/userSlice";
import handleFormErrors from "../utils/handleFormErrors";
import { showErrorSnackBar, showSuccessSnackBar } from "./notifications";
import { DECISION_APPROVE, DECISION_KEY, DECISION_REJECT } from "../pages/MyCompany/MyTeam/SignUpApprovalButtons";

export const getMyTeamUsers = () => async (dispatch, getState) => {
    const state = getState();
    const formValues = getFormValues(MY_TEAM_FILTERS)(state);
    const activeOrder = selectMyTeamActiveOrder(state);
    const role = selectAccountRole(state);
    const activeTab = selectMyTeamActiveTab(state);
    const isAdmin = IS_ADMIN_USER(role);

    try {
        const { page, hasMore } = selectMyTeamMeta(state);
        if (hasMore) {
            const params = {
                page,
            };

            if (formValues?.keywords) {
                params.keywords = formValues.keywords;
            }
            if (activeOrder) {
                params.order = activeOrder;
            }
            if (isAdmin) {
                if (activeTab === ACTIVE_TAB_INDEX) {
                    params.isApproved = true;
                }
                if (activeTab === PENDING_TAB_INDEX) {
                    params.isNeedConfirm = true;
                }
                if (activeTab === ARCHIVE_TAB_INDEX) {
                    params.isRejected = true;
                }
                params.withAffiliatedCompanies = true;
                params.withWithoutCompanies = true;
            }

            dispatch(actions.requestMyTeam());
            const response = await api.fetchUsers(params);
            dispatch(actions.resultMyTeam(response));
        }
    } catch (e) {
        dispatch(actions.resultMyTeam(handleError(e)));
    }
};

export const refreshMyTeamList = () => (dispatch) => {
    dispatch(actions.refreshMyTeam());
    dispatch(getMyTeamUsers());
};

export const getMyCompanies = () => async (dispatch, getState) => {
    const state = getState();
    const formValues = getFormValues(MY_COMPANIES_FILTERS)(state);
    const companyId = selectCompanyId(state);

    try {
        const { page, hasMore } = selectMyCompaniesMeta(state);
        if (hasMore) {
            const params = {
                page,
                parentCompanies: companyId,
            };

            if (formValues?.keywords) {
                params.keywords = formValues.keywords;
            }

            dispatch(actions.requestMyCompanies());
            const response = await api.fetchCompanies(params);
            dispatch(actions.resultMyCompanies(response));
        }
    } catch (e) {
        dispatch(actions.resultMyCompanies(handleError(e)));
    }
};

export const refreshMyCompaniesList = () => (dispatch) => {
    dispatch(actions.resetMyCompaniesState());
    dispatch(getMyCompanies());
};

export const handleMyTeamUsersSort = (sortParam) => (dispatch) => {
    dispatch(actions.refreshMyTeamMeta());
    dispatch(actions.updateMyTeamSortingParam(sortParam));
    dispatch(getMyTeamUsers());
};

export const inviteCompany = (values) => async () => {
    try {
        await api.inviteCompany(values);
    } catch (e) {
        handleFormErrors(e);
    }
};

export const createMyTeamUser = (values) => async (dispatch, getState) => {
    const userData = {
        ...values,
        company: values.company.value,
        username: values.email,
        role: USER_ROLE_SUB_COMPANY_CUSTOMER,
    };
    try {
        const { data } = await api.createUserRequest(userData);

        dispatch(actions.updateMyTeamSuccessMessage("User Was Created"));
        dispatch(refreshMyTeamList());
        return data;
    } catch (e) {
        handleFormErrors(e);
    }
};

export const approveUserSignUp =
    (userId, userData = null) =>
    async (dispatch, getState) => {
        try {
            if (userData) {
                await dispatch(updateMyTeamUser(userId, userData));
            }

            await userSignUpDecisionRequest(userId, { [DECISION_KEY]: DECISION_APPROVE });

            dispatch(showSuccessSnackBar("User Sign Up Approved"));
            dispatch(refreshMyTeamList());
            dispatch(getHasUnapprovedUsers());
        } catch (e) {
            dispatch(showErrorSnackBar(handleError(e).message));
        }
    };

export const rejectUserSignUp = (userId) => async (dispatch, getState) => {
    try {
        await userSignUpDecisionRequest(userId, { [DECISION_KEY]: DECISION_REJECT });

        dispatch(showSuccessSnackBar("User Sign Up Rejected"));
        dispatch(refreshMyTeamList());
        dispatch(getHasUnapprovedUsers());
    } catch (e) {
        dispatch(showErrorSnackBar(handleError(e).message));
    }
};

export const getHasUnapprovedUsers = () => async (dispatch, getState) => {
    try {
        const { meta } = await fetchUsers({
            perPage: 1,
            isNeedConfirm: true,
            archived: false,
            withAffiliatedCompanies: true,
            withWithoutCompanies: true,
        });
        return dispatch(actions.updateUnapprovedUsersCount(meta.count));
    } catch (e) {
        dispatch(showErrorSnackBar(handleError(e).message));
    }
};

export const updateMyTeamUser = (userId, userData) => async (dispatch, getState) => {
    try {
        const { data } = await api.updateUserRequest(userId, userData);

        return data;
    } catch (e) {
        dispatch(showErrorSnackBar(handleError(e).message));
    }
};
