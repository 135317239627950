import { createSlice } from "@reduxjs/toolkit";

import { resetState } from "./actions";

const initialState = {
    requests: {
        data: null,
        initialLoading: false,
        meta: {
            loading: false,
            error: null,
            successMessage: null,
            page: 1,
            count: 0,
            hasMore: true,
        },
    },
    currentRequest: {
        data: null,
        meta: {
            loading: false,
            error: null,
        },
    },
    turnTime: {
        data: null,
        meta: {
            loading: false,
            error: null,
        },
    },
};
export const requestsSlice = createSlice({
    name: "requests",
    initialState,
    reducers: {
        requestRequests: (state) => {
            state.requests.meta.loading = true;
        },
        resultRequests: (state, { payload }) => {
            if (payload.error) {
                state.requests.meta.error = payload.message;
                state.requests.meta.loading = false;
            } else {
                const { data, meta } = payload;
                state.requests.meta = {
                    ...meta,
                    loading: false,
                    page: meta.page + 1,
                    hasMore: !(data.length < 20),
                };
                if (meta.page === 1) {
                    state.requests.data = data;
                } else {
                    state.requests.data.push(...data);
                }
            }
        },
        refreshRequests: (state) => {
            state.requests = initialState.requests;
        },
        resetRequests: (state) => {
            state.requests = initialState.requests;
        },
        requestDetails: (state, { payload }) => {
            state.currentRequest.meta.loading = true;
            state.currentRequest.data = { id: payload };
        },
        resultDetails: (state, { payload }) => {
            if (payload.error) {
                state.currentRequest.meta.error = payload.message;
                state.currentRequest.meta.loading = false;
            } else {
                state.currentRequest.meta.loading = false;
                state.currentRequest.data = payload;
            }
        },
        resetDetails: (state) => {
            state.currentRequest = initialState.currentRequest;
        },
        requestTurnTime: (state, { payload }) => {
            state.currentRequest.meta.loading = true;
            state.currentRequest.data = { id: payload };
        },
        resultTurnTime: (state, { payload }) => {
            state.currentRequest.meta.loading = false;
            state.currentRequest.data = payload;
        },
        updateRequestSuccessMessage: (state, { payload }) => {
            state.requests.successMessage = payload;
        },
    },
    extraReducers: (builder) => {
        return builder.addCase(resetState, () => initialState);
    },
});

const actions = requestsSlice.actions;

export { actions };

export const selectRequests = (state) => state.requests.requests.data;
export const selectRequestsMeta = (state) => state.requests.requests.meta;
export const selectRequestsSuccessMessage = (state) => state.requests.requests.successMessage;

export const selectRequestDetails = (state) => state.requests.currentRequest.data;
export const selectRequestDetailsMeta = (state) => state.requests.currentRequest.meta;

export default requestsSlice.reducer;
