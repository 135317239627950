import React from "react";
import { reduxForm } from "redux-form";
import { useSelector } from "react-redux";

import { Divider, Grid, Stack } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "@mui/material/Button";

import { useActions } from "../../../hooks";
import FormDialog from "../../../components/core/FormDialog";
import CreateUserForm from "./CreateUserForm";
import { IS_ADMIN_USER } from "../../../constants/global";
import { selectAccountRole } from "../../../slices/userSlice";
import { CREATE_USER_FORM, MY_TEAM_FILTERS } from "../../../constants/forms";
import { Filters } from "../../../components";

function MyTeamFilters({ form, reset }) {
    const { refreshMyTeamList } = useActions();
    const role = useSelector(selectAccountRole);
    const isAdmin = IS_ADMIN_USER(role);

    return (
        <>
            <Grid container alignItems="center" sx={{ my: 1 }}>
                <Grid item xs={12} sm={6}>
                    {isAdmin && (
                        <FormDialog
                            maxWidth={"sm"}
                            fullWidth
                            dialogTitle={<div className="--text-center">CREATE USER</div>}
                            form={CREATE_USER_FORM}
                            OpenModalComponent={(props) => (
                                <Stack direction="row" alignItems="center">
                                    <Button variant="text" startIcon={<AddCircleOutlineIcon />} {...props}>
                                        Create new team member
                                    </Button>
                                </Stack>
                            )}
                        >
                            {(props) => <CreateUserForm {...props} />}
                        </FormDialog>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Filters form={form} request={refreshMyTeamList}>
                        <Stack direction="row" alignItems="center">
                            <Stack sx={{ flex: 1 }}>
                                <Filters.SearchField />
                            </Stack>
                            <Filters.RefreshButton />
                        </Stack>
                    </Filters>
                </Grid>
            </Grid>
            <Divider />
        </>
    );
}

export default reduxForm({
    form: MY_TEAM_FILTERS,
})(MyTeamFilters);
