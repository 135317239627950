import { actions } from "../slices/notificationsSlice";

export const showSuccessSnackBar =
    (message, options = {}, key = null) =>
    (dispatch, getState) => {
        dispatch(actions.showSnackbar({ message: message, key, options: { variant: "success", ...options } }));
    };

export const showErrorSnackBar =
    (message, options = {}, key = null) =>
    (dispatch, gesState) => {
        dispatch(actions.showSnackbar({ message: message, key, options: { variant: "error", ...options } }));
    };
