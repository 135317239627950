import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    // toast messages
    snackbars: [],

    // notifications
    notifications: [],
};
export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        showSnackbar: (state, { payload: notification }) => {
            // is key wasn't specified
            if (!notification.key) {
                notification.key = new Date().getTime() + Math.random();
            }
            state.snackbars.push(notification);
        },
        closeSnackbar: (state, { payload }) => {
            state.snackbars = state.snackbars.map((notification) => {
                if (notification.key === payload) {
                    return { ...notification, dismissed: true };
                }
                return notification;
            });
        },
        removeSnackbar: (state, { payload }) => {
            state.snackbars = state.snackbars.filter((notification) => notification.key !== payload);
        },
        addNotification: (state, { payload }) => {
            state.notifications.unshift(payload);
        },
        resetNotifications: (state) => {
            state.notifications = initialState.notifications;
            state.snackbars = initialState.snackbars;
        },
    },
});

const actions = notificationsSlice.actions;

export const selectSnackbars = (state) => state.notifications.snackbars;
export const selectNotifications = (state) => state.notifications.notifications;

export { actions };

export default notificationsSlice.reducer;
