import React from "react";

import { makeStyles } from "@mui/styles";

const emptySubNavStyles = makeStyles((theme) => ({
    emptySubNav: {
        backgroundColor: theme.palette.secondary.superDark,
        height: 67,
        display: "flex",
        position: "relative",
        justifyContent: "flex-start",
    },
}));

const EmptySubNav = () => {
    const classes = emptySubNavStyles();

    return <div className={classes.emptySubNav} />;
};

export default EmptySubNav;
