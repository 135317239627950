import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { change, formValueSelector, getFormSubmitErrors, reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { Alert, Box, Snackbar, Stack, Step, StepLabel, Stepper } from "@mui/material";
import { SIGN_UP_FORM } from "../../../constants/forms";
import { PrimaryButton } from "../../../components";
import { ButtonsGroup } from "../../../components/core/buttons/ButtonsGroup/buttonsGroup";
import { SimpleLink } from "../../../components/core/link";
import CompanyContactInfoForm, { EXISTING_COMPANY_VALUE } from "./CompanyContactInfoForm";
import TermsAndConditionsForm from "./TermsAndConditionsForm";
import { VALIDATION_ERRORS } from "../../../utils/validation";
import UserInfoForm from "./UserInfoForm";
import { Logo } from "../../../assets";
import { ROUTE_SIGNIN } from "../../../routes/routesStrings";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import Loader from "../../../components/core/loader";
import { signUp } from "../../../actionCreators/user.js";

const steps = ["Company Contact info", "Terms and Conditions", "User Info"];

const validate = ({ acceptTermsAndConditions, password, passwordConfirm }) => {
    const errors = {};
    if (!acceptTermsAndConditions) {
        errors.thirdStepError = "Please accept Terms and Conditions to continue.";
    }

    if (password !== passwordConfirm) {
        errors.passwordConfirm = VALIDATION_ERRORS.passwordConfirm;
    }

    return errors;
};

function SignUpForm({ handleSubmit, form, submitting, submit, error, ...restProps }) {
    const submitErros = useSelector(getFormSubmitErrors(form));
    const submitSuccess = useSelector((state) => formValueSelector(form)(state, "submitSuccess"));
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (submitErros.userContactPhone) {
            setActiveStep(0);
        }
    }, [submitErros.userContactPhone]);

    const lastStep = activeStep === steps.length - 1;

    const setStateValue = (value) => {
        dispatch(change(form, "state", { label: value.abbreviation, value: value.value }));
    };

    const handleNext = (values) => {
        setActiveStep(activeStep + 1);
    };

    const onSubmit = lastStep ? submit : handleNext;

    const handleBack = () => {
        setActiveStep((step) => step - 1);
    };

    const returnToLogin = () => {
        navigate(ROUTE_SIGNIN);
    };

    const renderSignUpStep = () => {
        switch (activeStep) {
            case 0: {
                return <CompanyContactInfoForm setStateValue={setStateValue} form={form} />;
            }
            case 1: {
                return <TermsAndConditionsForm />;
            }
            case 2: {
                return <UserInfoForm />;
            }
        }
    };

    return (
        <div className={clsx(submitting && LOADER_WHITE_OVERLAY)}>
            {submitting && <Loader />}
            <form onSubmit={handleSubmit(onSubmit)}>
                {submitSuccess ? (
                    <React.Fragment>
                        <Box className="--text-center">
                            <h1>Congratulations!</h1>
                            <br />
                            Please check your e-mail to finish registration.
                        </Box>
                        <br />
                        <ButtonsGroup className="buttons-set">
                            <PrimaryButton type="button" onClick={returnToLogin} size={"large"}>
                                Return to Login page
                            </PrimaryButton>
                        </ButtonsGroup>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Stack direction="row" justifyContent="center">
                            <Logo />
                        </Stack>
                        <br />
                        <div>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <div className="--full-width content-container">
                                {renderSignUpStep()}
                                <div>
                                    <br />
                                    <ButtonsGroup>
                                        <PrimaryButton size={"small"} disabled={activeStep === 0} onClick={handleBack}>
                                            Back
                                        </PrimaryButton>
                                        <PrimaryButton size={"small"} type="submit">
                                            {activeStep === steps.length - 1 ? "Finish" : "Next"}
                                        </PrimaryButton>
                                    </ButtonsGroup>
                                </div>
                                <br />
                            </div>
                        </div>
                        <div className="--text-center">
                            <SimpleLink to={ROUTE_SIGNIN}>Return to Login page</SimpleLink>
                        </div>
                        <br />
                    </React.Fragment>
                )}
            </form>
            <Snackbar open={!!error} autoHideDuration={6000}>
                <Alert severity="error" variant="filled">
                    {error}
                </Alert>
            </Snackbar>
        </div>
    );
}

const onSubmit = (values, dispatch) => dispatch(signUp(values));

const onSubmitSuccess = (result, dispatch, { change }) => {
    if (result?.status === 200) dispatch(change("submitSuccess", true));
};

export default reduxForm({
    form: SIGN_UP_FORM,
    onSubmit,
    onSubmitSuccess,
    initialValues: {
        companyExisting: EXISTING_COMPANY_VALUE,
    },
    validate,
})(SignUpForm);
