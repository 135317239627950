// @flow
import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Payloads from "./Payloads";
import { selectCurrentProject, selectCurrentProjectMeta, selectPayloads } from "../../../slices/projectsSlice.js";
import { useActions } from "../../../hooks";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
} from "../../../components/core/form/AsyncAutocompleteComponent";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global.js";
import Loader from "../../../components/core/loader";

function ProjectDetails(props) {
    const currentProject = useSelector(selectCurrentProject) || {};
    const { loading } = useSelector(selectCurrentProjectMeta);
    const poLiItems = useSelector(selectPayloads);

    const { getCurrentProject, resetPayloads, getPayloads } = useActions();

    useEffect(() => {
        if (currentProject && currentProject.id) {
            resetPayloads();
            getPayloads();
        }
    }, [currentProject?.id]);

    return (
        <Box component="main" sx={{ flex: 1, p: 2 }} className={loading && LOADER_WHITE_OVERLAY}>
            {loading && <Loader />}
            <Typography variant="h1" sx={{ my: "18px" }}>
                {currentProject.name}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
                <Field
                    name="customer"
                    placeholder="Customer"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    // onFieldChange={setStateValue}
                    loadOptions={() => Promise.resolve([])}
                    component={AsyncAutocompleteComponent}
                />
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
                <Field
                    name="jobContact"
                    placeholder="Foreman / Point-of-Conact"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    // onFieldChange={setStateValue}
                    loadOptions={() => Promise.resolve([])}
                    component={AsyncAutocompleteComponent}
                />
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
                <Field
                    name="terms"
                    placeholder="Terms"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    // onFieldChange={setStateValue}
                    loadOptions={() => Promise.resolve([])}
                    component={AsyncAutocompleteComponent}
                />
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
                <Field
                    name="pavertrackers"
                    placeholder="Pavertracker(s)"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    // onFieldChange={setStateValue}
                    loadOptions={() => Promise.resolve([])}
                    component={AsyncAutocompleteComponent}
                    isMulti
                />
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
                <Field
                    name="region"
                    placeholder="Region"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    // onFieldChange={setStateValue}
                    loadOptions={() => Promise.resolve([])}
                    component={AsyncAutocompleteComponent}
                />
            </Stack>
            <Payloads poLiItems={poLiItems} projectName={currentProject.name} projectId={currentProject.id} />
        </Box>
    );
}

export default compose(
    connect((state) => {
        const project = selectCurrentProject(state);

        return {
            initialValues: project && {
                customer: project.customer && {
                    value: project.customer.id,
                    label: project.customer.name,
                },
                terms: project.terms && {
                    value: project.terms.id,
                    label: project.terms.name,
                },
                jobContact: project.jobContact && {
                    value: project.jobContact.id,
                    label: project.jobContact.fullName,
                },
                region: project.region && {
                    value: project.region.id,
                    label: project.region.regionName,
                },
                pavertrackers: project.paverTrackers && project.paverTrackers.map((i) => ({ id: i, label: i })),
            },
        };
    }),
    reduxForm({ form: "Project Details", enableReinitialize: true }),
)(ProjectDetails);
