import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const StyledSearchTextField = styled(TextField)(({}) => ({
    width: "100%",
    justifyContent: "center",
    ".MuiInputBase-input": { padding: "7px 0px 7px 7px" },
    ".MuiSvgIcon-root": { width: 20 },
}));

const SearchTextField = ({ handleTextChange, input, meta }) => {
    return (
        <StyledSearchTextField
            {...input}
            placeholder="Type to search"
            variant="outlined"
            onChange={(e) => {
                input.onChange(e);
                handleTextChange && handleTextChange();
            }}
        />
    );
};

export default SearchTextField;
