import _ from "lodash";
import { getValidCoordinates } from "../helpers/sites";

const minLength = (min) => (value) => {
    return value && value.length < min ? VALIDATION_ERRORS.minLength(min) : undefined;
};

const maxLength = (max) => (value) => {
    return value && value.length > max ? VALIDATION_ERRORS.maxLength(max) : undefined;
};

export const required = (value) => (value ? undefined : "Required");
export const number = (value) => (value && isNaN(Number(value)) ? "Must be a number" : undefined);
export const email = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? "Invalid email address" : undefined;
export const noSpecialSymbols = (value) => {
    if (!value || _.isNumber(value)) {
        return undefined;
    }

    const notValid = /[\\`*[\];:"|,./=?]/.test(value.split(" ").join(""));

    return !value ? undefined : notValid ? VALIDATION_ERRORS.specialSymbols : undefined;
};

export const Validation = {
    required: (value) => {
        return (value || value === 0 || value === false) &&
            (_.isString(value) ? value.trim() : true) &&
            (_.isNumber(value) ? true : !_.isEmpty(value) || (value.toString() && value !== ""))
            ? undefined
            : VALIDATION_ERRORS.required;
    },
    password: (value) => (!value ? undefined : value.length >= 5 ? undefined : VALIDATION_ERRORS.passwordMinLengthFive),
    email: (value) =>
        value
            ? value.match(/^(?!\.)^["'A-Z0-9._%+-]*(["'A-Z0-9_%+-])@([\w]+[\w+-])*[\w]+(\.([A-Z0-9]){2,4})$/i)
                ? undefined
                : VALIDATION_ERRORS.email
            : undefined,
    noSpecialSymbols: (value) => {
        if (!value || _.isNumber(value)) {
            return undefined;
        }

        const notValid = /[\\`*[\];:"|,./=?]/.test(value.split(" ").join(""));

        return !value ? undefined : notValid ? VALIDATION_ERRORS.specialSymbols : undefined;
    },
    coordinates: (value) => {
        return value ? (getValidCoordinates(value) ? undefined : VALIDATION_ERRORS.coordinates) : undefined;
    },
    phoneNumberSymbols: (value) => {
        let cleanedValue = _.clone(value);
        if (cleanedValue && cleanedValue[0] && cleanedValue[0] === "+") {
            cleanedValue = cleanedValue.replace("+", "");
        }
        if (_.isInteger(+cleanedValue)) {
            return undefined;
        }

        return VALIDATION_ERRORS.phone;
    },
    minLength2: minLength(2),
    minLength4: minLength(4),
    minLength6: minLength(6),
    maxLength16: maxLength(16),
    maxLength64: maxLength(64),
    maxLength100: maxLength(100),
};

export const VALIDATION_ERRORS = {
    specialSymbols: 'Special symbols (* . " / \\ [ ] : ; | , = ?) are not allowed',
    minLength: (min) => `Must be ${min} characters or more`,
    maxLength: (max) => `Must be ${max} characters or less`,
    passwordConfirm: "Passwords should match",
    required: "Required",
    email: "Please enter valid email",
    coordinates: "Invalid coordinates format",
    phone: "Invalid phone number",
};
