import {
    WEBSOCKET_EVENT_TYPE_AFFILIATED_COMPANY_USER_SIGN_UP,
    WEBSOCKET_EVENT_TYPE_UNKNOWN,
} from "../../../constants/global";

const notificationTitles = {
    [WEBSOCKET_EVENT_TYPE_UNKNOWN]: "Unknown event",
    [WEBSOCKET_EVENT_TYPE_AFFILIATED_COMPANY_USER_SIGN_UP]: "New user |{username}| under |{company_name}| signed up",
};

const getNotificationTitle = (event) => {
    const { company_name, username } = event;

    return notificationTitles[event.type]
        .replace("{username}", username)
        .replace("{company_name}", company_name || "N/A");
};

export const processAffiliatedCompanyEvent = ({ event }) => {
    return {
        ...event,
        notificationTitle: getNotificationTitle(event),
    };
};
