import dataProvider from "./httpRequest";
import { COMPANY_ENDPOINTS } from "./endpoints";

export const fetchAffiliatedCompanies = async (companyId, params) => {
    return await dataProvider({
        url: COMPANY_ENDPOINTS.AFFILIATED_COMPANIES(companyId),
        method: "GET",
        params,
    });
};
