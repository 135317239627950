import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { Link, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const simpleLinkStyles = makeStyles((theme) => ({
    simpleLink: {
        cursor: "pointer",
        color: theme.palette.text.primary,
        padding: 0,
        fontSize: theme.typography.fontSize,
        textTransform: "none",
        textDecoration: "underline",

        "&:hover": {
            textDecoration: "none",
        },

        "&.-primary-color": {
            color: theme.palette.primary.main,
        },
    },
    disabledLink: {
        cursor: "not-allowed",
    },
}));

export const SimpleLink = (props) => {
    const { component, onClick, to, children, otherProps, className, disabled, tooltipTitle } = props;
    const classes = simpleLinkStyles();

    return to ? (
        <RouterLink to={to} className={clsx(classes.simpleLink, className)} {...otherProps}>
            {children}
        </RouterLink>
    ) : (
        <Tooltip title={tooltipTitle || false} arrow disableHoverListener={!disabled}>
            <Link
                component={component}
                onClick={!disabled && onClick && onClick}
                className={clsx(classes.simpleLink, className, disabled ? classes.disabledLink : null)}
                {...otherProps}
            >
                {children}
            </Link>
        </Tooltip>
    );
};

SimpleLink.defaultProps = {
    component: "a",
    otherProps: {},
};

const actionLinkStyles = makeStyles((theme) => ({
    actionLink: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        color: theme.palette.primary.main,
        width: "100%",
        minHeight: 32,

        "& svg, & a": {
            color: "inherit",
        },

        "& a": {
            marginLeft: 10,
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: theme.typography.fontSize,
        },
    },
}));

export const ActionLink = (props) => {
    const classes = actionLinkStyles();
    const { children, className, otherProps } = props;

    return (
        <div className={clsx(classes.actionLink, "action-link", className)} {...otherProps}>
            {children}
        </div>
    );
};

ActionLink.defaultProps = {
    component: "a",
    otherProps: {},
};
