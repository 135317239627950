export const ROUTE_SIGNIN = "/signin";
export const ROUTE_SIGNUP = "/signup";

export const ROUTE_FORGOT_PASSWORD = "/forgot-password";

export const ROUTE_REQUESTS = "/requests";
export const ROUTE_REQUEST_DETAILS = "/requests/:id";

export const PROJECTS_AND_SITES_ROUTE = "/projects-and-sites";
export const ROUTE_CREATE_REQUEST = "/create-request";

export const ROUTE_SITE_DETAILS = "/sites/:id";

export const ROUTE_PROJECTS_AND_SITES = {
    ROOT: PROJECTS_AND_SITES_ROUTE,
    PROJECTS: `${PROJECTS_AND_SITES_ROUTE}/projects`,
    SITES: `${PROJECTS_AND_SITES_ROUTE}/sites`,
};

export const myCompanyRoute = "/my-company";
export const ROUTE_MY_COMPANY = {
    ROOT: myCompanyRoute,
    MY_TEAM: `${myCompanyRoute}/my-team`,
    ALL_COMPANIES: `${myCompanyRoute}/all-companies`,
};
