import React from "react";
import { Container } from "@mui/material";
import MyCompaniesList from "./MyCompaniesList";
import MyCompaniesFilters from "./MyCompaniesFilters";

const Companies = () => {
    return (
        <Container maxWidth={false}>
            <MyCompaniesFilters />
            <MyCompaniesList />
        </Container>
    );
};

export default Companies;
