import React from "react";
import { compose } from "recompose";
import { Field, reduxForm } from "redux-form";

import Stack from "@mui/material/Stack";

import TextInput from "../../core/form/TextInput";
import { INVITE_COMPANY } from "../../../constants/forms";
import { Validation } from "../../../utils/validation";
import PhoneField from "../../core/form/PhoneInput/PhoneInput";
import EmailField from "../../core/form/EmailField/emailField";
import { withHandleFormError } from "../../../hoc";
import { ButtonsGroup } from "../../core/buttons/ButtonsGroup/buttonsGroup";
import PrimaryButton from "../../core/buttons/PrimaryButton";
import SecondaryButton from "../../core/buttons/SecondaryButton";
import { inviteCompany } from "../../../actionCreators/myCompany";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import Loader from "../../core/loader";

function InviteCompanyForm({ handleSubmit, submitting, handleCloseModal }) {
    const onCancel = () => {
        handleCloseModal && handleCloseModal();
    };

    return (
        <form onSubmit={handleSubmit} className={submitting && LOADER_WHITE_OVERLAY}>
            {submitting && <Loader />}
            <Stack container spacing={2} sx={{ p: 3 }}>
                <Field
                    name="name"
                    validate={[Validation.required]}
                    placeholder="Company Name"
                    label="Company Name"
                    component={TextInput}
                />
                <PhoneField isRequired />
                <EmailField isRequired />
                <ButtonsGroup>
                    <SecondaryButton onClick={onCancel}>CANCEL</SecondaryButton>
                    <PrimaryButton type="submit">INVITE</PrimaryButton>
                </ButtonsGroup>
            </Stack>
        </form>
    );
}

const onSubmit = (values, dispatch) => dispatch(inviteCompany(values));

export default compose(reduxForm({ form: INVITE_COMPANY, onSubmit }), withHandleFormError)(InviteCompanyForm);
