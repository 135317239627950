import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { selectSnackbars } from "../slices/notificationsSlice";
import { useActions } from "./index";

let displayed = [];

//https://codesandbox.io/s/github/iamhosseindhv/notistack/tree/master/examples/redux-example?file=/index.js:537-555
const useNotifier = () => {
    const snackbars = useSelector(selectSnackbars);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { removeSnackbar } = useActions();

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter((key) => id !== key)];
    };

    useEffect(() => {
        snackbars.forEach(({ key, message, options = {}, dismissed = false }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(message.replaceAll("|", ""), {
                key,
                ...options,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    removeSnackbar(myKey);
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [snackbars, closeSnackbar, enqueueSnackbar, removeSnackbar]);
};

export default useNotifier;
