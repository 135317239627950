export const REQUESTS_FILTERS = [
    {
        key: "accessType",
        label: "Request Status",
        items: [
            {
                label: "Filled",
                value: "1,3",
            },
            {
                label: "Unfilled",
                value: 4,
            },
        ],
    },
];
