/* global google */
import sitePin from "../../../assets/images/pale-red-pin.svg";
import { compose } from "recompose";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import CustomMarker from "../../../components/core/CustomMarker";
import { GOOGLE_MAPS_SITE_ICON_SIZE } from "../../../constants";
import React from "react";

const CreateSiteFormMap = compose(
    withScriptjs,
    withGoogleMap,
)((props) => {
    const { setMap, currentLocation } = props;

    return (
        <GoogleMap ref={(map) => setMap(map)} defaultZoom={12}>
            {currentLocation && (
                <CustomMarker
                    id={0}
                    position={currentLocation}
                    icon={{
                        url: sitePin,
                        scaledSize: new google.maps.Size(GOOGLE_MAPS_SITE_ICON_SIZE, GOOGLE_MAPS_SITE_ICON_SIZE),
                    }}
                />
            )}
        </GoogleMap>
    );
});

export default CreateSiteFormMap;
