import React from "react";
import PropTypes from "prop-types";
import { Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import UnlimitedAdornment from "./UnlimitedAdornment";
import TextInputComponent from "./form/TextInput/TextInputComponent";
import HiddenInput from "./form/HiddenInput";
import { Validation } from "../../utils/validation";
import { AUTOCOMPLETE_FORM_VIEW_CLASS } from "./form/AsyncAutocompleteComponent";

const useStyles = makeStyles({
    input: {
        "& input[type=number]": {
            "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
});

const QuantityField = (props) => {
    const styles = useStyles();
    const {
        name,
        qtyOnBlur,
        form,
        dispatch,
        setUnlimitedHandler,
        validation,
        fieldNote,
        disabled,
        disableUnlimitedButton,
        formValues: { unlimited, unitOfMeasure },
        showUnlimited,
    } = props;

    return (
        <div>
            <Field
                name={name}
                type="number"
                validate={unlimited ? [] : [Validation.required, ...validation]}
                placeholder="Quantity"
                disabled={unlimited || disabled}
                label="Quantity"
                onBlur={(e) => qtyOnBlur && qtyOnBlur(e.target.value)}
                className={clsx(styles.input, AUTOCOMPLETE_FORM_VIEW_CLASS)}
                fieldNote={fieldNote}
                needShowEndAdornment
                endAdornment={
                    showUnlimited && (
                        <UnlimitedAdornment
                            form={form}
                            disabled={disabled || disableUnlimitedButton}
                            dispatch={dispatch}
                            quantityName={name}
                            unitOfMeasure={unitOfMeasure}
                            setUnlimitedHandler={setUnlimitedHandler}
                        />
                    )
                }
                component={TextInputComponent}
            />
            <Field name={`${name}Error`} component={HiddenInput} />
            <Field name="unlimited" component={HiddenInput} />
        </div>
    );
};

QuantityField.propTypes = {
    name: PropTypes.string.isRequired,
    formValues: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    qtyOnBlur: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    setUnlimitedHandler: PropTypes.func,
    validation: PropTypes.array.isRequired,
    fieldNote: PropTypes.string,
    disabled: PropTypes.bool,
    disableUnlimitedButton: PropTypes.bool,
    unitOfMeasure: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showUnlimited: PropTypes.bool.isRequired,
};

QuantityField.defaultProps = {
    name: "quantity",
    validation: [],
    showUnlimited: true,
};

export default connect((state, props) => {
    const formSelector = formValueSelector(props.form);

    return {
        formValues: formSelector(state, "unitOfMeasure", "unlimited"),
    };
})(QuantityField);
