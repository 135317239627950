import React from "react";
import { Field } from "redux-form";
import { Validation } from "../../../utils/validation";
import TextInputComponent from "./TextInput";

const PasswordField = (props) => {
    const { className, needShowEndAdornment } = props;

    return (
        <Field
            type="password"
            placeholder="••••••••"
            validate={[Validation.required, Validation.password]}
            name="password"
            needShowEndAdornment={needShowEndAdornment}
            label="Password"
            className={className}
            component={TextInputComponent}
        />
    );
};

PasswordField.defaultProps = {
    needShowEndAdornment: false,
};

export default PasswordField;
