import { createSlice } from "@reduxjs/toolkit";
import { resetState } from './actions';

const initialState = {
    data: null,
    meta: {
        loading: false,
        error: null,
        page: 1,
        count: 0,
        hasMore: true,
    },
    currentProject: {
        data: null,
        meta: {
            loading: true,
            error: null,
        },
    },
    payloads: {
        data: null,
        meta: {
            loading: false,
            error: null,
            page: 1,
            count: 0,
            hasMore: true,
        },
    },
};
export const projectsSlise = createSlice({
    name: "projects",
    initialState,
    reducers: {
        requestProjects: (state) => {
            state.meta.loading = true;
        },
        resultProjects: (state, { payload }) => {
            if (payload.error) {
                state.meta.error = payload.message;
                state.meta.loading = false;
            } else {
                const { data, meta } = payload;
                state.meta = {
                    ...meta,
                    loading: false,
                    page: meta.page + 1,
                    hasMore: !(data.length < 20),
                };
                if (meta.page === 1) {
                    state.data = data;
                } else {
                    state.data.push(...data);
                }
            }
        },
        refreshProjects: (state) => {
            state.data = initialState.data;
            state.meta = initialState.meta;
        },
        resetProjects: (state) => {
            state.data = initialState.data;
            state.meta = initialState.meta;
        },
        requestProjectDetails: (state) => {
            state.currentProject.meta.loading = true;
        },
        resultProjectDetails: (state, { payload }) => {
            state.currentProject.data = payload;
            state.currentProject.meta.loading = false;
        },
        refreshProjectDetails: (state) => {
            state.currentProject = initialState.currentProject;
        },
        requestPayloads: (state) => {
            state.payloads.meta.loading = true;
        },
        resultPayloads: (state, { payload }) => {
            if (payload.error) {
                state.payloads.meta.error = payload.message;
                state.payloads.meta.loading = false;
            } else {
                const { data, meta } = payload;
                state.payloads.meta = {
                    ...meta,
                    loading: false,
                    page: meta.page + 1,
                    hasMore: !(data.length < 5),
                };
                if (meta.page === 1) {
                    state.payloads.data = data;
                } else {
                    state.payloads.data.push(...data);
                }
            }
        },
        resetPayloads: (state) => {
            state.payloads.data = initialState.payloads.data;
            state.payloads.meta = initialState.payloads.meta;
        },
    },
    extraReducers: (builder) => {
        return builder.addCase(resetState, () => initialState);
    },
});

const actions = projectsSlise.actions;

export { actions };

export const selectProjects = (state) => state.projects.data;
export const selectProjectsMeta = (state) => state.projects.meta;
export const selectCurrentProject = (state) => state.projects.currentProject.data;
export const selectCurrentProjectMeta = (state) => state.projects.currentProject.meta;
export const selectPayloads = (state) => state.projects.payloads.data;
export const selectPayloadsMeta = (state) => state.projects.payloads.meta;

export default projectsSlise.reducer;
