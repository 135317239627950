import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MUICard from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { styled } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

const Card = styled(MUICard)(({ isActive }) => ({
    position: "relative",
    minWidth: 275,
    cursor: "pointer",
    color: "#000",
    border: `2px solid ${isActive ? "#229440" : "#979797"}`,
    borderRadius: 4,
    marginBottom: 10,
    textDecoration: "none",
    backgroundColor: "#fff",
    "&:hover": {
        cursor: "pointer",
        boxShadow: "0 0 6px 0 #979797",
    },
}));

const CheckIcon = styled(CheckCircleOutlineOutlinedIcon)(() => ({
    top: 3,
    color: "#229440",
    right: 3,
    width: 17,
    height: 17,
    position: "absolute",
    borderRadius: 20,
    backgroundColor: "#C9F0E5",
}));

const CardStyled = styled(Card)(() => ({
    minWidth: 275,
    cursor: "pointer",
}));

export const ProjectSkeleton = () => {
    return (
        <CardStyled sx={{ minWidth: 275, cursor: "pointer" }}>
            <CardContent>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Skeleton variant="rectangular" width={30} height={30} />
                    <Skeleton variant="text" width="30%" height={20} />
                </Stack>
            </CardContent>
        </CardStyled>
    );
};

export default function ProjectCard({ name, isActive, setActiveProject }) {
    return (
        <Card sx={{ minWidth: 275, cursor: "pointer" }} onClick={setActiveProject} isActive={isActive}>
            <CardContent>
                <Stack direction="row" alignItems="center">
                    {isActive && <CheckIcon />}
                    <EventNoteIcon sx={{ mr: 2 }} />
                    <Typography sx variant="h3" component="div">
                        {name}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    );
}
