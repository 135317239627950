import { getFormValues } from "redux-form";
import moment from "moment";

import { actions, selectRequestDetailsMeta, selectRequestsMeta } from "../slices/requestSlice";
import * as api from "../api/requests";
import { getStates } from "../api/requests";
import { GLOBAL_COUNT_TO_LOAD } from "../api/endpoints";
import { handleError } from "../utils";
import { REQUESTS_FILTERS_FORM } from "../constants/forms";
import { selectAccountTimezone } from "../slices/userSlice";
import { convertDateToUserTimeZone } from "../helpers/time";
import { createPurchaseOrder, createPurchaseOrdersItems, requestProjects } from "../api/projects";
import { PROJECT_STATUS_ACTIVE } from "../constants/global";
import handleFormErrors from "../utils/handleFormErrors";
import { selectIfIsWebDimensions } from "../slices/utilsSlice";

const _format = "YYYY-MM-DD";

export const getRequestsList = (preventRequestDetails) => async (dispatch, getState) => {
    try {
        const { page, hasMore } = selectRequestsMeta(getState());
        if (hasMore) {
            const formValues = getFormValues(REQUESTS_FILTERS_FORM)(getState());
            const params = {
                page,
                startDate: moment(formValues?.startDate).format(_format),
                endDate: moment(formValues?.endDate).format(_format),
            };
            if (formValues?.filters?.accessType?.length) params.status = formValues.filters.accessType.join(",");
            if (formValues?.keywords) params.keywords = formValues.keywords;
            dispatch(actions.requestRequests());
            const result = await api.getRequests(params);
            dispatch(actions.resultRequests(result));
            if (result.data && result.data.length) {
                dispatch(getRequestDetails(result.data[0].id));
            }
        }
    } catch (e) {
        dispatch(actions.resultRequests(handleError(e)));
    }
};

export const refreshRequestsList = () => (dispatch) => {
    dispatch(actions.refreshRequests());
    dispatch(getRequestsList());
};

export const getRequestDetails = (requestId, initForm) => async (dispatch, getState) => {
    const state = getState();

    try {
        const { loading } = selectRequestDetailsMeta(state);
        if (!loading) {
            dispatch(actions.requestDetails(requestId));
            const { data } = await api.getRequestDetails(requestId);
            dispatch(actions.resultDetails(data));
        }
    } catch (error) {
        dispatch(actions.resultDetails(handleError(error)));
    }
};

export const getRequstTurnTime = (requestId, withoutRequest) => async (dispatch) => {
    try {
        dispatch(actions.requestTurnTime(requestId));
        const { data } = await api.getRequstTurnTime(requestId);
        dispatch(actions.resultTurnTime(data));
    } catch (error) {}
};

export const loadStatesOptions = (inputValue, { params = {}, loadedCount }) => {
    let stateParams = { ...params };
    if (inputValue) stateParams.keywords = inputValue;

    return getStates({ ...stateParams }).then((data) => {
        const currentPage = Math.ceil(loadedCount / GLOBAL_COUNT_TO_LOAD);
        const nextPage = loadedCount ? +currentPage + 1 : 1;
        const options = data.data.map((state) => ({
            label: `${state.abbreviation} (${state.name})`,
            abbreviation: state.abbreviation,
            value: state.id,
        }));

        return {
            options: options,
            hasMore: data.meta.count > (loadedCount || options.length),
            page: nextPage,
        };
    });
};

const DEFAULT_PRICE = "1";

export const createRequest = (values) => async (dispatch, getState) => {
    const state = getState();
    const timezone = selectAccountTimezone(state);

    try {
        const defaultProjectsResponse = await requestProjects({ status: PROJECT_STATUS_ACTIVE });
        const defaultProject = defaultProjectsResponse.data[0];
        const purchaseOrderResponse = await createPurchaseOrder({
            project: defaultProject.id,
            items: [],
            truckTypes: [],
        });
        const purchaseOrderId = purchaseOrderResponse.data.id;
        const body = {
            dropOffSites: values.dropOffSites.map((it) => it.value),
            requestNotes: values.requestNotes,
            totalQuantity: +values.totalQuantity || 0,
            unlimited: !!values.unlimited,
            startDate: convertDateToUserTimeZone(values.startDate, timezone),
        };

        const itemsResponse = await createPurchaseOrdersItems(purchaseOrderId, {
            dropOffSites: body.dropOffSites,
            pickUpSite: body.dropOffSites[0],
            perUnitPrice: DEFAULT_PRICE,

            unitOfMeasure: values.unitOfMeasure.value,
            payload: values.material.value,
        });
        const purchaseOrderItemsId = itemsResponse.data.id;

        body.poLineItemId = purchaseOrderItemsId;
        if (values.extRef) {
            body.extRef = values.extRef;
        }
        const result = await api.fetchCreateRequest(body);

        return result;
    } catch (e) {
        handleFormErrors(e);
    }
};

export const updateRequest = (requestId, values) => async (dispatch, getState) => {
    const state = getState();
    const timezone = selectAccountTimezone(state);
    try {
        const body = {
            dropOffSites: values.dropOffSites.map((it) => it.value),
            poLineItemId: values.material.value,
            requestNotes: values.requestNotes || "",
            totalQuantity: +values.totalQuantity || 0,
            unlimited: !!values.unlimited,
            startDate: convertDateToUserTimeZone(values.startDate, timezone),
        };
        if (values.extRef) body.extRef = values.extRef;
        const result = await api.fetchUpdateRequest(requestId, body);

        return result;
    } catch (e) {
        handleFormErrors(e);
    }
};
