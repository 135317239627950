import * as React from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import TuneIcon from "@mui/icons-material/Tune";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

import Chip from "./Chip";

const StyledMenu = styled(Menu)(() => ({
    ".MuiList-root": {
        padding: 20,
    },
}));

const StyledChip = styled(Chip)(({ selected }) => ({
    backgroundColor: selected ? "#ffd6b1" : "rgba(0, 0, 0, 0.08)",
    padding: 15,
    margin: 5,
    "&:hover": {
        backgroundColor: selected ? "#ffd6b1" : "rgba(0, 0, 0, 0.08)",
    },
}));

const ClearButtonStyled = styled(Button)(() => ({
    position: "absolute",
    right: 10,
    top: 10,
}));

export default function FilterSelector({ filters, input, clear, request, meta }) {
    const filtersCount = (input.value && Object.values(input.value).flat().length) || 0;

    const [anchorEl, setAnchorEl] = React.useState();
    const open = Boolean(anchorEl);
    const handleClose = (event) => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onChangeFilter = (item, single) => {
        const filters = (input.value && input.value[item.key]) || [];
        const isExists = filters.includes(item.value);
        if (single) {
            if (isExists) {
                input.onChange({
                    ...input.value,
                    [item.key]: filters.filter((filter) => {
                        return typeof item.value === "string" ? filter !== item.value : +filter !== +item.value;
                    }),
                });
            } else {
                input.onChange({
                    ...input.value,
                    [item.key]: [item.value],
                });
            }
        } else {
            if (isExists) {
                const newValue = {
                    ...input.value,
                    [item.key]: filters.filter((filter) => {
                        return typeof item.value === "string" ? filter !== item.value : +filter !== +item.value;
                    }),
                };
                input.onChange(newValue);
            } else {
                input.onChange({
                    ...input.value,
                    [item.key]: [...filters, item.value],
                });
            }
        }
        request && request();
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <Badge badgeContent={filtersCount} color="error">
                    <TuneIcon />
                </Badge>
            </IconButton>

            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <ClearButtonStyled onClick={clear}>Clear</ClearButtonStyled>
                {filters &&
                    filters.length &&
                    filters.map(({ label, items, key, single }, index) => {
                        return (
                            <>
                                <Typography variant="h3" sx={{ mt: index !== 0 ? 2 : 0 }}>
                                    {label}
                                </Typography>
                                <Divider sx={{ my: 2 }} />
                                {items.map((item) => (
                                    <StyledChip
                                        selected={
                                            input.value && input.value[key] && input.value[key].includes(item.value)
                                        }
                                        label={item.label}
                                        value={item.value}
                                        onClick={() => onChangeFilter({ ...item, key }, single)}
                                    />
                                ))}
                            </>
                        );
                    })}
            </StyledMenu>
        </div>
    );
}
