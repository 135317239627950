import React from "react";

import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";

import ProjectList from "./ProjectList/ProjectList";
import ProjectDetails from "./ProjectDetails/ProjectDetails";
import SubNavigation  from "./SubNavigation/SubNavigation"

function ProjectsAndSites(props) {
    return (
        <>
        <SubNavigation /> 
        <Container maxWidth="xl">
            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                <ProjectList />
                <ProjectDetails />
            </Stack>
        </Container>
        </>
    );
}

export default ProjectsAndSites;
