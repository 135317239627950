// @flow
import React from "react";

import AuthenticatedRoutes from "./AuthenticatedRoutes";
import UnauthenticatedRoutes from "./UnauthenticatedRoutes";

type Props = {
    isAuth: boolean,
};

const AppRoutes = ({ isAuth }: Props) => {
    if (isAuth) {
        return <AuthenticatedRoutes />;
    }

    return <UnauthenticatedRoutes />;
};

export default AppRoutes;
