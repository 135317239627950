import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { Logo } from "../../assets/";
import { useActions } from "../../hooks";
import { ROUTE_SIGNIN } from "../../routes/routesStrings";
import { selectAccount, selectIsAdmin } from "../../slices/userSlice";
import { closeWebsocket, initializeWebsocket } from "../../actionCreators/websocket";
import NavigationNotifications from "./NavigationNotifications/NavigationNotifications";
import NavigationLinks from "./NavigationLinks/NavigationLinks";

const settings = [
    {
        label: "Logout",
    },
];

const Header = styled(AppBar)(({ theme }) => ({
    backgroundColor: "#fff",
    paddingTop: 6,
    paddingBottom: 6,
    position: "relative",
}));

const LogoContainerStyled = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    fontFamily: "monospace",
    fontWeight: 700,
    letterSpacing: ".3rem",
    color: "inherit",
    textDecoration: "none",
    [theme.breakpoints.up("xs")]: {
        display: "flex",
    },
    [theme.breakpoints.up("md")]: {
        display: "none",
    },
}));

const UserNameStyled = styled(Typography)(() => ({ color: "#000" }));

const Navigation = () => {
    const account = useSelector(selectAccount);
    const isAdmin = useSelector(selectIsAdmin);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { removeUser, resetState, resetNotifications, getHasUnapprovedUsers } = useActions();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logout = () => {
        dispatch(removeUser());
        dispatch(resetState());
        localStorage.removeItem("accountData");
        localStorage.removeItem("truckitToken");
        navigate(ROUTE_SIGNIN);
    };

    useEffect(() => {
        if (isAdmin) {
            getHasUnapprovedUsers();
        }
        initializeWebsocket();
        window.addEventListener("beforeunload", (e) => {
            e.preventDefault();
            closeWebsocket();
        });

        return () => {
            resetNotifications();
        };
    }, []);

    return (
        <Header>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box component="a" href="/" noWrap sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
                        <Logo width={"60px"} height={"60px"} />
                    </Box>
                    <LogoContainerStyled>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="default"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            <NavigationLinks />
                        </Menu>
                    </LogoContainerStyled>
                    <LogoContainerStyled component="a" href="/" noWrap>
                        <Logo width={"60px"} height={"60px"} />
                    </LogoContainerStyled>
                    <Box sx={{ flexGrow: 11, display: { xs: "none", md: "flex" } }}>
                        <NavigationLinks />
                    </Box>
                    <Stack direction="row" alignItems="center" sx={{ flexGrow: 0 }} spacing={2}>
                        {account && (
                            <UserNameStyled variant="h3">
                                {account.firstName} {account.lastName}
                            </UserNameStyled>
                        )}
                        <NavigationNotifications />
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: 1 }}>
                                <AccountCircleIcon
                                    sx={{
                                        width: 35,
                                        height: 35,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting.label} onClick={logout}>
                                    <Typography textAlign="center">{setting.label}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Stack>
                </Toolbar>
            </Container>
        </Header>
    );
};

export default Navigation;
