import * as React from "react";
import moment from "moment";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

import { TYPOGRAPHY_ICONS_SITE } from "../../styles/icons";
import { JobQuantity, Status, TrucksRatio } from "../";

import clsx from "clsx";
import { selectAccountTimezone } from "../../slices/userSlice";

const METRIC = 2;

export const getShowCompletedCubage = (completedCubage, measurementSystem) =>
    !!completedCubage && measurementSystem !== METRIC;

const Text12 = styled(Typography)(({ theme, expand }) => ({
    color: "#000",
    fontSize: 12,
    fontWeight: 500,
    linehHeight: 1.5,
}));

const Text14 = styled(Typography)(({ theme, expand }) => ({
    color: "#000",
    fontSize: 14,
    fontWeight: 500,
    linehHeight: 1.5,
}));

const JobOrderStyled = styled(Card)(({ theme, isActive }) => ({
    width: "100%",
    border: isActive ? "2px solid #b96211" : "1px solid #979797",
    cursor: "pointer",
    fontSize: 12,
    boxShadow: "0 0 4px 0 rgb(112 112 112 / 50%)",
    transition: "box-shadow 0.3s ease-in-out",
    fontWeight: 500,
    lineHeight: 1.67,
    borderRadius: 8,
    marginBottom: 11,
    backgroundColor: isActive ? "#fbf7f3" : "white",
    [theme.breakpoints.down(950)]: {
        border: "1px solid #979797",
        backgroundColor: "white"
    },
    "&:hover": {
        boxShadow: "0 0 10px 1px rgb(112 112 112 / 50%)",
    },
}));

export const JobOrderSkeleton = () => {
    return (
        <JobOrderStyled spacing={1}>
            <CardContent>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Skeleton variant="text" width="30%" height={20} />
                    <Skeleton variant="text" width="30%" height={20} />
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Skeleton variant="text" width="15%" height={30} />
                    <Skeleton variant="text" width="15%" height={30} />
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Skeleton variant="text" width="30%" height={20} />
                    <Skeleton variant="text" width="5%" height={20} />
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Skeleton variant="text" width="30%" height={20} />
                    <Box display="flex" flexDirection="column" alignItems="flex-end" sx={{ flex: 1 }}>
                        <Skeleton variant="text" width="40%" height={20} />
                        <Skeleton variant="text" width="40%" height={20} />
                    </Box>
                </Stack>
            </CardContent>
        </JobOrderStyled>
    );
};

export default function JobOrder(props) {
    const siteIconsClasses = TYPOGRAPHY_ICONS_SITE();
    const timezone = useSelector(selectAccountTimezone);

    const { jobOrder, isActive, setActiveRequest } = props;
    const {
        id,
        extRef,
        completedQuantity,
        iAmOwner,
        unlimited,
        completedCubage,
        totalQuantity,
        unitOfMeasure,
        dispatchedQuantity,
        startDate,
        projectName,
        status,
        projectExternalJobNumber,
        copySchedule,
        assignedBy,
        assignedTrucksCount,
        totalNeededTrucksCount,
        companyName,
        trucks,
        payloadName,
    } = jobOrder;

    const joTrucks = trucks && trucks.join(", ");
    const showCompletedCubage = getShowCompletedCubage(completedCubage, 2);

    const handleActiveRequest = () => {
        setActiveRequest(id);
    };

    return (
        <JobOrderStyled isActive={isActive} onClick={handleActiveRequest}>
            <CardContent>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Text12>
                        {"Request"} # {id} -{" "}
                        {extRef ? (
                            <React.Fragment>
                                {extRef}
                                <br />
                            </React.Fragment>
                        ) : (
                            ""
                        )}
                        {iAmOwner ? (
                            <>
                                {projectName}
                                {projectExternalJobNumber ? ` - ${projectExternalJobNumber}` : ""}
                            </>
                        ) : assignedBy ? (
                            assignedBy.name
                        ) : (
                            companyName
                        )}
                    </Text12>
                    <Text14>{moment(startDate).tz(timezone).format("MM/DD/YYYY hh:mm A")}</Text14>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <JobQuantity
                        unitOfMeasure={unitOfMeasure}
                        completedQuantity={completedQuantity}
                        showCompletedCubage={showCompletedCubage}
                        unlimited={unlimited}
                        totalQuantity={totalQuantity}
                        dispatchedQuantity={dispatchedQuantity}
                        completedCubage={completedCubage}
                    />
                    <Status type={status} />
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Text12>{payloadName}</Text12>
                    <TrucksRatio assignedTrucks={assignedTrucksCount} requestedTrucks={+totalNeededTrucksCount} />
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Text12>{joTrucks}</Text12>
                    <Box display="flex" alignItems="flex-end">
                        <LocationOnIcon className={clsx(siteIconsClasses.iconSite, "drop-off")} />
                        <Text12>{jobOrder?.dropOffSites}</Text12>
                    </Box>
                </Stack>
            </CardContent>
        </JobOrderStyled>
    );
}
