import { ATLANTA_COORDINATES } from "../constants/global";

export const loadCurrentCoordinates = (defaultCoordinates = ATLANTA_COORDINATES) => {
    return new Promise((resolve) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                () => {
                    resolve({
                        latitude: defaultCoordinates.lat,
                        longitude: defaultCoordinates.lng,
                    });
                },
            );
        } else {
            resolve(defaultCoordinates);
        }
    });
};
