import * as React from "react";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const withHandleFormError = (WrappedComponent) => (props) =>
    (
        <>
            <WrappedComponent {...props} />
            <Snackbar
                open={props.error}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                autoHideDuration={5000}
            >
                <Alert severity="error" sx={{ width: "100%" }}>
                    {props.error}
                </Alert>
            </Snackbar>
        </>
    );

export default withHandleFormError;
