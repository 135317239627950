// @flow
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { formValueSelector, hasSubmitSucceeded } from "redux-form";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import useMediaQuery from "@mui/material/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import { useActions } from "../../../hooks";
import { selectRequestDetails, selectRequests, selectRequestsMeta } from "../../../slices/requestSlice.js";

import JobOrder, { JobOrderSkeleton } from "../../../components/JobOrder/JobOrder";
import ScrolledList from "../../../components/core/ScrolledList";
import Waypoint from "../../../components/core/Waypoint";
import { DEFAULT_DATE_TIME_FORMAT, LOADER_WHITE_OVERLAY, SERVER_DATE_FORMAT } from "../../../constants/global";
import Loader from "../../../components/core/loader";
import { CREATE_REQUEST, REQUEST_NOTIFICATION, REQUESTS_FILTERS_FORM } from "../../../constants/forms";
import { Filters, PrimaryButton } from "../../../components";
import FormDialog from "../../../components/core/FormDialog";
import CreateRequest from "../../CreateRequest/CreateRequest";
import { REQUESTS_FILTERS } from "../constants";

const FiltersContainerStyled = styled(Stack)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down(1190)]: {
        flexDirection: "column",
        width: "100%",
    },
    [theme.breakpoints.down(955)]: {
        flexDirection: "row",
    },
    [theme.breakpoints.down(500)]: {
        width: "100%",
        justifyContent: "center",
        flexDirection: "column !important",
    },
}));
const DatesContainerStyled = styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down(500)]: {
        width: "100%",
        justifyContent: "space-evenly",
        flexDirection: "row",
    },
}));
const SearchSontainerStyled = styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down(1190)]: {
        width: "100%",
    },
    [theme.breakpoints.down(955)]: {
        flexDirection: "row",
    },
    [theme.breakpoints.down(500)]: {
        marginTop: 5,
        flexDirection: "row",
    },
}));

function RequestList(props) {
    const { getRequestsList, getRequestDetails, resetRequests, resetDetails, refreshRequestsList, destroy } =
        useActions();
    const navigate = useNavigate();
    const isWebMode = useMediaQuery("(min-width:950px)");
    const requests = useSelector(selectRequests);
    const { error, loading } = useSelector(selectRequestsMeta);
    const currentRequest = useSelector(selectRequestDetails);

    const hasSubmitSuccess = useSelector(hasSubmitSucceeded(CREATE_REQUEST));
    const requestSuccessLabel = useSelector((state) =>
        formValueSelector(REQUEST_NOTIFICATION)(state, "requestSuccessLabel"),
    );

    const resetData = () => {
        resetRequests();
        resetDetails();
    };

    useEffect(() => {
        if (hasSubmitSuccess) {
            refreshRequestsList();
        }
    }, [hasSubmitSuccess]);

    useEffect(() => {
        getRequestsList();

        return () => {
            resetData();
        };
    }, []);

    useEffect(() => {
        if (currentRequest?.id) {
            getRequestDetails(currentRequest?.id);
        }
    }, [currentRequest?.id]);

    const setActiveRequest = (id) => {
        if (isWebMode) {
            if (currentRequest.id !== id) {
                getRequestDetails(id);
            }
        } else {
            navigate(`/requests/${id}`);
        }
    };

    const onFiltersChange = () => {
        resetDetails();
        refreshRequestsList();
    };

    return (
        <Box component="main" sx={{ flex: 1, p: 2 }} className={loading && LOADER_WHITE_OVERLAY}>
            {loading && <Loader />}
            <FormDialog
                dialogTitle="New Request"
                form={CREATE_REQUEST}
                OpenModalComponent={(props) => (
                    <PrimaryButton style={{ width: "100%" }} {...props}>
                        <AddIcon />
                        CREATE REQUEST
                    </PrimaryButton>
                )}
            >
                {(props) => (
                    <CreateRequest
                        {...props}
                        initialValues={{
                            startDate: moment(new Date()).format(DEFAULT_DATE_TIME_FORMAT),
                        }}
                    />
                )}
            </FormDialog>
            <Divider sx={{ my: 2 }} />
            <Filters
                form={REQUESTS_FILTERS_FORM}
                request={onFiltersChange}
                initialValues={{
                    startDate: moment(new Date()).format(SERVER_DATE_FORMAT),
                    endDate: moment(new Date()).format(SERVER_DATE_FORMAT),
                }}
            >
                <FiltersContainerStyled>
                    <DatesContainerStyled>
                        <Filters.DateField name="startDate" />
                        <Filters.DateField name="endDate" />
                    </DatesContainerStyled>
                    <SearchSontainerStyled>
                        <Filters.SearchField />
                        <Filters.FilterSelector filters={REQUESTS_FILTERS} />
                        <Filters.RefreshButton />
                    </SearchSontainerStyled>
                </FiltersContainerStyled>
            </Filters>
            <Divider sx={{ my: 2 }} />
            <ScrolledList>
                {loading && !(requests && requests.length)
                    ? Array.from(new Array(5)).map(() => <JobOrderSkeleton />)
                    : requests &&
                      requests.map((jobOrder, index) => {
                          const isActive = currentRequest && jobOrder.id === currentRequest.id;
                          return (
                              <React.Fragment>
                                  <JobOrder
                                      isActive={isActive}
                                      setActiveRequest={setActiveRequest}
                                      jobOrder={jobOrder}
                                  />
                                  <Waypoint fetchMore={getRequestsList} index={index} items={requests} />
                              </React.Fragment>
                          );
                      })}
            </ScrolledList>
            <Snackbar
                open={!!error}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={resetRequests}
            >
                <Alert elevation={6} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {error}
                </Alert>
            </Snackbar>
            <Snackbar
                open={!!requestSuccessLabel}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => destroy(REQUEST_NOTIFICATION)}
            >
                <Alert elevation={6} severity="success" sx={{ width: "100%" }}>
                    {requestSuccessLabel}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default RequestList;
