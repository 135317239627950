import Axios from "axios";
import _ from "lodash";
import { ACCOUNT_DATA_STORAGE_KEY } from "../constants/global";
import { removeUserSession } from "../actionCreators/user";
import { apiBase } from "../config";

export const LOCAL_STORAGE_FILTERS_ID = "appliedFilters";
export const PREVIOUS_ACCOUNT_DATA_STORAGE_KEY = "previousAccountData";
export const USERNAME_STORAGE_KEY = "accountData";
const __BASE_URL__ = apiBase;

const getXSRF = () => {
    const accountData = JSON.parse(localStorage.getItem(ACCOUNT_DATA_STORAGE_KEY));
    let token = null;

    if (accountData && accountData.token) {
        token = `Token ${accountData.token}`;
    }

    return token;
};

export const getClearXSRF = () => {
    const accountData = JSON.parse(localStorage.getItem(ACCOUNT_DATA_STORAGE_KEY));

    if (accountData && accountData.token) return accountData.token;

    return null;
};

const dataProvider = (options) => {
    options = options || {};
    options.baseURL = __BASE_URL__;
    options.responseType = options.responseType || "json";
    options.method = options.method || "GET";
    options.cache = false;

    options.headers = {
        "Cache-Control": "private",
        IS_WEB_APP: true,
        IS_I80_PORTAL: true,
    };
    options.headers.common = {};

    // if (hostname !== "localhost" && !options.noPragma) {
    //     options.headers.common["Pragma"] = "no-cache";
    // }
    const token = getXSRF();

    if (token) {
        options.headers.common["Authorization"] = token;
    }

    if (options.data && options.data.forceTokenDelete) {
        delete options.headers.common["Authorization"];
        delete options.data.forceTokenDelete;
    }

    Axios.defaults.withCredentials = true;

    if (!_.isEmpty(options.cancelToken)) {
        delete options.cancelToken;
    } else if (options.params && options.params.cancelToken) {
        delete options.params.cancelToken;
    }

    return Axios.request(options)
        .then((response) => {
            if (options.responseType === "blob") {
                return response.data || response;
            } else {
                if (response.data && response.data.authToken) {
                    localStorage.setItem(
                        "accountData",
                        JSON.stringify({
                            ...response.data.data,
                            token: response.data.authToken,
                        }),
                    );
                }

                return response.data;
            }
        })
        .catch((error) => {
            let params = {};

            if (error.response) {
                params = error.response.data || error.response;
                params.status = error.response.status;

                if (params.status === 401) {
                    removeUserSession();
                }
            }

            if (
                error.request &&
                error.request.responseType === "blob" &&
                error.response.data instanceof Blob &&
                error.response.data.type &&
                error.response.data.type.toLowerCase().indexOf("json") != -1
            ) {
                const reader = new FileReader();
                reader.onload = () => (error.response.data.message = JSON.parse(reader.result));
            }

            return Promise.reject(params);
        });
};

export const fileUploader = (url, file, options = {}) => {
    options.baseURL = __BASE_URL__;
    options.headers = { "content-type": "multipart/form-data" };
    options.headers.common = {};
    const token = getXSRF();

    let data = new FormData();

    if (token) {
        options.headers.common["Authorization"] = token;
    }

    options.data = data;
    options.url = url;

    return Axios.request(options)
        .then((response) => {
            return options.responseType === "blob" ? response : response.data;
        })
        .catch((error) => {
            let params = {};

            if (error.response) {
                params = error.response.data || error.response;
                params.status = error.response.status;
            }

            return Promise.reject(params);
        });
};

export default dataProvider;
