import React from "react";

import MUIChip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

import { MAIN_THEME } from "../../styles/globalThemeConfig";
import {
    JO_STATUS_COMPLETED_ID,
    JO_STATUS_ACTIVE_ID,
    JO_STATUS_NOT_STARTED_ID,
    JO_STATUS_PENDING_ID,
} from "../../constants/job";

const Chip = styled(MUIChip)(({ theme, backgroundColor }) => ({
    color: "#fff",
    padding: 0,
    fontSize: 11,
    letterSpacing: -0.6,
    textTransform: "uppercase",
    height: 15,
    width: 74,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
    borderRadius: theme.shape.borderRadius * 3,
    backgroundColor,
    "& span": {
        display: "block",
        padding: "1px 12px 0",
        fontSize: "inherit",
        overflow: "visible",
    },
}));

const STATUS_PILL_LABELS = {
    [JO_STATUS_ACTIVE_ID]: "Active",
    [JO_STATUS_PENDING_ID]: "Pending",
    [JO_STATUS_NOT_STARTED_ID]: "Not Started",
    [JO_STATUS_COMPLETED_ID]: "Closed",
};

const statusPillPalette = {
    default: MAIN_THEME.palette.secondary.dark,
    [JO_STATUS_ACTIVE_ID]: MAIN_THEME.palette.general.active,
    [JO_STATUS_PENDING_ID]: MAIN_THEME.palette.general.pending,
    [JO_STATUS_NOT_STARTED_ID]: MAIN_THEME.palette.secondary.superDark,
    [JO_STATUS_COMPLETED_ID]: MAIN_THEME.palette.primary.main,
};

const StatusPill = (props) => {
    const { label, component, type, className } = props;
    const text = label || STATUS_PILL_LABELS[type];

    return <Chip component={component} label={text} backgroundColor={statusPillPalette[type]} />;
};

export default StatusPill;
