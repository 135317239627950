export const SIGN_IN_FORM = "SIGN_IN";
export const SIGN_UP_FORM = "SIGN_UP";

export const MY_TEAM_FILTERS = "MY_TEAM_FILTERS";
export const MY_COMPANIES_FILTERS = "MY_COMPANIES_FILTERS";

export const CREATE_REQUEST = "CREATE REQUEST";
export const REQUEST_NOTIFICATION = "REQUEST NOTIFICATION";
export const REQUESTS_FILTERS_FORM = "REQUESTS FILTERS";

export const CREATE_SITE_FORM = "CREATE_SITE_FORM";

export const SEND_EMAIL = "SEND EMAIL";
export const ENTER_NEW_PASSWORD = "ENTER NEW PASSWORD";

export const INVITE_COMPANY = "INVITE COMPANY";

export const CREATE_USER_FORM = "CREATE_USER_FORM";
export const APPROVE_SIGN_UP_FROM = "APPROVE_SIGN_UP_FROM";
export const ACCEPT_TERMS_FORM = "ACCEPT_TERMS_FORM";

//SITES PAGE
export const SITES_FILTERS_FORM = "SITES FILTERS";
