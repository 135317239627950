import { SubmissionError } from "redux-form";

import * as api from "../api/user";
import { agreeWithTermsAndConditionsRequest } from "../api/user";
import { actions, selectAccount } from "../slices/userSlice";
import history from "../utils/browserHistory";
import { getNextPage } from "../api/endpoints";
import { store } from "../app/store";
import { handleError } from "../utils";
import { ROUTE_SIGNIN } from "../routes/routesStrings";
import { getFullCompanyAddress, preparePhone } from "../helpers/global";
import { EXISTING_COMPANY_VALUE, NEW_COMPANY_VALUE } from "../pages/SignUp/SignUpForm/CompanyContactInfoForm";
import { ACCOUNT_DATA_STORAGE_KEY, USER_ROLE_SUB_COMPANY_CUSTOMER } from "../constants/global";
import handleFormErrors from "../utils/handleFormErrors";
import { resetState } from "../slices/actions";
import { PARENT_COMPANY_ID, PARENT_COMPANY_UUID } from "../constants/customersMapper/customersMapper";

export const signIn = (values) => async (dispatch) => {
    try {
        const body = {
            email: values.email,
            password: values.password,
        };
        const { data, authToken } = await api.signIn(body);
        dispatch(actions.setUser({ ...data, token: authToken }));
    } catch (e) {
        const errors = {};
        if (typeof e === "string") throw new SubmissionError({ _error: e });
        if (typeof e.message === "string") throw new SubmissionError({ _error: e.message });
        if (e?.message?.password) errors.password = e.message.password[0];
        if (e?.message?.email) errors.email = e.message.email[0];
        throw new SubmissionError(errors);
    }
};

const returnCompanyAddress = (values) => {
    const address = {};
    if (values.companyAddress) address.companyAddress = values.companyAddress;
    if (values.streetAddress) address.streetAddress = values.streetAddress;
    if (values.secondaryAddress) address.secondaryAddress = values.secondaryAddress;
    if (values.zipCode) address.zipCode = values.zipCode;
    if (values.state) address.state = values.state.label;
    if (values.city) address.city = values.city;
    address.companyAddress = getFullCompanyAddress(values);

    return { ...address };
};

export const signUp = (values) => async (dispatch) => {
    try {
        let dataForSave = {
            password: values.password,
            acceptTermsAndConditions: values.acceptTermsAndConditions,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            username: values.email,
            phone: preparePhone(values.phone),
            userContact: {
                name: values.userContactName,
                email: values.userContactEmail,
                phone: preparePhone(values.userContactPhone),
            },
            role: USER_ROLE_SUB_COMPANY_CUSTOMER,
            parentCompanyUUID: PARENT_COMPANY_UUID,
        };
        if (values.companyExisting === EXISTING_COMPANY_VALUE) {
            dataForSave.registrationCompanyName = values.registrationCompanyName;
        }
        if (values.companyExisting === NEW_COMPANY_VALUE) {
            dataForSave = {
                ...dataForSave,
                companyName: values.companyName,
                companyAddress: values.companyAddress,
                ...returnCompanyAddress(values),
            };
        }

        const result = await api.userSignUp(dataForSave);

        return result;
    } catch (e) {
        const errors = {};
        if (typeof e === "string") throw new SubmissionError({ _error: e });
        if (typeof e.message === "string") throw new SubmissionError({ _error: e.message });
        if (e?.message?.userContact?.phone) errors.userContactPhone = e?.message?.userContact?.phone[0];
        throw new SubmissionError(errors);
    }
};

export const removeUserSession = () => {
    store.dispatch(actions.removeUser());
    store.dispatch(resetState());
    localStorage.removeItem(ACCOUNT_DATA_STORAGE_KEY);
    history.push(ROUTE_SIGNIN);
};

export const loadContacts =
    (inputValue, { params = {}, loadedCount }) =>
    async () => {
        try {
            let stateParams = { ...params };
            if (inputValue) stateParams.keywords = inputValue;

            const { data, meta } = await api.fetchContacts(stateParams);

            const nextPage = getNextPage(loadedCount);

            const options = data.map((state) => ({
                label: state.fullName,
                value: state.id,
            }));

            return {
                options: options,
                hasMore: meta.count > (loadedCount || options.length),
                page: nextPage,
            };
        } catch (error) {
            throw new Error(handleError(error));
        }
    };

export const loadMaterials =
    (inputValue, { params = {}, loadedCount }) =>
    async (dispatch, getState) => {
        try {
            let stateParams = { ...params };
            if (inputValue) stateParams.keywords = inputValue;

            const { data, meta } = await api.fetchMaterials(PARENT_COMPANY_ID, params);

            const nextPage = getNextPage(loadedCount);

            const options = data.map((item) => ({
                label: item.name,
                value: item.id,
            }));

            return {
                options: options,
                hasMore: meta.count > (loadedCount || options.length),
                page: nextPage,
            };
        } catch (error) {
            throw new Error(handleError(error));
        }
    };

export const loadUnitsOfMeasureOptions =
    (inputValue, { params = {}, loadedCount }) =>
    async (dispatch, getState) => {
        try {
            const requestParams = {
                ...params,
                page: null,
                useMeasurementSystem: true,
            };
            if (inputValue) {
                requestParams.keywords = inputValue;
            }

            const { data, meta } = await api.fetchUnitsOfMeasure(requestParams);

            const nextPage = getNextPage(loadedCount);

            const options = data.map((item) => ({
                label: item.name,
                value: item.id,
            }));

            return {
                options: options,
                hasMore: meta.count > (loadedCount || options.length),
                page: nextPage,
            };
        } catch (error) {
            throw new Error(handleError(error));
        }
    };

export const agreeWithTermsAndConditions = () => async (dispatch, getState) => {
    const state = getState();
    const account = selectAccount(state);
    try {
        await agreeWithTermsAndConditionsRequest();
        const updatedUser = {
            ...account,
            hasAgreedToLatestTC: true,
        };
        dispatch(actions.setUser(updatedUser));

        localStorage.setItem(ACCOUNT_DATA_STORAGE_KEY, JSON.stringify(updatedUser));
    } catch (e) {
        handleFormErrors(e);
    }
};
