import { getFormValues } from "redux-form";

import { actions, selectCurrentProject, selectPayloadsMeta, selectProjectsMeta } from "../slices/projectsSlice";
import * as api from "../api/projects";
import { getNextPage } from "../api/endpoints";
import { handleError } from "../utils";

export const getProjectsList = () => async (dispatch, getState) => {
    try {
        const { page, hasMore } = selectProjectsMeta(getState());
        if (hasMore) {
            dispatch(actions.requestProjects());
            const currentProject = selectCurrentProject(getState());
            const formValues = getFormValues("projects-filters")(getState());
            const params = {
                page,
            };
            if (formValues?.filters?.status && formValues?.filters?.status.length) {
                params.status = formValues?.filters?.status.join();
            }
            if (formValues && formValues.keywords) params.keywords = formValues.keywords;
            const result = await api.requestProjects(params);
            if (result.data && result.data.length && !currentProject) {
                dispatch(getCurrentProject(result.data[0].id));
            }
            dispatch(actions.resultProjects(result));
        }
    } catch (e) {
        dispatch(actions.resultProjects(handleError(e)));
    }
};

export const refreshProjectsList = () => (dispatch) => {
    dispatch(actions.refreshProjects());
    dispatch(getProjectsList());
};

export const refreshCurrentProjectDetails = () => (dispatch) => {
    dispatch(actions.refreshProjectDetails());
    dispatch(getCurrentProject());
};

export const getCurrentProject = (projectId) => async (dispatch) => {
    try {
        dispatch(actions.requestProjectDetails());
        const { data } = await api.requestProjectDetails(projectId);
        dispatch(actions.resultProjectDetails(data));
    } catch (error) {}
};

export const getPayloads = (projectId) => async (dispatch, getState) => {
    try {
        const { page, hasMore } = selectPayloadsMeta(getState());
        const currentProject = selectCurrentProject(getState());
        if (hasMore && currentProject) {
            dispatch(actions.requestPayloads());
            const params = { page };
            const result = await api.requestProjectPoItems(currentProject.id, params);
            dispatch(actions.resultPayloads(result));
        }
    } catch (error) {
        dispatch(actions.resultPayloads(handleError(error)));
    }
};

export const loadProjects =
    (inputValue, { params = {}, loadedCount }) =>
    async () => {
        try {
            let stateParams = { ...params };
            if (inputValue) stateParams.keywords = inputValue;

            const { data, meta } = await api.requestProjects(stateParams);

            const nextPage = getNextPage(loadedCount);

            const options = data.map((state) => ({
                label: state.name,
                value: state.id,
            }));

            return {
                options: options,
                hasMore: meta.count > (loadedCount || options.length),
                page: nextPage,
            };
        } catch (error) {
            throw new Error(handleError(error));
        }
    };
