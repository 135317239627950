import React from "react";
import clsx from "clsx";

const NotificationLabel = (props) => {
    const { className, text } = props;

    return (
        <span className={className}>
            {text.split("|").map((word, index) => (
                <span key={index} className={clsx(index % 2 !== 0 && "--text-semi-bold")}>
                    {word}
                </span>
            ))}
        </span>
    );
};

export default NotificationLabel;
