import React from "react";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import RoomIcon from "@mui/icons-material/Room";
import NotesIcon from "@mui/icons-material/Notes";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FlagIcon from "@mui/icons-material/Flag";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { styled } from "@mui/material/styles";

import clsx from "clsx";

import { TYPOGRAPHY_ICONS_SITE } from "../../../styles/icons";
import { JobOrderMap } from "../../../components";
import { SimpleLink } from "../../../components/core/link";
import { selectRequestDetails, selectRequestsMeta, selectRequestDetailsMeta } from "../../../slices/requestSlice.js";
import { selectIfIsMobileDimensions } from "../../../slices/utilsSlice.js";
import { selectAccountTimezone } from "../../../slices/userSlice";
import { ROUTE_REQUESTS } from "../../../routes/routesStrings";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import FormDialog from "../../../components/core/FormDialog";
import EditRequest from "../../CreateRequest/CreateRequest";
import Loader from "../../../components/core/loader";
import { CREATE_REQUEST } from "../../../constants/forms";
import SecondaryButton from "../../../components/SecondaryButton/index";
import { RequestFormInitialValuesDTO } from "../../../utils/DTO.js";
import { useActions } from "../../../hooks";

const TextWeight600 = styled(Typography)(() => ({
    fontWeight: "600",
}));

const ContainerStyled = styled(Box)(({ theme, requestParamId }) => ({
    [theme.breakpoints.down(950)]: {
        display: requestParamId ? "auto" : "none !important",
    },
}));

const DropOffSites = ({ dropOffSites }) => {
    const siteIconsClasses = TYPOGRAPHY_ICONS_SITE();

    if (!dropOffSites || !dropOffSites.length) return;

    return dropOffSites.map((site, index) => (
        <Stack direction="column" sx={{ pb: 2 }}>
            <Stack direction="row" alignItems="flex-start">
                <RoomIcon className={clsx(siteIconsClasses.iconSite, "drop-off")} />
                <Stack direction="column">
                    <Typography variant="h3">Drop-Off Site</Typography>
                    <SimpleLink to={ROUTE_REQUESTS}>{site.name}</SimpleLink>
                    <Typography sx={{ mt: 2 }}>{site.address}</Typography>
                </Stack>
            </Stack>
            {!!site.notes && (
                <Stack direction="row" alignItems="flex-start" sx={{ mt: 2 }}>
                    <NotesIcon className={clsx(siteIconsClasses.iconSite)} />
                    <Stack direction="column">
                        <Typography variant="h3">Site Notes</Typography>
                        <Typography>{site.notes}</Typography>
                    </Stack>
                </Stack>
            )}
        </Stack>
    ));
};

const RequestDetailsSkeleton = ({ requestParamId }) => {
    return (
        <ContainerStyled
            requestParamId={requestParamId}
            component="main"
            sx={{ flex: 1, p: 2 }}
            className={LOADER_WHITE_OVERLAY}
        >
            <Loader />
            <Skeleton variant="text" animation="wave" sx={{ fontSize: "27px" }} width="50%" />
            <Divider sx={{ my: 2 }} />
            <Stack direction="row" justifyContent="center">
                <Skeleton variant="rectangular" animation="wave" sx={{ borderRadius: 1 }} height={55} width={150} />
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Skeleton variant="rectangular" animation="wave" height={400} width="100%" />
            <Divider sx={{ my: 2 }} />
            <Skeleton variant="text" animation="wave" sx={{ fontSize: "16px" }} width="25%" />
            <Stack direction="column" sx={{ my: 2 }}>
                <Skeleton variant="text" animation="wave" sx={{ fontSize: "10px" }} width="20%" />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: "10px" }} width="18%" />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: "16px" }} width="50%" />
            </Stack>
            <Stack direction="column" sx={{ my: 2 }}>
                <Skeleton variant="text" animation="wave" sx={{ fontSize: "10px" }} width="20%" />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: "10px" }} width="18%" />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: "16px" }} width="50%" />
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Skeleton variant="text" animation="wave" sx={{ fontSize: "16px" }} width="30%" />
            <Skeleton variant="text" animation="wave" sx={{ fontSize: "16px" }} width="70%" />
        </ContainerStyled>
    );
};

export default function RequestDetails(props) {
    const { getRequestDetails, resetDetails } = useActions();
    const { id: requestParamId } = useParams();
    const timezone = useSelector(selectAccountTimezone);
    const { loading } = useSelector(selectRequestDetailsMeta);
    const { loading: requestsLoading } = useSelector(selectRequestsMeta);
    const details = useSelector(selectRequestDetails) || {};

    const { dropOffSites, requestNotes, createdBy, createdDate } = details;

    const project = details.poLineItem && details.poLineItem.purchaseOrder && details.poLineItem.purchaseOrder.project;

    const canBeEdited = details.assignedTrucksCount === 0;

    React.useEffect(() => {
        if (requestParamId) {
            getRequestDetails(requestParamId);
        }
        return () => {
            resetDetails();
        };
    }, [requestParamId]);

    if (loading || requestsLoading) {
        return <RequestDetailsSkeleton requestParamId={requestParamId} />;
    }

    if (!loading && _.isEmpty(details))
        return (
            <ContainerStyled
                requestParamId={requestParamId}
                component="main"
                sx={{ flex: 1, p: 2 }}
                className={loading && LOADER_WHITE_OVERLAY}
            >
                <Typography textAlign="center" variant="h1" sx={{ my: "18px" }}>
                    No data to show
                </Typography>
            </ContainerStyled>
        );

    return (
        <ContainerStyled
            requestParamId={requestParamId}
            component="main"
            sx={{ flex: 1, p: 2 }}
            className={loading && LOADER_WHITE_OVERLAY}
        >
            {loading && <Loader />}
            {requestParamId && (
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/requests">
                        Requests
                    </Link>
                    <Typography color="text.primary">Request #{requestParamId}</Typography>
                </Breadcrumbs>
            )}
            <Box display="flex" alignItems="center" sx={{ height: 55 }}>
                <Typography variant="h1">
                    Request #{details.id}
                    {details.extRef && ` / Ext. Ref #${details.extRef}`}
                    {`${details.customerName ? ` - ${details.customerName}` : ""}`}
                    {project
                        ? ` Project ${project.externalJobNumber ? `${project.externalJobNumber} - ` : ""} ${
                              project.name
                          }`
                        : ""}
                </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            {canBeEdited && (
                <>
                    <FormDialog
                        dialogTitle={`Request #${details.id}`}
                        form={CREATE_REQUEST}
                        OpenModalComponent={(props) => (
                            <Stack direction="row" justifyContent="center" spacing={2}>
                                <SecondaryButton size="small" {...props}>
                                    <EditIcon />
                                    edit request
                                </SecondaryButton>
                            </Stack>
                        )}
                    >
                        {(props) => (
                            <EditRequest
                                {...props}
                                requestId={details.id}
                                initialValues={new RequestFormInitialValuesDTO(details, timezone)}
                            />
                        )}
                    </FormDialog>
                    <Divider sx={{ my: 2 }} />
                </>
            )}
            <JobOrderMap key={details?.id} jobOrder={details} />
            <Box>
                <Typography variant="h3">SITE DETAILS</Typography>
                <Stack direction="column" spacing={2} sx={{ my: 2 }}>
                    <DropOffSites dropOffSites={dropOffSites} />
                </Stack>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box>
                <Typography variant="h3">JOB DETAILS</Typography>
                {requestNotes && (
                    <Stack direction="row" alignItems="flex-start" sx={{ my: 2 }}>
                        <FlagIcon />
                        <Stack direction="column">
                            <TextWeight600>Request Notes</TextWeight600>
                            <Typography>{requestNotes}</Typography>
                        </Stack>
                    </Stack>
                )}
                {createdBy && (
                    <Stack direction="row" sx={{ my: 2 }}>
                        <FlagIcon />
                        <Stack direction="row" alignItems="center">
                            <TextWeight600 sx={{ mr: 0.5 }}>Created</TextWeight600>
                            <Typography>
                                {moment(createdDate).format("MM/DD/YYYY hh:mm A")} by {createdBy.firstName}
                                {createdBy.lastName} (
                                <SimpleLink otherProps={{ href: `tel:${createdBy.phone}` }}>
                                    {createdBy.phone}
                                </SimpleLink>
                                )
                            </Typography>
                        </Stack>
                    </Stack>
                )}
            </Box>
        </ContainerStyled>
    );
}
