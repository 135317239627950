import moment from "moment";

export const toDate = ({ date, timezone = null }) => {
    let m = moment.utc(date);
    if (timezone) {
        m.tz(timezone);
    }

    return m;
};
