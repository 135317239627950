// @flow
import React from "react";
import "moment-timezone";
import "./App.css";

import "./styles.scss";
import "./styles/fonts.css";

import AppRoutes from "./components/Routes/AppRoutes";
import { useSelector } from "react-redux";
import { selectAccount, selectGlobalErrorMessage } from "./slices/userSlice";
import ErrorMessage from "./components/core/messages/ErrorMessage";
import { useActions } from "./hooks";
import useNotifier from "./hooks/useNotifier";
import JumpToTopButton from "./components/core/buttons/JumpToTopButton";
import useTabTitle from "./hooks/tabSettingsHooks/useTabTitle";
import { TAB_TITLE, LOGO_SVG } from "./constants/customersMapper/customersMapper";
import useTabIcon from "./hooks/tabSettingsHooks/useTabIcon";

function App() {
    useNotifier();
    useTabTitle(TAB_TITLE);
    useTabIcon(LOGO_SVG);

    const account = useSelector(selectAccount);
    const error = useSelector(selectGlobalErrorMessage);
    const { updateGlobalErrorMessage } = useActions();

    return (
        <>
            <AppRoutes isAuth={account && account.token} />
            <ErrorMessage error={error} onClose={() => updateGlobalErrorMessage(null)} />
            <JumpToTopButton />
        </>
    );
}

export default App;
