import React from "react";
import { compose } from "recompose";
import { Field, reduxForm } from "redux-form";

import { Box, Stack } from "@mui/material";

import { CREATE_USER_FORM } from "../../../constants/forms";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
} from "../../../components/core/form/AsyncAutocompleteComponent";
import { Validation } from "../../../utils/validation";
import { useActions } from "../../../hooks";
import TextInputComponent from "../../../components/core/form/TextInput";
import EmailField from "../../../components/core/form/EmailField/emailField";
import PhoneInput from "../../../components/core/form/PhoneInput/PhoneInput";
import PasswordField from "../../../components/core/form/PasswordField";
import { SecondaryButton } from "../../../components";
import PrimaryButton from "../../../components/core/buttons/PrimaryButton";
import { ButtonsGroup } from "../../../components/core/buttons/ButtonsGroup/buttonsGroup";
import withHandleFormError from "../../../hoc/withHandleFormError";
import { createMyTeamUser } from "../../../actionCreators/myCompany";

const CreateUserForm = ({ handleSubmit, submitting, handleCloseModal }) => {
    const { getCompaniesOptions } = useActions();

    const onCancel = () => {
        handleCloseModal && handleCloseModal();
    };

    return (
        <form onSubmit={handleSubmit} className={submitting && LOADER_WHITE_OVERLAY}>
            <Box sx={{ p: 3 }}>
                <Stack direction="column" spacing={2}>
                    <Field
                        name="company"
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        validate={[Validation.required]}
                        loadOptions={getCompaniesOptions}
                        label="Company"
                        component={AsyncAutocompleteComponent}
                    />
                    <Field
                        name="firstName"
                        validate={[Validation.required, Validation.noSpecialSymbols]}
                        type="text"
                        label="First Name"
                        component={TextInputComponent}
                    />
                    <Field
                        name="lastName"
                        validate={[Validation.required, Validation.noSpecialSymbols]}
                        type="text"
                        label="Last Name"
                        component={TextInputComponent}
                    />
                    <PhoneInput isRequired />
                    <EmailField isRequired />
                    <PasswordField />
                    <ButtonsGroup>
                        <SecondaryButton onClick={onCancel}>CANCEL</SecondaryButton>
                        <PrimaryButton type="submit">SUBMIT</PrimaryButton>
                    </ButtonsGroup>
                </Stack>
            </Box>
        </form>
    );
};

const onSubmit = (values, dispatch) => {
    return dispatch(createMyTeamUser(values));
};

export default compose(
    reduxForm({
        form: CREATE_USER_FORM,
        onSubmit,
    }),
    withHandleFormError,
)(CreateUserForm);
