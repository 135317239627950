import PropTypes from "prop-types";
import React from "react";

import Typography from "@mui/material/Typography";
import clsx from "clsx";

const UnitOfMeasure = (props) => {
    const { component, value, isPlural, className, variant } = props;

    return (
        <Typography component={component} className={clsx("--uppercase", className)} variant={variant}>
            {value}
            {isPlural && "s"}
        </Typography>
    );
};

UnitOfMeasure.defaultProps = {
    component: "span",
    isPlural: true,
};

export default UnitOfMeasure;
