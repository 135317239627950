import { websocketClient } from "../websocket/websocketClient";
import { websocketBase } from "../config";
import {
    WEBSOCKET_DATA_SUB_TYPE_JO_EVENT,
    WEBSOCKET_DATA_TYPE_NOTIFICATION,
    WEBSOCKET_DATA_TYPE_SUB_TYPE_AFFILIATED_COMPANY_USER_SIGN_UP,
    WEBSOCKET_EVENT_TYPE_AFFILIATED_COMPANY_USER_SIGN_UP,
} from "../constants/global";
import { prepareAffiliatedCompanyEvent, prepareRequestEvent } from "../websocket/events";
import { store } from "../app/store";
import { actions } from "../slices/notificationsSlice";
import { showSuccessSnackBar } from "./notifications";
import { getHasUnapprovedUsers } from "./myCompany";

const dispatch = store.dispatch;
const getState = store.getState;

export const initializeWebsocket = () => {
    const connectionUrl = websocketBase;

    // all possible events for tests:

    // setTimeout(() => {
    //     handleEvent({
    //         data_type: 1,
    //         data: {
    //             datetime: "2022-11-21T13:10:07",
    //             user_id: 6284,
    //             username: "username",
    //             company_id: 2452,
    //             company_name: "CompanyName",
    //             description: "signup_to_affiliated_company",
    //         },
    //         subType: 14,
    //     });
    // }, 3000);
    // handleEvent({
    //     data_type: 1,
    //     data: {
    //         datetime: "2022-11-23T09:56:00",
    //         region_id: null,
    //         description: "create_job_request",
    //         companyName: "Test I80 - 11",
    //         firstName: "Test I80 - 11",
    //         lastName: " - 1",
    //         jobOrderId: 95734,
    //         userId: 6198,
    //     },
    //     subType: 4,
    // });
    // handleEvent({
    //     data_type: 1,
    //     data: {
    //         datetime: "2022-11-23T10:03:10",
    //         region_id: null,
    //         description: "fill_request_job",
    //         companyName: "I-80 Concrete",
    //         firstName: "denis",
    //         lastName: "i80",
    //         jobOrderId: 95734,
    //         userId: 6027,
    //     },
    //     subType: 4,
    // });
    // handleEvent({
    //     data_type: 1,
    //     data: {
    //         datetime: "2022-11-23T10:34:37",
    //         region_id: null,
    //         description: "close_job_request",
    //         companyName: "I-80 Concrete",
    //         firstName: "denis",
    //         lastName: "i80",
    //         jobOrderId: 95736,
    //         userId: 6027,
    //     },
    //     subType: 4,
    // });

    websocketClient.setEventCallback(handleEvent);
    websocketClient.initialize(connectionUrl);
};

export const closeWebsocket = () => {
    websocketClient.close();
};

const handleEvent = (msg) => {
    switch (msg.data_type) {
        case WEBSOCKET_DATA_TYPE_NOTIFICATION:
            switch (msg.subType) {
                case WEBSOCKET_DATA_TYPE_SUB_TYPE_AFFILIATED_COMPANY_USER_SIGN_UP:
                    handleAffiliatedCompanyEvent(msg);
                    break;
                case WEBSOCKET_DATA_SUB_TYPE_JO_EVENT:
                    handleRequestsEvent(msg);
                    break;
            }
            break;
        default:
            return;
    }
};

const handleNotificationEvent = (event) => {
    dispatch(showSuccessSnackBar(event.notificationTitle));
    dispatch(actions.addNotification(event));
};

const handleAffiliatedCompanyEvent = (msg) => {
    const affiliatedCompanyEvent = prepareAffiliatedCompanyEvent({ event: { ...msg.data, dataSubType: msg.subType } });

    switch (affiliatedCompanyEvent.type) {
        case WEBSOCKET_EVENT_TYPE_AFFILIATED_COMPANY_USER_SIGN_UP: {
            dispatch(getHasUnapprovedUsers());
        }
    }
    handleNotificationEvent(affiliatedCompanyEvent);
};

const handleRequestsEvent = (msg) => {
    const requestEvent = prepareRequestEvent({ event: { ...msg.data, dataSubType: msg.subType } });
    handleNotificationEvent(requestEvent);
};
