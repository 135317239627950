import {useEffect} from "react";

const useTabIcon = (logo)=>{
    useEffect(() => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = logo;

    }, []);
}

export default useTabIcon