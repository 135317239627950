import React from "react";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { connect, useSelector } from "react-redux";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import QuantityWithUnlimited from "../../components/core/QuantityField";
import { Validation } from "../../utils/validation";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
} from "../../components/core/form/AsyncAutocompleteComponent";
import TextInput from "../../components/core/form/TextInput";
import DateTimePicker from "../../components/core/DateTimePicker";
import { SecondaryButton } from "../../components";
import PrimaryButton from "../../components/core/buttons/PrimaryButton";
import { useActions } from "../../hooks";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import Loader from "../../components/core/loader";
import actionCreators from "../../actionCreators";
import { selectRequestDetailsMeta, selectRequestsSuccessMessage } from "../../slices/requestSlice";
import { CREATE_REQUEST } from "../../constants/forms";
import DropOffSelector from "../Sites/DropOffSelector/DropOffSelector";
import { withHandleFormError } from "../../hoc";
import SuccessMessage from "../../components/core/messages/SuccessMessage";

const ButtonContainer = styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(400)]: {
        flexDirection: "column",
    },
}));

const SecondaryButtonStyled = styled(SecondaryButton)(({ theme }) => ({
    [theme.breakpoints.down(400)]: {
        width: "100%",
        marginBottom: 10,
    },
    [theme.breakpoints.up(400)]: {
        marginRight: 10,
    },
}));

const PrimaryButtonStyled = styled(PrimaryButton)(({ theme }) => ({
    [theme.breakpoints.down(400)]: {
        width: "100%",
    },
}));

const StartDateStyled = styled(Grid)(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-end",

    [theme.breakpoints.down(600)]: {
        display: "flex",
        justifyContent: "flex-start",
    },
}));
const CreateRequest = ({
    form,
    dispatch,
    handleSubmit,
    submitting,
    error,
    dropOffSites,
    handleCloseModal,
    requestId,
}) => {
    const { loadMaterials, updateRequestSuccessMessage, loadUnitsOfMeasureOptions } = useActions();
    const { loading } = useSelector(selectRequestDetailsMeta);
    const successMessage = useSelector(selectRequestsSuccessMessage);

    const isEdit = !!requestId;

    const onCancel = () => {
        handleCloseModal && handleCloseModal();
    };

    return (
        <form onSubmit={handleSubmit} className={(loading || submitting) && LOADER_WHITE_OVERLAY}>
            {(loading || submitting) && <Loader />}
            <Grid container spacing={2} sx={{ p: 3 }}>
                <Grid item xs={6}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant="h2" sx={{ width: 115, mr: 1 }}>
                            Ext. Ref &nbsp;&nbsp;
                        </Typography>
                        <Field
                            name="extRef"
                            disabled={true}
                            type="text"
                            className="job-order-input-field ext-ref-field"
                            placeholder="#"
                            validate={[Validation.noSpecialSymbols]}
                            fullWidth
                            needShowEndAdornment={false}
                            component={TextInput}
                        />
                    </Box>
                </Grid>
                <StartDateStyled item xs={12} sm={6}>
                    <Field name="startDate" component={DateTimePicker} />
                </StartDateStyled>
                <Grid item xs={12} sm={4}>
                    <Field
                        name={"material"}
                        validate={[Validation.required]}
                        loadOptions={loadMaterials}
                        placeholder="Material"
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        component={AsyncAutocompleteComponent}
                        disabled={isEdit}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Field
                        name={"unitOfMeasure"}
                        validate={[Validation.required]}
                        loadOptions={loadUnitsOfMeasureOptions}
                        placeholder="Unit Of Measure"
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        component={AsyncAutocompleteComponent}
                        disabled={isEdit}
                        isSearchable={false}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <QuantityWithUnlimited form={form} name="totalQuantity" dispatch={dispatch} showUnlimited={false} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DropOffSelector form={form} dropOffSites={dropOffSites} maxMenuHeight={210} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Field
                        name="jobContact"
                        label="Point-of-contact"
                        placeholder="Point-of-contact"
                        needShowEndAdornment={false}
                        component={TextInput}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <Field
                        name="requestNotes"
                        rows={3}
                        label="Request notes (optional)"
                        placeholder="Request notes (optional)"
                        multiline={true}
                        needShowEndAdornment={false}
                        component={TextInput}
                    />
                </Grid>
            </Grid>
            <ButtonContainer sx={{ px: 2, pb: 2 }}>
                <SecondaryButtonStyled onClick={onCancel}>CANCEL</SecondaryButtonStyled>
                <PrimaryButtonStyled type="submit">SUBMIT</PrimaryButtonStyled>
            </ButtonContainer>
            <SuccessMessage message={successMessage} onClose={() => updateRequestSuccessMessage(null)} />
        </form>
    );
};

export const onSubmit = (values, dispatch, props) => {
    if (props.requestId) {
        return dispatch(actionCreators.updateRequest(props.requestId, values));
    }
    return dispatch(actionCreators.createRequest(values));
};

export default compose(
    connect((state) => {
        return {
            requestId: formValueSelector(CREATE_REQUEST)(state, "requestId"),
            dropOffSites: formValueSelector(CREATE_REQUEST)(state, "dropOffSites"),
        };
    }),
    reduxForm({
        form: CREATE_REQUEST,
        onSubmit,
    }),
    withHandleFormError,
)(CreateRequest);
