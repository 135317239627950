import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

const buttonsGroupStyles = makeStyles(() => ({
    buttonsGroup: {
        flex: 1,
        "& > button:not(:first-child)": {
            marginLeft: 15,
        },
    },
}));

export const ButtonsGroup = (props) => {
    const { children, justifyContent, className } = props;
    const classes = buttonsGroupStyles();

    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent={justifyContent}
            alignItems="center"
            className={clsx(classes.buttonsGroup, className)}
        >
            {children}
        </Box>
    );
};

ButtonsGroup.defaultProps = {
    component: "div",
    justifyContent: "center",
    otherProps: {},
};

const buttonsGroupVerticalStyles = makeStyles(() => ({
    buttonGroupVertical: {
        "& button:not(:last-child)": {
            marginBottom: 15,
        },
    },
}));

export const ButtonsGroupVertical = (props) => {
    const { children, justifyContent } = props;
    const classes = buttonsGroupVerticalStyles();

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent={justifyContent}
            alignItems="center"
            className={classes.buttonGroupVertical}
        >
            {children}
        </Box>
    );
};

ButtonsGroupVertical.defaultProps = {
    component: "div",
    justifyContent: "flex-end",
    otherProps: {},
};
