import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { destroy } from "redux-form";
import { actions } from "../slices";
import actionCreators from "../actionCreators";

export default function useActions() {
    const dispatch = useDispatch();

    return bindActionCreators(
        {
            ...actionCreators,
            ...actions,
            destroy,
        },
        dispatch,
    );
}
