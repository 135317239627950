import React from "react";

import { Grid } from "@mui/material";
import { Field, formValueSelector } from "redux-form";
import TextInputComponent from "../../../components/core/form/TextInput";
import { Validation } from "../../../utils/validation";
import { ButtonsGroup } from "../../../components/core/buttons/ButtonsGroup/buttonsGroup";
import CustomCheckbox from "../../../components/core/form/СustomCheckbox";
import { connect } from "react-redux";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
} from "../../../components/core/form/AsyncAutocompleteComponent";
import { useActions } from "../../../hooks";
import CompanyAddressForm from "./CompanyAddressForm";
import EmailField from "../../../components/core/form/EmailField/emailField";
import PhoneInput from "../../../components/core/form/PhoneInput/PhoneInput";

export const EXISTING_COMPANY_VALUE = "existingCompany";
export const NEW_COMPANY_VALUE = "newCompany";

const CompanyContactInfoForm = ({ setStateValue, formValues: companyExisting }) => {
    const { getCompaniesOptions } = useActions();

    return (
        <Grid container justify={"center"}>
            <Grid item xs={12}>
                <br />
                <h4 className="--text-center">Do you want to register under existing company or create a new one?</h4>
                <ButtonsGroup justifyContent={"space-around"}>
                    <Field
                        name="companyExisting"
                        type="radio"
                        value={EXISTING_COMPANY_VALUE}
                        label="Existing Company"
                        component={CustomCheckbox}
                    />
                    <Field
                        name="companyExisting"
                        type="radio"
                        value={NEW_COMPANY_VALUE}
                        label="New Company"
                        component={CustomCheckbox}
                    />
                </ButtonsGroup>
                <br />
                {companyExisting === EXISTING_COMPANY_VALUE && (
                    <Field
                        name="registrationCompanyName"
                        validate={[Validation.required]}
                        label="Company"
                        type="text"
                        component={TextInputComponent}
                    />
                )}
                {companyExisting === NEW_COMPANY_VALUE && (
                    <>
                        <Field
                            name="companyName"
                            validate={[Validation.required, Validation.noSpecialSymbols]}
                            type="text"
                            label="Company Name"
                            component={TextInputComponent}
                        />
                        <CompanyAddressForm setStateValue={setStateValue} />
                    </>
                )}
                <h3 style={{ textAlign: "center" }}>Contact</h3>
                <Field
                    name="userContactName"
                    validate={[Validation.required, Validation.noSpecialSymbols]}
                    type="text"
                    label="Name"
                    component={TextInputComponent}
                />
                <br />
                <br />
                <EmailField isRequired name="userContactEmail" />
                <br />
                <br />
                <PhoneInput isRequired name="userContactPhone" />
            </Grid>
        </Grid>
    );
};

export default connect((state, props) => {
    const formSelector = formValueSelector(props.form);

    return {
        formValues: formSelector(state, "companyExisting"),
    };
})(CompanyContactInfoForm);
