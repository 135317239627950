import { getNextPage } from "../api/endpoints";
import { fetchAffiliatedCompanies } from "../api/companies";
import { PARENT_COMPANY_ID } from "../constants/customersMapper/customersMapper";

export const getCompaniesOptions =
    (inputValue, { params = {}, loadedCount }) =>
    async () => {
        try {
            const requestParams = { ...params };
            if (inputValue) {
                requestParams.keywords = inputValue;
            }

            const { data: companies, meta } = await fetchAffiliatedCompanies(PARENT_COMPANY_ID, requestParams);

            const nextPage = getNextPage(loadedCount);

            const options = companies.map((company) => ({
                label: company.name,
                value: company.id,
            }));

            return {
                options: options,
                hasMore: meta.count > (loadedCount || options.length),
                page: nextPage,
            };
        } catch (error) {}
    };
