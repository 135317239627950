import React, { useEffect } from "react";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";

import Loader, { fixedLoaderStyles } from "../../../components/core/loader";
import {
    ACTIVE_TAB_INDEX,
    ARCHIVE_TAB_INDEX,
    DEFAULT_DATE_FORMAT,
    I80_ADMIN_TABLE_ROLES,
    IS_ADMIN_USER,
    LOADER_WHITE_OVERLAY,
    PENDING_TAB_INDEX,
} from "../../../constants/global";
import TableWithStickyHeader from "../../../components/core/tables/TableWithStickyHeader";
import { useActions } from "../../../hooks";
import {
    selectMyTeamActiveOrder,
    selectMyTeamActiveTab,
    selectMyTeamData,
    selectMyTeamMeta,
} from "../../../slices/myCompanySlice";
import { selectAccount, selectAccountRole } from "../../../slices/userSlice";
import SignUpApprovalButtons from "./SignUpApprovalButtons";
import ErrorMessage from "../../../components/core/messages/ErrorMessage";

export const IS_NEED_CONFIRM_ORDER = "isNeedConfirm";
export const LAST_LOGIN_ORDER = "lastLogin";
export const COMPANY_NAME_COLUMN_INDEX = 1;
export const ROLE_COLUMN_INDEX = 2;
export const APPROVED_BY_COLUMN_INDEX = 6;
export const REJECTED_BY_COLUMN_INDEX = 6;

const MyTeamList = (props) => {
    const { getMyTeamUsers, refreshMyTeam, handleMyTeamUsersSort, resetMyTeam } = useActions();

    const users = useSelector(selectMyTeamData);
    const { loading, error } = useSelector(selectMyTeamMeta);
    const myTeamActiveOrder = useSelector(selectMyTeamActiveOrder);
    const activeTab = useSelector(selectMyTeamActiveTab);
    const account = useSelector(selectAccount);
    const role = useSelector(selectAccountRole);
    const isAdmin = IS_ADMIN_USER(role);

    const getTableData = () => {
        const rows = users.map((user, index) => {
            const {
                id,
                firstName,
                lastName,
                username,
                company,
                role,
                email,
                phone,
                lastLogin,
                isNeedConfirm,
                registrationCompanyName,
                approvedBy,
                rejectedBy,
            } = user;

            return {
                index,
                id,
                name: `${firstName} ${lastName}`,
                username,
                companyName: company.name,
                role: I80_ADMIN_TABLE_ROLES(role),
                email,
                phone,
                lastLogin: lastLogin ? moment(lastLogin).tz(account.timezone).format(DEFAULT_DATE_FORMAT) : null,
                // action: (
                //     <MyTeamsActionMenu
                //         activeOrderParam={activeTeamUsersOrder}
                //         itemForEdit={user}
                //         activeTab={activeTab}
                //         account={account}
                //         userName={`${firstName} ${lastName}`}
                //         timezone={account.timezone}
                //         userId={id}
                //     />
                // ),
                approvedBy: approvedBy?.username,
                rejectedBy: rejectedBy?.username,
                approval:
                    isAdmin && isNeedConfirm ? (
                        <SignUpApprovalButtons userId={id} registrationCompanyName={registrationCompanyName} />
                    ) : null,
            };
        });
        let columns = [
            {
                id: "name",
                label: "NAME",
                sortParam: "lastName,firstName",
            },
            {
                id: "username",
                label: "USER NAME",
                sortParam: "username",
            },
            {
                id: "phone",
                label: "PHONE",
                sortParam: "phone",
            },
            {
                id: "email",
                label: "EMAIL",
                sortParam: "email",
            },
            // { id: "action", label: TEXT_ACTIONS_TABLE_CELL },
        ];

        if (activeTab === ACTIVE_TAB_INDEX) {
            columns.push({
                id: "lastLogin",
                label: "LAST LOGIN DATE",
                sortParam: LAST_LOGIN_ORDER,
            });
        }

        if (isAdmin) {
            columns.splice(COMPANY_NAME_COLUMN_INDEX, 0, { id: "companyName", label: "COMPANY", sortParam: "company" });
            columns.splice(ROLE_COLUMN_INDEX, 0, {
                id: "role",
                label: "ROLE",
                // sortParam: "role"
            });

            if (activeTab === ACTIVE_TAB_INDEX) {
                columns.splice(APPROVED_BY_COLUMN_INDEX, 0, {
                    id: "approvedBy",
                    label: "Approved by",
                    // sortParam: ,
                });
            }
            if (activeTab === ARCHIVE_TAB_INDEX) {
                columns.splice(REJECTED_BY_COLUMN_INDEX, 0, {
                    id: "rejectedBy",
                    label: "Rejected by",
                    // sortParam: ,
                });
            }
            if (activeTab === PENDING_TAB_INDEX) {
                columns.unshift({
                    id: "approval",
                    label: "sign up approval",
                });
            }
        }

        return { rows: rows, columns: columns };
    };

    useEffect(() => {
        getMyTeamUsers();

        return () => {
            resetMyTeam();
        };
    }, []);

    return (
        <React.Fragment>
            <div className={clsx(loading && `${LOADER_WHITE_OVERLAY}`, _.isEmpty(users) && "-min-height")}>
                {loading && <Loader style={fixedLoaderStyles()} />}
                {!_.isEmpty(users) && (
                    <TableWithStickyHeader
                        rows={getTableData().rows}
                        fetchMore={getMyTeamUsers}
                        columns={getTableData().columns}
                        handleSort={handleMyTeamUsersSort}
                        activeOrderParam={myTeamActiveOrder}
                    />
                )}
            </div>
            {_.isEmpty(users) && !loading && (
                <React.Fragment>
                    <br />
                    <h1 className="--text-center">No users match your search / filter parameters.</h1>
                    <br />
                </React.Fragment>
            )}
            <ErrorMessage error={error} onClose={refreshMyTeam} />
        </React.Fragment>
    );
};

export default MyTeamList;
