const API = "1/";
const API_2 = "2/";
const API_3 = "3/";

export const GLOBAL_COUNT_TO_LOAD = 20;

export const getNextPage = (loadedCount) => {
    const currentPage = Math.ceil(loadedCount / GLOBAL_COUNT_TO_LOAD);
    const nextPage = loadedCount ? +currentPage + 1 : 1;

    return nextPage;
};

export const AUTH_ENDPOINTS = {
    SIGN_IN: () => `${API_2}signin`,
    RESTORE: () => `${API}restore`,
};

export const JOB_ORDER_ENDPOINTS = {
    REQUESTS: () => `${API_2}job-orders`,
    REQUEST_DETAILS: (requestId) => `${API_2}job-orders/${requestId}`,
    UPDATE_REQUEST: (requestId) => `${API_3}job-orders/${requestId}`,
    REQUEST_TURNTIME: (requestId) => `${API_2}job-orders/${requestId}/turntimes`,
    REQUESTS_ROUTES: (requestId) => `${API_2}job-orders/${requestId}/routes`,
    REQUESTS_MAP: (requestId) => `${API_2}job-orders/${requestId}/map-details`,
    REQUEST_ETA: () => `${API_2}trucks/estimated-time`,
    REQUEST_STATS_TAB: (requestId) => `${API_2}job-orders/${requestId}/turntimes`,
};

export const PROJECT_ENDPOINTS = {
    PROJECTS: () => `${API_2}projects`,
    PROJECTS_DETAILS: (projectId) => `${API_2}projects/${projectId}`,
    PROJECT_PO_ITEMS: (projectId) => `${API_2}projects/${projectId}/po-items`,
};

export const PURCHASE_ORDER_ENDPOINTS = {
    CREATE_PURCHASE_ORDER: () => `1/purchase-orders`,
    POLI: (poId) => `2/purchase-orders/${poId}/items`,
};

export const SITE_ENDPOINTS = {
    SITES: () => `${API}sites`,
    SITE_DETAILS: (siteId) => `${API}sites/${siteId}`,
    SITE_ALERT_ZONES: (siteId) => `${API}regions?siteId=${siteId}`,
};

export const UNIT_OF_MEASURE_ENDPOINTS = {
    UNITS_OF_MEASURE: () => `${API}units-of-measure`,
};

export const PAYLOAD_ENDPOINTS = {
    MATERIALS: (companyId) => `${API_2}payloads/${companyId}`,
};

export const USER_ENDPOINTS = {
    USERS: () => `${API}users`,
    USER_BY_ID: (id) => `${API}users/${id}`,
    ACCEPT_TERMS_AND_CONDITIONS: () => `1/users/current/terms-and-conditions/agree`,
    USER_SIGN_UP_DECISION: (id) => `${API}users/${id}/decision`,
};

export const COMPANY_ENDPOINTS = {
    COMPANIES: () => `${API_2}companies`,
    AFFILIATED_COMPANIES: (companyId) => `${API_2}companies/${companyId}/affiliated-companies`,
    INVITE_COMPANY: () => `${API}invite-hauler`,
};
