import { LOGO_SVG } from "../constants/customersMapper/customersMapper";

const Logo = ({ width, height }) => {
    return <img width={width} height={height} id="logo" alt="customer-logo" src={LOGO_SVG} />;
};

Logo.defaultProps = {
    width: "150px",
    height: "150px",
};

export default Logo;
