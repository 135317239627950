import dataProvider from "./httpRequest";
import { AUTH_ENDPOINTS, PAYLOAD_ENDPOINTS, UNIT_OF_MEASURE_ENDPOINTS, USER_ENDPOINTS } from "./endpoints";
import { USER } from "../constants/endpoints/user";

export const signIn = async (data) => {
    return await dataProvider({ url: AUTH_ENDPOINTS.SIGN_IN(), method: "POST", data });
};

export const userSignUp = (data) => {
    return dataProvider({
        url: USER.SIGN_UP(),
        method: "POST",
        data,
    });
};

export const fetchContacts = (params) => {
    return dataProvider({
        url: USER_ENDPOINTS.USERS(),
        method: "GET",
        params: {
            ...params,
            truckerType: 1,
        },
    });
};

export const agreeWithTermsAndConditionsRequest = () => {
    return dataProvider({
        url: USER_ENDPOINTS.ACCEPT_TERMS_AND_CONDITIONS(),
        method: "POST",
    });
};

export const fetchMaterials = async (companyId, params) => {
    return await dataProvider({
        url: PAYLOAD_ENDPOINTS.MATERIALS(companyId),
        method: "GET",
        params: {
            ...params,
            archived: false,
        },
    });
};

export const fetchUnitsOfMeasure = (params) => {
    return dataProvider({
        url: UNIT_OF_MEASURE_ENDPOINTS.UNITS_OF_MEASURE(),
        method: "GET",
        params,
    });
};
