import { AUTH_ENDPOINTS } from "./endpoints";
import httpRequest from "./httpRequest";

export const postRestore = async (data) => {
    return await httpRequest({
        url: AUTH_ENDPOINTS.RESTORE(),
        method: "POST",
        data,
    });
};

export const putRestore = async (data) => {
    return await httpRequest({
        url: AUTH_ENDPOINTS.RESTORE(),
        method: "PUT",
        data,
    });
};
