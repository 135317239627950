import React from "react";
import { Outlet } from "react-router-dom";

import MyCompanySubNav from "./MyCompanySubNav/MyCompanySubNav";

const MyCompany = () => {
    return (
        <div className="my-company">
            <MyCompanySubNav />
            <Outlet />
        </div>
    );
};

export default MyCompany;
