import * as api from "../api/auth";
import handleFormErrors from "../utils/handleFormErrors";

export const sendPasswordRestoreEmail = (values) => async (dispatch) => {
    try {
        await api.postRestore(values);
    } catch (e) {
        handleFormErrors(e);
    }
};

export const updatePassword = (values) => async (dispatch) => {
    try {
        const body = {
            password: values.password,
            token: values.token,
        };
        await api.putRestore(body);
    } catch (e) {
        handleFormErrors(e);
    }
};
