import React from "react";
import { Field } from "redux-form";
import TextInputComponent from "../../../components/core/form/TextInput";
import { Validation } from "../../../utils/validation";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
} from "../../../components/core/form/AsyncAutocompleteComponent";
import { loadStatesOptions } from "../../../actionCreators/requests";

const CompanyAddressForm = (props) => {
    const { setStateValue } = props;

    return (
        <React.Fragment>
            <br />
            <br />
            <h3 style={{ textAlign: "center" }}>Company Address</h3>
            <Field
                name="streetAddress"
                validate={[Validation.required, Validation.noSpecialSymbols]}
                type="text"
                label="Street Address"
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                name="secondaryAddress"
                validate={[Validation.noSpecialSymbols]}
                type="text"
                label="Secondary Address"
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                name="city"
                validate={[Validation.required, Validation.noSpecialSymbols]}
                type="text"
                label="City"
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                name="state"
                placeholder="State"
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                validate={[Validation.required]}
                onFieldChange={setStateValue}
                loadOptions={loadStatesOptions}
                component={AsyncAutocompleteComponent}
            />
            <br />
            <Field
                name="zipCode"
                validate={[Validation.required]}
                type="text"
                label="Zip Code"
                component={TextInputComponent}
            />
            <br />
            <br />
        </React.Fragment>
    );
};

export default CompanyAddressForm;
