import * as React from "react";
import moment from "moment";

import Stack from "@mui/material/Stack";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";

const _format = "YYYY-MM-DD";

export default function BasicDatePicker({ handleDateChange, renderInput, input, meta }) {
    const onChange = (date, value) => {
        input.onChange(moment(date).format(_format));
        handleDateChange && handleDateChange(date, value);
    };
    const onArrowClick = (e) => {
        const action = e.target.dataset.action;
        const name = e.target.dataset.name;

        switch (action) {
            case "prev":
                onChange(moment(moment(input.value).subtract(1, "days")).format(_format), name);
                break;
            case "next":
                onChange(moment(moment(input.value).add(1, "days")).format(_format), name);
                break;
            default:
                break;
        }
    };
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack direction="row">
                <IconButton id="prev-button" size={"small"} sx={{ p: 0 }} onClick={onArrowClick}>
                    <ArrowLeftIcon data-name={input.name} data-action="prev" fontSize="small" />
                </IconButton>
                <DatePicker
                    {...input}
                    renderInput={(props) => (renderInput && renderInput(props)) || <TextField {...props} />}
                    value={input.value}
                    onChange={onChange}
                />
                <IconButton
                    data-name={input.name}
                    data-action="next"
                    size={"small"}
                    sx={{ p: 0 }}
                    onClick={onArrowClick}
                >
                    <ArrowRightIcon data-name={input.name} data-action="next" fontSize="small" />
                </IconButton>
            </Stack>
        </LocalizationProvider>
    );
}

