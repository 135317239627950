import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import * as routes from "../../routes/routesStrings";
import { ForgotPassword, SignIn } from "../../pages";
import SignUp from "../../pages/SignUp/SignUp";

const UnauthenticatedRoutes = () => {
    return (
        <Routes>
            <Route path={routes.ROUTE_SIGNIN} element={<SignIn />} />
            <Route path={routes.ROUTE_FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={routes.ROUTE_SIGNUP} element={<SignUp />} />
            <Route path="*" element={<Navigate to={routes.ROUTE_SIGNIN} />} />
        </Routes>
    );
};

export default UnauthenticatedRoutes;
