import React from "react";
import { Field } from "redux-form";
import { Validation } from "../../../../utils/validation";
import TextInputComponent from "../TextInput";

const EmailField = (props) => {
    const { isRequired, name, className, fieldNote, disabled, label } = props;
    let validation = [Validation.email];

    if (isRequired) {
        validation = [Validation.required, ...validation];
    }

    return (
        <Field
            name={name}
            validate={validation}
            className={className}
            type="email"
            label={label ?? "Email"}
            fieldNote={fieldNote}
            component={TextInputComponent}
            disabled={disabled}
        />
    );
};

EmailField.defaultProps = {
    name: "email",
};

export default EmailField;
