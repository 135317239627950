import { createTheme } from "@mui/material/styles";

const BREAKPOINT_VALUES = {
    xs: 1024,
    sm: 1280,
    md: 1440,
    lg: 1855,
    xl: 1920,
};

export const BODY_FONT_SIZE = 14;
export const GLOBAL_CONTENT_WIDTH = "81%";

export const MAIN_THEME = createTheme({
    spacing: 8,
    // breakpoints: {
    //     values: BREAKPOINT_VALUES,
    // },
    palette: {
        primary: {
            superLight: "#fbf7f3",
            light: "#F3B175",
            main: "#b96211",
            dark: "#d37622",
            superDark: "#D68530",
            disabled: "#A37E4E",
        },
        secondary: {
            superLight: "#d8d8d8",
            light: "#868686",
            main: "#979797",
            superDark: "#393939",
            dark: "#505050",
        },
        ternary: {
            superLight: "rgba(216,216,216,0.25)",
            light: "#bebebe",
            main: "rgba(255, 255, 255, 0.7)",
            dark: "#868686",
        },
        text: {
            primary: "#505050",
            secondary: "#393939",
            disabled: "rgba(0, 0, 0, 0.38)",
        },
        background: {
            default: "#fff",
            paper: "#fff",
        },
        border: {
            primary: "#979797",
        },
        general: {
            active: "#52b96d",
            pending: "#4a3ef3",
            paleBlue: "#4e74e6",
            lightBlue: "#7ad7f0",
            paleRed: "#f5473d",
            softRed: "#ea9b97",
            softPink: "#FFDFDE",
            darkishRed: "#b00001",
            softGreen: "#4dba6a",
            priceGreen: "#289e40",
            softOrange: "#ffd6b1",
            lightGrey: "#f1f1f1",
            paleGrey: "#f5f5f5",
            grey: "#ebebeb",
            black: "#000000",
            green: "#229440",
            emeraldGreen: "#007a20",
            paleOrange: "rgba(185, 98, 17, 0.05)",
        },
        // error: will use the default color
    },
    typography: {
        htmlFontSize: BODY_FONT_SIZE,
        fontSize: BODY_FONT_SIZE,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        fontFamily: '"Roboto", "Times New Roman", serif',
        button: {
            fontSize: BODY_FONT_SIZE,
            letterSpacing: 0,
            fontWeight: 600,
        },
        h1: {
            fontSize: 24,
            fontWeight: 600,
            lineHeight: 1,
            letterSpacing: 0.75,
        },
        h2: {
            fontSize: 18,
            fontWeight: 600,
            lineHeight: "20px",
        },
        h3: {
            fontSize: 16,
            letterSpacing: 0.5,
            fontWeight: 600,
            lineHeight: 1,
        },
        h4: {
            fontSize: BODY_FONT_SIZE,
            lineHeight: "20px",
            fontWeight: 500,
        },
        h5: {
            fontSize: 21,
            lineHeight: "20px",
            letterSpacing: 0,
            fontWeight: 600,
        },
        body1: {
            fontSize: BODY_FONT_SIZE,
        },
        link: {
            fontSize: BODY_FONT_SIZE,
            textDecoration: "underline",
        },
    },
    shape: {
        borderRadius: 4,
    },
    overrides: {
        MuiButton: {
            root: {
                transitionProperty:
                    "background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            },
        },
    },
    icon: {
        width: 24,
        height: 24,
    },
    mainButton: {
        boxShadow: "0 2px 4px 0 rgb(83 83 83 / 50%)",
        color: "#fff",
        background: `linear-gradient(129deg, ${"#F3B175"} 0%, ${"#d37622"}) 80%`,

        "&:hover, &:active": {
            color: "#fff",
            background: `linear-gradient(129deg, ${"#d37622"} 0%, ${"#d37622"}) 80%`,
        },
    },
});
