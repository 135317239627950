import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const ErrorMessage = ({ onClose, error }) => {
    return (
        <Snackbar
            open={!!error}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={onClose}
        >
            <Alert variant="filled" severity="error">
                {error}
            </Alert>
        </Snackbar>
    );
};

export default ErrorMessage;
