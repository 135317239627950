import { PROJECT_ENDPOINTS, PURCHASE_ORDER_ENDPOINTS } from "./endpoints";
import dataProvider from "./httpRequest";

export const requestProjects = async (params) => {
    return await dataProvider({
        url: PROJECT_ENDPOINTS.PROJECTS(),
        method: "GET",
        params: {
            ...params,
            order: "-createdDate",
            perPage: 20,
        },
    });
};

export const requestProjectDetails = async (projectId) => {
    return await dataProvider({
        url: PROJECT_ENDPOINTS.PROJECTS_DETAILS(projectId),
        method: "GET",
    });
};

export const requestProjectPoItems = async (projectId, params) => {
    return await dataProvider({
        url: PROJECT_ENDPOINTS.PROJECT_PO_ITEMS(projectId),
        method: "GET",
        params: {
            ...params,
            perPage: 20,
            order: "name",
        },
    });
};

export const createPurchaseOrder = (data) => {
    return dataProvider({
        url: PURCHASE_ORDER_ENDPOINTS.CREATE_PURCHASE_ORDER(),
        method: "POST",
        data,
    });
};
export const createPurchaseOrdersItems = (purchaseOrderId, data) => {
    return dataProvider({
        url: PURCHASE_ORDER_ENDPOINTS.POLI(purchaseOrderId),
        method: "POST",
        data,
    });
};
