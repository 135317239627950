import React from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { THEME_ICONS } from "../../../styles/icons";
import SubNavigation, { subNavigationStyles } from "../../../components/core/SubNavigation";
import { Grid, Icon } from "@mui/material";
import PinDropIcon from "@mui/icons-material/PinDrop";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { ROUTE_PROJECTS_AND_SITES } from "../../../routes/routesStrings";
import { GLOBAL_CONTENT_WIDTH } from "../../../styles/globalThemeConfig";

const activeClass = "active";

const useStyles = makeStyles((theme) => ({
    uiIconProjects: {
        ...THEME_ICONS.eventIcon,
        marginRight: 20,
    },
    subNav: { padding: "9px 0" },
    uiIconSites: {
        width: theme.icon.width,
        height: theme.icon.height,
        marginRight: 20,
    },
}));

const ProjectsAndSitesSubNav = (props) => {
    const classes = useStyles();
    const subNavigationClasses = subNavigationStyles();

    const { pathname } = useLocation();

    return (
        <SubNavigation subMenuWidth={GLOBAL_CONTENT_WIDTH} subNavStyles={classes.subNav}>
            <Grid
                className={subNavigationClasses.trucksSubMenuResponsive}
                container
                component="div"
                alignItems={"center"}
            >
                <Grid item component="div">
                    <div
                        className={clsx(
                            subNavigationClasses.subMenuItems,
                            pathname.includes(ROUTE_PROJECTS_AND_SITES.PROJECTS) && activeClass,
                        )}
                    >
                        <AccountTreeIcon className={classes.uiIconProjects} />
                        <Link to={ROUTE_PROJECTS_AND_SITES.PROJECTS}>Projects</Link>
                    </div>
                </Grid>
                <Grid item component="div">
                    <div
                        className={clsx(
                            subNavigationClasses.subMenuItems,
                            pathname.includes(ROUTE_PROJECTS_AND_SITES.SITES) && activeClass,
                        )}
                    >
                        <PinDropIcon className={classes.uiIconSites} />
                        <Link to={ROUTE_PROJECTS_AND_SITES.SITES}>Sites</Link>
                    </div>
                </Grid>
            </Grid>
        </SubNavigation>
    );
};

export default ProjectsAndSitesSubNav;
