import { getFormValues } from "redux-form";

import { actions, selectCurrentSite, selectSitesMeta } from "../slices/sitesSlice";
import * as api from "../api/sites";
import { getNextPage } from "../api/endpoints";
import { SITES_FILTERS_FORM } from "../constants/forms";
import { handleError } from "../utils";
import { selectIfIsWebDimensions } from "../slices/utilsSlice";

export const getSitesList = () => async (dispatch, getState) => {
    try {
        const filtersValues = getFormValues(SITES_FILTERS_FORM)(getState());
        const { page, hasMore } = selectSitesMeta(getState());
        if (hasMore) {
            const currentSite = selectCurrentSite(getState());
            dispatch(actions.requestSites());
            const params = {
                page,
            };
            if (filtersValues?.filters?.status?.length) params.status = filtersValues.filters.status.join();
            if (filtersValues?.filters?.accessType?.length) params.accessType = filtersValues.filters.accessType.join();
            if (filtersValues?.keywords) params.keywords = filtersValues.keywords;

            const result = await api.fetchSites(params);
            const sitesAmount = result?.data?.length;
            if (sitesAmount && !currentSite) {
                dispatch(getSiteDetails(result.data[0].id));
            }
            if (sitesAmount === 0) {
                dispatch(actions.resultCurrentSiteDetails(null));
            }
            dispatch(actions.resultSites(result));
        }
    } catch (error) {
        dispatch(actions.resultSites(handleError(error)));
    }
};

export const refreshSitesList = () => (dispatch) => {
    dispatch(actions.refreshSites());
    dispatch(getSitesList());
};

export const getSiteDetails = (siteId) => async (dispatch) => {
    try {
        if (siteId) {
            dispatch(actions.requestCurrentSiteDetails());
            const { data } = await api.fetchSiteDetails(siteId);
            const { data: alertZones } = await api.fetchSiteAlertZones(siteId);
            dispatch(actions.resultCurrentSiteDetails({ ...data, alertZones }));
        } else {
            dispatch(actions.resultCurrentSiteDetails(null));
        }
    } catch (error) {}
};

export const loadDropOffSites =
    (inputValue, { params = {}, loadedCount }) =>
    async () => {
        try {
            let stateParams = { ...params };
            if (inputValue) stateParams.keywords = inputValue;

            const { data, meta } = await api.fetchDropOffSites(stateParams);

            const nextPage = getNextPage(loadedCount);

            const options = data.map((state) => ({
                label: state.name,
                value: state.id,
            }));

            return {
                options: options,
                hasMore: meta.count > (loadedCount || options.length),
                page: nextPage,
            };
        } catch (error) {
            throw new Error(handleError(error));
        }
    };
