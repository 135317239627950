import React, { Fragment, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@mui/styles";
import {
    Avatar,
    Badge,
    ClickAwayListener,
    Divider,
    Grid,
    Grow,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListSubheader,
    Paper,
    Popper,
    Tooltip,
} from "@mui/material";

import NotificationLabel from "./NotificationLabel";
import { THEME_ICONS } from "../../../styles/icons";
import { selectNotifications } from "../../../slices/notificationsSlice";

const useStyles = makeStyles(() => ({
    uiIconNotifications: {
        ...THEME_ICONS.notifications,
    },
    notificationsList: {
        maxHeight: 280,
        overflowY: "auto",
    },
}));

const NavigationNotifications = (props) => {
    const classes = useStyles();
    const [showNotifications, openNotifications] = useState(false);
    const notificationsAnchorRef = useRef(null);
    const notifications = useSelector(selectNotifications);

    return (
        <>
            <IconButton
                id="notification"
                sx={{ width: 40, ml: 1 }}
                onClick={() => openNotifications(!showNotifications)}
            >
                <Tooltip title="Notifications" enterDelay={600}>
                    <Badge id="notification" color="error" badgeContent={notifications.length}>
                        <i ref={notificationsAnchorRef} className={classes.uiIconNotifications} id="notification" />
                    </Badge>
                </Tooltip>
            </IconButton>
            <Popper open={showNotifications} anchorEl={notificationsAnchorRef.current} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener
                                onClickAway={(e) => {
                                    if (!(e.target.localName === "span") && e.target.id !== "notification") {
                                        openNotifications(!showNotifications);
                                    }
                                }}
                            >
                                <List>
                                    <ListSubheader disableSticky>
                                        You have {notifications.length} notifications
                                    </ListSubheader>
                                    <div className={classes.notificationsList}>
                                        {notifications.map((notificationEvent, index) => {
                                            const { displayDate, notificationTitle } = notificationEvent;

                                            return (
                                                <Fragment key={index}>
                                                    <Divider />
                                                    <ListItem alignItems="center">
                                                        <ListItemAvatar>
                                                            <Avatar />
                                                        </ListItemAvatar>
                                                        <li>
                                                            <Grid item>
                                                                <Grid>
                                                                    <NotificationLabel text={notificationTitle} />
                                                                </Grid>
                                                                <Grid>
                                                                    <span>{displayDate}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </li>
                                                    </ListItem>
                                                </Fragment>
                                            );
                                        })}
                                    </div>
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

NavigationNotifications.propTypes = {};

export default NavigationNotifications;
