import * as React from "react";

import Stack from "@mui/material/Stack";
import MUICard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { styled } from "@mui/material/styles";

const Card = styled(MUICard)(({ theme, isActive }) => ({
    position: "relative",
    minWidth: 275,
    cursor: "pointer",
    color: "#000",
    border: `2px solid ${isActive ? "#229440" : "#979797"}`,
    borderRadius: 4,
    marginBottom: 10,
    textDecoration: "none",
    backgroundColor: "#fff",
    [theme.breakpoints.down(950)]: {
        border: `2px solid #979797`,
        backgroundColor: "white",
    },
    "&:hover": {
        cursor: "pointer",
        boxShadow: "0 0 6px 0 #979797",
    },
}));

const CheckIcon = styled(CheckCircleOutlineOutlinedIcon)(() => ({
    top: 3,
    color: "#229440",
    right: 3,
    width: 17,
    height: 17,
    position: "absolute",
    borderRadius: 20,
    backgroundColor: "#C9F0E5",
}));

const CardStyled = styled(Card)(() => ({
    minWidth: 275,
    cursor: "pointer",
}));

export const SiteSkeleton = () => {
    return (
        <CardStyled>
            <CardContent>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Skeleton variant="rectangular" width={30} height={30} />
                    <Skeleton variant="text" width="30%" height={20} />
                </Stack>
            </CardContent>
        </CardStyled>
    );
};

export default function SiteCard({ name, isActive, setActiveSite }) {
    return (
        <CardStyled onClick={setActiveSite} isActive={isActive}>
            <Box sx={{ p: 2 }}>
                <Stack direction="row" alignItems="center">
                    {isActive && <CheckIcon />}
                    <LocationOnIcon sx={{ mr: 2 }} />
                    <Typography sx variant="h3" component="div">
                        {name}
                    </Typography>
                </Stack>
            </Box>
        </CardStyled>
    );
}
