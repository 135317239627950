import React, { useRef } from "react";
import { useSelector } from "react-redux";

import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import RequestsList from "./RequestsList";
import RequestDetails from "./RequestDetails";
import EmptySubNav from "../../components/Navigation/EmptySubNav/EmptySubNav";

const Requests = (props) => {
    return (
        <>
            <EmptySubNav />
            <Container maxWidth="xl">
                <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                    <RequestsList />
                    <RequestDetails />
                </Stack>
            </Container>
        </>
    );
};

export default Requests;
