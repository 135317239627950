import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";

import { ROUTE_MY_COMPANY, ROUTE_PROJECTS_AND_SITES, ROUTE_REQUESTS } from "../../../routes/routesStrings";
import { selectIsAdmin } from "../../../slices/userSlice";
import { selectUnapprovedUsersCount } from "../../../slices/myCompanySlice";

const StyledLink = styled(Link)(({ theme, active }) => {
    return {
        padding: "6px 15px",
        margin: "0 10px",
        color: "#505050",
        borderBottom: `4px solid ${active ? theme.palette.primary.main : "transparent"}`,
        display: "block",
        fontSize: 18,
        "&:hover": {
            background: "transparent",
        },
        textDecoration: "none",
    };
});

const NavigationLinks = () => {
    const isAdmin = useSelector(selectIsAdmin);
    const unapprovedUsersCount = useSelector(selectUnapprovedUsersCount);

    const { pathname } = useLocation();

    const isActive = (path) => pathname.includes(path);

    return (
        <>
            <StyledLink to={ROUTE_REQUESTS} active={isActive(ROUTE_REQUESTS)}>
                Requests
            </StyledLink>
            <StyledLink to={ROUTE_PROJECTS_AND_SITES.SITES} active={isActive(ROUTE_PROJECTS_AND_SITES.ROOT)}>
                Sites
            </StyledLink>
            <StyledLink to={ROUTE_MY_COMPANY.MY_TEAM} active={isActive(ROUTE_MY_COMPANY.ROOT)}>
                My Company
                {isAdmin && !!unapprovedUsersCount && (
                    <IconButton sx={{ width: 35 }}>
                        <Tooltip title={"You have unapproved users"}>
                            <Badge color="error" badgeContent={unapprovedUsersCount} />
                        </Tooltip>
                    </IconButton>
                )}
            </StyledLink>
        </>
    );
};

export default NavigationLinks;
