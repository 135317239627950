import React, { useState } from "react";
import PropTypes from "prop-types";

import AppModalWithoutConfirmation from "./AppModalWithoutConfirmation";
import ConfirmationModalWrap from "./ConfirmationModalWrap";

const AppModal = (props) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const { children, closeModal, showCloseButton, isOpen: open, modalStyles, form, customDirtyProperty } = props;
    const closeLocalModal = () => {
        setShowConfirmModal(true);
    };
    const closeChildModal = () => {
        closeModal();
        setShowConfirmModal(false);
    };

    return (
        <ConfirmationModalWrap
            form={form}
            customDirtyProperty={customDirtyProperty}
            closeModal={() => setShowConfirmModal(false)}
            closeChildModal={closeChildModal}
            closeChildModalRequest={showConfirmModal}
        >
            <AppModalWithoutConfirmation
                modalStyles={modalStyles}
                closeModal={closeLocalModal}
                isOpen={open}
                children={children}
                showCloseButton={showCloseButton}
            />
        </ConfirmationModalWrap>
    );
};

AppModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    showCloseButton: PropTypes.bool.isRequired,
    children: PropTypes.any,
    modalStyles: PropTypes.object.isRequired,
    closeModal: PropTypes.func,
    form: PropTypes.string,
    customDirtyProperty: PropTypes.bool,
};

AppModal.defaultProps = {
    isOpen: false,
    modalStyles: {},
    showCloseButton: true,
};

export default AppModal;
