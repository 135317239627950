import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Fade, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import clsx from "clsx";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        overflowY: "scroll",
    },
    modalContent: {
        backgroundColor: theme.palette.background.paper,
        padding: "16px 16px 26px",
        outline: 0,
        borderRadius: theme.shape.borderRadius * 2,
        minWidth: 332,
        textAlign: "left",

        "& .title": {
            textTransform: "uppercase",
            fontSize: 21,
            marginBottom: 20,
            textAlign: "center",
        },
    },
    closeIcon: {
        cursor: "pointer",
    },
}));

const AppModalWithoutConfirmation = (props) => {
    const classes = useStyles();
    const { children, closeModal, showCloseButton, isOpen: open, modalStyles } = props;

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disableRestoreFocus={true}
            className={classes.modalWrapper}
            open={open}
            onClose={closeModal}
            closeAfterTransition
        >
            <Fade in={open}>
                <Box className={classes.modalContent} style={modalStyles}>
                    {showCloseButton && (
                        <Box component="div" display="flex" justifyContent={"flex-end"} mb={2}>
                            <Close onClick={closeModal} className={clsx(classes.closeIcon)} />
                        </Box>
                    )}
                    {children}
                </Box>
            </Fade>
        </Modal>
    );
};

AppModalWithoutConfirmation.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    showCloseButton: PropTypes.bool.isRequired,
    children: PropTypes.any,
    modalStyles: PropTypes.object.isRequired,
    closeModal: PropTypes.func,
    form: PropTypes.string,
};

AppModalWithoutConfirmation.defaultProps = {
    isOpen: false,
    modalStyles: {},
    showCloseButton: true,
};

export default AppModalWithoutConfirmation;
