import * as React from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import moment from "moment";
import { DEFAULT_DATE_TIME_FORMAT } from "../../constants/global";

export default function DateTimePicker({ label, input: { onChange, ...inputProps } }) {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDateTimePicker
                {...inputProps}
                renderInput={(props) => <TextField {...props} />}
                label={label}
                mask={DEFAULT_DATE_TIME_FORMAT}
                onChange={(value) => {
                    onChange(moment(value).format(DEFAULT_DATE_TIME_FORMAT));
                }}
            />
        </LocalizationProvider>
    );
}
