import { SubmissionError } from "redux-form";

const handleFormErrors = (e) => {
    const errors = {};
    if (typeof e === "string") throw new SubmissionError({ _error: e });
    if (typeof e.message === "string") throw new SubmissionError({ _error: e.message });
    Object.keys(e.message).forEach((error) => {
        errors[error] = e.message[error];
    });
    throw new SubmissionError(errors);
};

export default handleFormErrors;
