import { useEffect, useRef, useState } from "react";

export const useShowJumpToTopButton = () => {
    const lastScrollTop = useRef(0);
    const [showButton, setShowButton] = useState(false);

    const handleScroll = () => {
        const st = window.pageYOffset || document.documentElement.scrollTop;

        if (st > lastScrollTop.current || document.documentElement.scrollTop === 0) {
            setShowButton(false);
        } else {
            setShowButton(true);
        }

        lastScrollTop.current = st <= 0 ? 0 : st;
    };

    useEffect(() => {
        if (document.documentElement.scrollTop > 200) {
            setShowButton(true);
        }

        document.addEventListener("scroll", handleScroll, false);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return showButton;
};
