import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

import { Apartment } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Grid, Icon } from "@mui/material";

import { ROUTE_MY_COMPANY } from "../../../routes/routesStrings";
import SubNavigation, { subNavigationStyles } from "../../../components/core/SubNavigation";
import { GLOBAL_CONTENT_WIDTH } from "../../../styles/globalThemeConfig";
import { THEME_ICONS } from "../../../styles/icons";
import { IS_ADMIN_USER } from "../../../constants/global";
import { selectAccountRole } from "../../../slices/userSlice";

const activeClass = "active";

const useStyles = makeStyles(() => ({
    uiIconTeam: {
        ...THEME_ICONS.account,
        marginRight: 20,
    },
    subNavIcon: {
        marginRight: 20,
    },
}));

const MyCompanySubNav = (props) => {
    const role = useSelector(selectAccountRole);

    const classes = useStyles();
    const subNavigationClasses = subNavigationStyles();
    const { pathname } = useLocation();

    const isAdmin = IS_ADMIN_USER(role);

    return (
        <SubNavigation subMenuWidth={GLOBAL_CONTENT_WIDTH}>
            <Grid
                className={subNavigationClasses.trucksSubMenuResponsive}
                container
                component="div"
                alignItems={"center"}
            >
                <Grid item component="div">
                    <div
                        className={clsx(
                            subNavigationClasses.subMenuItems,
                            pathname.includes(ROUTE_MY_COMPANY.MY_TEAM) && activeClass,
                        )}
                    >
                        <Icon className={classes.uiIconTeam} />
                        <Link to={ROUTE_MY_COMPANY.MY_TEAM}>My Team</Link>
                    </div>
                </Grid>
                {isAdmin && (
                    <Grid item component="div">
                        <div
                            className={clsx(
                                subNavigationClasses.subMenuItems,
                                pathname.includes(ROUTE_MY_COMPANY.ALL_COMPANIES) && activeClass,
                            )}
                        >
                            <Apartment className={clsx(classes.subNavIcon)} />
                            <Link to={ROUTE_MY_COMPANY.ALL_COMPANIES}>Companies</Link>
                        </div>
                    </Grid>
                )}
            </Grid>
        </SubNavigation>
    );
};

export default MyCompanySubNav;
