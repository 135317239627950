import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const SuccessMessage = ({ onClose, message }) => {
    return (
        <Snackbar
            open={!!message}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={onClose}
        >
            <Alert variant="filled" severity="success">
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SuccessMessage;
