import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Container, Link, Stack, Typography } from "@mui/material";
import { Logo } from "../../assets";
import SignInForm from "./SignInForm";
import { ROUTE_SIGNUP } from "../../routes/routesStrings";

const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));

export default function Login() {
    return (
        <Box title="Login">
            <Container maxWidth="sm">
                <ContentStyle>
                    <Stack direction="row" justifyContent="center" sx={{ mb: 10 }}>
                        <Logo />
                    </Stack>
                    <SignInForm />
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="caption" align="left" sx={{ mt: 1 }}>
                            Don’t have an account?{" "}
                            <Link
                                style={{ textDecoration: "none", fontWeight: "100", color: "rgb(0 140 255)" }}
                                variant="caption"
                                component={RouterLink}
                                to={ROUTE_SIGNUP}
                            >
                                Sign Up
                            </Link>
                        </Typography>
                        {/* <Typography align="right" sx={{ mt: 1 }}>
                            <Link
                                style={{ textDecoration: "none", color: "rgb(0 140 255)" }}
                                variant="caption"
                                component={RouterLink}
                                to={ROUTE_SIGNUP}
                            >
                                Login via SSO
                            </Link>
                        </Typography> */}
                    </Stack>
                </ContentStyle>
            </Container>
        </Box>
    );
}
