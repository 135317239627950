import * as projects from "./projects";
import * as requests from "./requests";
import * as user from "./user";
import * as sites from "./sites";
import * as myCompany from "./myCompany";
import * as companies from "./companies";
import * as notifications from "./notifications";

const actionCreators = {
    ...projects,
    ...requests,
    ...user,
    ...sites,
    ...myCompany,
    ...companies,
    ...notifications,
};

export default actionCreators;
