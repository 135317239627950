import React from "react";
import { Waypoint } from "react-waypoint";

const WindowScrollableListWaypoint = ({ fetchMore, index, items }) => {
    return (
        items &&
        items.length - 2 === index && <Waypoint onEnter={() => fetchMore && fetchMore()} scrollableAncestor={window} />
    );
};

export default WindowScrollableListWaypoint;
