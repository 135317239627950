import React, { useState } from "react";
import { change, Field } from "redux-form";

import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import FormDialog from "../../../components/core/FormDialog";
import { Validation } from "../../../utils/validation";
import clsx from "clsx";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
    CREATE_NEW_OPTION_ID,
} from "../../../components/core/form/AsyncAutocompleteComponent";
import { useActions } from "../../../hooks";
import { CREATE_SITE_FORM } from "../../../constants/forms";
import CreateSiteForm from "../../../components/forms/CreateSiteForm/CreateSiteForm";
import { ICONS_SITE } from "../../../styles/icons";
import { useDispatch } from "react-redux";
import { getSiteValue } from "../../../helpers/sites";

export const DROP_OF_SITE_NAME = "dropOffSites";

const DropOffSelector = ({ dropOffSites, maxMenuHeight, form }) => {
    const isWebMode = useMediaQuery("(min-width:600px)");
    const dispatch = useDispatch();
    const siteIconsClasses = ICONS_SITE();
    const [showCreateSiteModal, setShowCreateSiteModal] = useState(false);
    const { loadDropOffSites, updateRequestSuccessMessage } = useActions();

    const createSiteHandler = (data, fieldName) => {
        dispatch(change(form, fieldName, [...(dropOffSites || []), getSiteValue(data)]));
        updateRequestSuccessMessage("Site was created");
    };

    return (
        <Stack direction="row" alignItems="center" spacing={0}>
            <FormDialog
                isOpen={showCreateSiteModal}
                form={CREATE_SITE_FORM}
                fullWidth
                maxWidth={"md"}
                closeModal={() => setShowCreateSiteModal(false)}
            >
                {() => (
                    <CreateSiteForm
                        closeModal={() => setShowCreateSiteModal(false)}
                        createSiteHandler={createSiteHandler}
                        fieldName={DROP_OF_SITE_NAME}
                        createForDropdown
                    />
                )}
            </FormDialog>
            <Field
                name={DROP_OF_SITE_NAME}
                validate={[Validation.required]}
                loadOptions={loadDropOffSites}
                startAdornment={<LocationOnIcon className={clsx(siteIconsClasses.iconSite, "drop-off")} />}
                placeholder="Drop-Off Site(s)"
                className={clsx(AUTOCOMPLETE_FORM_VIEW_CLASS, "drop-off")}
                isMulti={true}
                component={AsyncAutocompleteComponent}
                createNewOption={
                    isWebMode && {
                        label: "Add Site",
                        value: CREATE_NEW_OPTION_ID,
                    }
                }
                createNewHandler={() => setShowCreateSiteModal(true)}
                maxMenuHeight={maxMenuHeight}
            />
            {!isWebMode && (
                <Tooltip title="Add Site">
                    <IconButton onClick={() => setShowCreateSiteModal(true)}>
                        <AddCircleOutlineIcon fontSize="large" color="primary" />
                    </IconButton>
                </Tooltip>
            )}
        </Stack>
    );
};

export default DropOffSelector;
