// @flow
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { selectProjects, selectProjectsMeta, selectCurrentProject } from "../../../slices/projectsSlice.js";
import ScrolledList from "../../../components/core/ScrolledList";
import ProjectCard, { ProjectSkeleton } from "./ProjectCard";
import Waypoint from "../../../components/core/Waypoint";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global.js";
import Loader from "../../../components/core/loader";
import { useActions } from "../../../hooks";
import Filters from "../Filters/Filters";

function ProjectsList(props) {
    const projects = useSelector(selectProjects);
    const { loading, error } = useSelector(selectProjectsMeta);
    const currentProject = useSelector(selectCurrentProject);

    const { getProjectsList, refreshProjectsList, getCurrentProject, resetProjects, refreshCurrentProjectDetails } =
        useActions();

    useEffect(() => {
        getProjectsList();
    }, []);

    const setActiveProject = (id) => {
        getCurrentProject(id);
    };

    const requety = () => {
        refreshCurrentProjectDetails();
        refreshProjectsList();
    };

    return (
        <Box component="main" sx={{ flex: 1, p: 2 }} className={loading && LOADER_WHITE_OVERLAY}>
            {loading && <Loader />}
            <Filters form="projects-filters" request={requety} />
            <Divider sx={{ my: 2 }} />
            <ScrolledList>
                {loading && !(projects && projects.length)
                    ? Array.from(new Array(9)).map(() => <ProjectSkeleton />)
                    : projects &&
                      projects.map((project, index) => {
                          const isActive = project.id === currentProject?.id;
                          return (
                              <>
                                  <ProjectCard
                                      key={project.id}
                                      isActive={isActive}
                                      name={project.name}
                                      setActiveProject={() => setActiveProject(project.id)}
                                  />
                                  <Waypoint fetchMore={getProjectsList} index={index} items={projects} />
                              </>
                          );
                      })}
            </ScrolledList>
            <Snackbar
                open={!!error}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={resetProjects}
            >
                <Alert elevation={6} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {error}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default ProjectsList;
