import { SITE_ENDPOINTS } from "./endpoints";
import dataProvider from "./httpRequest";

export const fetchSites = async (params) => {
    return await dataProvider({
        url: SITE_ENDPOINTS.SITES(),
        method: "GET",
        params: {
            ...params,
            relZoneInf: true,
            order: "-id",
            perPage: 20,
        },
    });
};

export const fetchDropOffSites = async (params) => {
    return await dataProvider({
        url: SITE_ENDPOINTS.SITES(),
        method: "GET",
        params: {
            ...params,
            accessType: 2,
            perPage: 20,
        },
    });
};

export const fetchSiteDetails = async (siteId) => {
    return await dataProvider({
        url: SITE_ENDPOINTS.SITE_DETAILS(siteId),
        method: "GET",
    });
};

export const fetchSiteAlertZones = async (siteId) => {
    return await dataProvider({
        url: SITE_ENDPOINTS.SITE_ALERT_ZONES(siteId),
        method: "GET",
    });
};

export const createSite = (data) => {
    return dataProvider({
        url: SITE_ENDPOINTS.SITES(),
        method: "POST",
        data,
    });
};
