// @flow
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import useMediaQuery from '@mui/material/useMediaQuery';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import FormDialog from "../../../components/core/FormDialog";
import { selectSites, selectSitesMeta, selectCurrentSite } from "../../../slices/sitesSlice.js";
import ScrolledList from "../../../components/core/ScrolledList";
import SiteCard, { SiteSkeleton } from "./SiteCard";
import Waypoint from "../../../components/core/Waypoint";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global.js";
import Loader from "../../../components/core/loader";
import { useActions } from "../../../hooks";
import { Filters } from "../../../components";
import AppModal from "../../../components/core/modals/AppModal";
import { CreateSiteForm } from "../../../components/forms";
import { CREATE_SITE_FORM } from "../../../constants/forms";
import { SITES_FILTERS, DEFAULT_SITES_FILTERS } from "../constants";
import { SITES_FILTERS_FORM } from "../../../constants/forms";
import { selectIfIsWebDimensions } from "../../../slices/utilsSlice.js";

function SitesList(props) {
    const [showCreateSiteModal, setShowCreateSiteModal] = useState(false);
    const isWebMode = useMediaQuery("(min-width:950px)")
    const navigate = useNavigate();
    const sites = useSelector(selectSites);
    const { loading, error } = useSelector(selectSitesMeta);
    const currentSite = useSelector(selectCurrentSite);

    const { getSitesList, refreshSitesList, getSiteDetails, refreshCurrentSiteDetails, initializeSites } = useActions();

    useEffect(() => {
        getSitesList();

        return () => {
            initializeSites();
        };
    }, []);

    const setActiveSite = (id) => {
        getSiteDetails(id);
        if (isWebMode) {
            getSiteDetails(id);
        } else {
            navigate(`/sites/${id}`);
        }
    };

    const request = () => {
        refreshCurrentSiteDetails();
        refreshSitesList();
    };

    return (
        <Box component="main" sx={{ flex: 1, p: 2 }} className={loading && LOADER_WHITE_OVERLAY}>
            {loading && <Loader />}
            <Filters form={SITES_FILTERS_FORM} request={request} initialValues={{ filters: DEFAULT_SITES_FILTERS }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
                    <Stack sx={{ flex: 1 }}>
                        <Filters.SearchField />
                    </Stack>
                    <Filters.FilterSelector filters={SITES_FILTERS} />
                    <Filters.RefreshButton />
                </Stack>
            </Filters>
            <Divider sx={{ mt: 2 }} />
            <FormDialog
                form={CREATE_SITE_FORM}
                fullWidth
                maxWidth={"md"}
                OpenModalComponent={(props) => (
                    <Button variant="text" sx={{ my: 1 }} startIcon={<AddCircleOutlineIcon />} {...props}>
                        CREATE NEW SITE
                    </Button>
                )}
            >
                {(props) => (
                    <CreateSiteForm
                        createForDropdown
                        createSiteHandler={request}
                        onSubmitSuccess={request}
                        {...props}
                    />
                )}
            </FormDialog>
            <ScrolledList>
                {loading && !(sites && sites.length)
                    ? Array.from(new Array(9)).map(() => <SiteSkeleton />)
                    : sites &&
                      sites.map((site, index) => {
                          const isActive = isWebMode && site.id === currentSite?.id;
                          return (
                              <>
                                  <SiteCard
                                      key={site.id}
                                      isActive={isActive}
                                      name={site.name}
                                      setActiveSite={() => setActiveSite(site.id)}
                                  />
                                  <Waypoint fetchMore={getSitesList} index={index} items={sites} />
                              </>
                          );
                      })}
            </ScrolledList>
            <Snackbar
                open={!!error}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={refreshSitesList}
            >
                <Alert elevation={6} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {error}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default SitesList;
