import React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const List = styled(Box)(({ theme, isEmpty }) => ({
    height: "calc(100vh - 2 * 120px)",
    overflowY: "scroll",
    paddingRight: "13px",
}));

export default function ScrolledList({ children, withButton }) {
    return <List>{children}</List>;
}
