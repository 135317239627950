import React from "react";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";

import { Stack, Typography } from "@mui/material";

import { APPROVE_SIGN_UP_FROM } from "../../../constants/forms";
import { withHandleFormError } from "../../../hoc";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
} from "../../../components/core/form/AsyncAutocompleteComponent";
import { Validation } from "../../../utils/validation";
import { useActions } from "../../../hooks";
import SecondaryButton from "../../../components/core/buttons/SecondaryButton";
import PrimaryButton from "../../../components/core/buttons/PrimaryButton";
import { ButtonsGroup } from "../../../components/core/buttons/ButtonsGroup/buttonsGroup";
import { approveUserSignUp } from "../../../actionCreators/myCompany";

const ApproveSignUpFormWithCompanyUpdating = ({ handleSubmit, closeModal, registrationCompanyName, userId }) => {
    const { getCompaniesOptions, approveUserSignUp } = useActions();
    const onSubmit = async (values) => {
        await approveUserSignUp(userId, { company: values.company.value });
        closeModal();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
                <Typography align="center" variant="h1">
                    Enter user company
                </Typography>
                <Typography align="center" variant="h2">
                    User inputted value during registration: {registrationCompanyName}
                </Typography>
                <Field
                    name="company"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    validate={[Validation.required]}
                    loadOptions={getCompaniesOptions}
                    label="Company"
                    component={AsyncAutocompleteComponent}
                />
                <ButtonsGroup>
                    <SecondaryButton onClick={closeModal}>CANCEL</SecondaryButton>
                    <PrimaryButton type="submit">APPROVE</PrimaryButton>
                </ButtonsGroup>
            </Stack>
        </form>
    );
};

export default compose(
    reduxForm({
        form: APPROVE_SIGN_UP_FROM,
    }),
    withHandleFormError,
)(ApproveSignUpFormWithCompanyUpdating);
