import { createSlice } from "@reduxjs/toolkit";
import { resetState } from "./actions";
import { IS_ADMIN_USER } from "../constants/global";

const initialState = {
    data: JSON.parse(localStorage.getItem("accountData")),
    meta: {
        loading: false,
        error: null,
    },
    globalErrorMessage: null,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            state.data = payload;
        },
        removeUser: (state) => {
            state.data = null;
        },
        updateGlobalErrorMessage: (state, { payload }) => {
            state.globalErrorMessage = payload;
        },
    },
    extraReducers: (builder) => {
        return builder.addCase(resetState, (state) => {
            state.data = null;
            state.meta = initialState.meta;
        });
    },
});

const actions = userSlice.actions;

export { actions };

export const selectAccount = (state) => state.user.data;
export const selectAccountTimezone = (state) => selectAccount(state).timezone;
export const selectAccountRole = (state) => selectAccount(state).role;
export const selectIsAdmin = (state) => IS_ADMIN_USER(selectAccountRole(state));
export const selectCompany = (state) => selectAccount(state).company;
export const selectCompanyId = (state) => selectCompany(state).id;

export const selectGlobalErrorMessage = (state) => state.user.globalErrorMessage;

export default userSlice.reducer;
