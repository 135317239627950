import React from "react";
import clsx from "clsx";

import { useShowJumpToTopButton } from "../../../hooks/jumpToTopButton/useShowJumpToTopButton";

import Navigation from "@mui/icons-material/Navigation";
import { Fab } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledFab = styled(Fab)(() => ({
    position: "fixed",
    bottom: "10%",
    right: "4%",
    opacity: 0,
    zIndex: 100,
    transition: "opacity 300ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",

    "&.-show": {
        opacity: 1,
        transition: "opacity 300ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    },
}));

const JumpToTopButton = () => {
    const showButton = useShowJumpToTopButton();

    const jumpToTop = () => {
        window.scrollTo(0, document.offsetTop);
    };

    return (
        <StyledFab onClick={jumpToTop} className={clsx(showButton && "-show")}>
            <Navigation />
        </StyledFab>
    );
};

JumpToTopButton.propTypes = {};

export default JumpToTopButton;
