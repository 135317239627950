/* global google */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Polyline from "@mapbox/polyline";
import { compose, withProps, lifecycle } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Polyline as PolylineComponent } from "react-google-maps";
import {
    GOOGLE_MAPS_URL,
    GOOGLE_MAPS_TRUCK_ICON_SIZE,
    ENV_MODE_PROD,
    FREQUENCY_UPDATE_PROD,
    FREQUENCY_UPDATE_TEST_DEMO,
    getTrucksForMap,
    getRandomColor,
} from "../../constants";

import Divider from "@mui/material/Divider";

import { LOADER_WHITE_OVERLAY, UNIT_OF_MEASURE_BY_ID, UNIT_OF_MEASURE_HOUR } from "../../constants/global";
import CustomMarker from "../core/CustomMarker";
import { getJobOrderMapData, getJobOrderRoutes } from "../../api/requests";
import clsx from "clsx";
import DropOffMapData from "../core/DropOffMapData";
import PickUpMapData from "../core/PickUpMapData";

export const getHoursQty = (quantity) =>
    `${Math.trunc(quantity)}h ${((quantity - Math.trunc(quantity)) * 60).toFixed(0)}m`;

export const generateColors = (tickets) => {
    const newSet = [];
    const defaultSet = [
        "#F44336",
        "#E91E63",
        "#9C27B0",
        "#673AB7",
        "#3F51B5",
        "#2196F3",
        "#03A9F4",
        "#00BCD4",
        "#607D8B",
        "#009688",
        "#4CAF50",
        "#8BC34A",
        "#CDDC39",
        "#FFEB3B",
        "#FFC107",
        "#FF9800",
        "#FF5722",
        "#795548",
        "#9E9E9E",
    ];

    tickets.forEach((ticket, index) => {
        newSet.push(defaultSet[index] || getRandomColor());
    });

    return newSet;
};

const getGeofenceCoord = (item, callback) => {
    item &&
        item.length &&
        item.forEach(({ polygon }) => {
            polygon.forEach((c) => callback(c[0], c[1]));
        });
};

const JobOrderMapComponent = compose(
    withProps({
        googleMapURL: GOOGLE_MAPS_URL,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
        componentDidMount() {
            const {
                jobOrder,
                mapData: {
                    pickUpSite: { latitude, longitude, geofenceData = [] },
                    dropOffSites,
                    tickets,
                    trucks,
                },
                setParentLoader,
            } = this.props;

            setParentLoader(true);

            this.setState({
                polyList: [],
                bounds: (map) => {
                    const bounds = new google.maps.LatLngBounds();

                    latitude && longitude && bounds.extend(new google.maps.LatLng(latitude, longitude));

                    [...dropOffSites, ...tickets, ...trucks].forEach((item, i) => {
                        let lat;
                        let lng;

                        if (geofenceData && geofenceData.length) {
                            getGeofenceCoord(geofenceData, (lat, lan) =>
                                bounds.extend(new google.maps.LatLng(lat, lan)),
                            );
                        }

                        if (dropOffSites && dropOffSites.length) {
                            dropOffSites.forEach((item) => {
                                getGeofenceCoord(item.geofenceData, (lat, lan) =>
                                    bounds.extend(new google.maps.LatLng(lat, lan)),
                                );
                            });
                        }

                        // for dropOffSites
                        if (item.latitude && item.longitude) {
                            lat = item.latitude;
                            lng = item.longitude;
                        }
                        // for trucks
                        if (item.currentLocation) {
                            lat = item.currentLocation.latitude;
                            lng = item.currentLocation.longitude;
                        }

                        lat && lng && bounds.extend(new google.maps.LatLng(lat, lng));

                        // for tickets
                        if (item.closeLocation) {
                            lat = item.closeLocation.latitude;
                            lng = item.closeLocation.longitude;

                            lat && lng && bounds.extend(new google.maps.LatLng(lat, lng));
                        }

                        if (item.openLocation) {
                            lat = item.openLocation.latitude;
                            lng = item.openLocation.longitude;

                            lat && lng && bounds.extend(new google.maps.LatLng(lat, lng));
                        }

                        if (!_.isEmpty(item.loadsLocations)) {
                            item.loadsLocations.forEach((location) => {
                                if (location.pickUpLocation) {
                                    lat = location.pickUpLocation.latitude;
                                    lng = location.pickUpLocation.longitude;

                                    lat && lng && bounds.extend(new google.maps.LatLng(lat, lng));
                                }

                                if (location.dropOffLocation) {
                                    lat = location.dropOffLocation.latitude;
                                    lng = location.dropOffLocation.longitude;

                                    lat && lng && bounds.extend(new google.maps.LatLng(lat, lng));
                                }
                            });
                        }
                        // for PAVERTRACKERS
                        if (item.coordinates) {
                            lat = item.coordinates.latitude;
                            lng = item.coordinates.longitude;
                            const geoZone = item.geoZone;

                            lat && lng && bounds.extend(new google.maps.LatLng(lat, lng));

                            if (geoZone) {
                                geoZone.forEach(([lat, lng]) => {
                                    lat && lng && bounds.extend(new google.maps.LatLng(lat, lng));
                                });
                            }
                        }
                    });

                    map && map.fitBounds(bounds);
                },
            });

            getJobOrderRoutes(jobOrder.id)
                .then(({ data: response }) => {
                    const polyList = [];

                    response.googleRoutes.forEach((data, index) => {
                        data.routes.forEach((route, routeIndex) => {
                            const polyline = _.get(
                                response,
                                `googleRoutes[${index}].routes[${routeIndex}].overview_polyline.points`,
                            );
                            if (!polyline) return [];

                            const poly = Polyline.decode(polyline).map((point) => ({
                                lat: point[0],
                                lng: point[1],
                            }));

                            polyList.push({ path: poly, id: data.id });
                        });
                    });
                    setParentLoader(false);
                    this.setState({
                        polyList: polyList,
                    });
                })
                .catch((error) => {
                    setParentLoader(false);
                    this.setState({
                        error,
                    });
                });
        },
    }),
)((props) => {
    const {
        mapData: {
            trucks,
            tickets,
            pickUpSite,
            dropOffSites,
            //  [PAVERTRACKERS_NAME]: devices
        },
        polyList,
        bounds,
        parentLoading,
        coordinatesForTruckId,
    } = props;
    const [state, setState] = useState({ notFadeMarkers: [], colors: [], activeRoute: null, defaultCenter: [] });
    const { notFadeMarkers, colors, activeRoute } = state;
    const [defaultCenter, setCenter] = useState();
    const pickUpdLocation = { lat: pickUpSite.latitude, lng: pickUpSite.longitude };
    const fadeOthers = (ids) => {
        setState({ ...state, notFadeMarkers: ids });
    };
    const dropOffWithCordinates = dropOffSites.find((i) => i.latitude && i.longitude);
    const firstDropOffLocation = dropOffWithCordinates && {
        lat: dropOffWithCordinates.latitude,
        lng: dropOffWithCordinates.longitude,
    };
    const closedTicketGeofence = tickets.find((i) => i.closeLocation);
    const firstClosedTicketTicketLocation = closedTicketGeofence && {
        lat: closedTicketGeofence.closeLocation.latitude,
        lng: closedTicketGeofence.closeLocation.longitude,
    };
    const openTicketGeofence = tickets.find((i) => i.openLocation);
    const firstOpenTicketTicketLocation = openTicketGeofence && {
        lat: openTicketGeofence.openLocation.latitude,
        lng: openTicketGeofence.openLocation.longitude,
    };
    const firstTruckWithLocation = trucks.find((i) => i.currentLocation);
    const firstTruckLocation = firstTruckWithLocation && {
        lat: firstTruckWithLocation.currentLocation.latitude,
        lng: firstTruckWithLocation.currentLocation.longitude,
    };
    const changeRoutesStyle = (id, open) => {
        setState({ ...state, activeRoute: open ? id : null });
    };
    const globalCoordinates =
        pickUpdLocation ||
        firstDropOffLocation ||
        firstOpenTicketTicketLocation ||
        firstClosedTicketTicketLocation ||
        firstTruckLocation;

    useEffect(() => {
        setState({
            ...state,
            colors: generateColors(tickets),
        });
        setCenter(globalCoordinates);
    }, []);

    useEffect(() => {
        if (coordinatesForTruckId) {
            const truckCoords = trucks.find((i) => i.currentLocation && i.id === coordinatesForTruckId);

            if (!truckCoords) {
                return false;
            }

            setCenter({
                lat: truckCoords.currentLocation.latitude,
                lng: truckCoords.currentLocation.longitude,
            });
        } else {
            setCenter(globalCoordinates);
        }
    }, [coordinatesForTruckId]);

    const actualTickets = tickets.filter(
        (i) =>
            i.closeLocation ||
            i.openLocation ||
            (!_.isEmpty(i.loadsLocations) &&
                i.loadsLocations.find((l) => !_.isEmpty(l.pickUpLocation) || !_.isEmpty(l.dropOffLocation))),
    );

    return defaultCenter && !parentLoading ? (
        <GoogleMap defaultZoom={14} center={defaultCenter} ref={bounds}>
            {!_.isEmpty(polyList) &&
                polyList.map((poly, index) => {
                    return (
                        <PolylineComponent
                            path={poly.path}
                            key={index}
                            geodesic={true}
                            onMouseOver={() => changeRoutesStyle(poly.id, true)}
                            onMouseOut={() => changeRoutesStyle(poly.id, false)}
                            options={{
                                strokeColor: "#73B9FF",
                                strokeOpacity: activeRoute ? (activeRoute === poly.id ? 0.7 : 0.1) : 0.7,
                                strokeWeight: 5,
                            }}
                        />
                    );
                })}
            {actualTickets.map((ticket, index) => {
                const {
                    closeLocation,
                    quantity,
                    driverName,
                    openLocation,
                    truckName,
                    externalRef,
                    unitOfMeasure,
                    loadsLocations,
                    id,
                } = ticket;
                const infoText = (loadId) => (
                    <div>
                        <div>Truck: {truckName} </div>
                        {driverName ? <div>Driver:{` ${driverName}`}</div> : ""}
                        <div>{`Ticket #: ${+externalRef === 0 ? "No ticket number" : externalRef}`}</div>
                        <div>{`Status: ${loadId ? "Loaded" : "Unloaded"}`}</div>
                        {/* @todo remove unitOfMeasureId as soon as you will find it */}
                        {loadId ? (
                            <div>{`Load Number: ${loadId}`}</div>
                        ) : quantity ? (
                            unitOfMeasure === UNIT_OF_MEASURE_HOUR ? (
                                <div>{`Quantity: ${getHoursQty(quantity)}`}</div>
                            ) : (
                                <div>
                                    {`Quantity: ${(+quantity).toFixed(2)} ${UNIT_OF_MEASURE_BY_ID[unitOfMeasure]}s`}
                                </div>
                            )
                        ) : (
                            ""
                        )}
                    </div>
                );
                const svg = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" height="80" width="80">
                    <circle cx="40" cy="40" r="40" fill="${colors[index]}"/>
                </svg>`;
                const url = "data:image/svg+xml;charset=UTF-8;base64," + btoa(svg);

                const icon = {
                    scaledSize: new google.maps.Size(15, 15),
                    url: url,
                };
                const opacity = (id) => {
                    return !_.isEmpty(notFadeMarkers) && !notFadeMarkers.includes(id) ? 0.1 : 1;
                };

                return (
                    <React.Fragment key={id}>
                        {closeLocation && (
                            <CustomMarker
                                position={{ lat: closeLocation.latitude, lng: closeLocation.longitude }}
                                opacity={opacity(id)}
                                id={id}
                                zIndex={2}
                                fadeOthers={fadeOthers}
                                icon={{ ...icon }}
                            >
                                <span>{infoText()}</span>
                            </CustomMarker>
                        )}
                        {openLocation && (
                            <CustomMarker
                                position={{ lat: openLocation.latitude, lng: openLocation.longitude }}
                                opacity={opacity(id)}
                                id={id}
                                zIndex={2}
                                icon={{ ...icon }}
                                fadeOthers={fadeOthers}
                            >
                                <span>{infoText()}</span>
                            </CustomMarker>
                        )}
                        {loadsLocations &&
                            loadsLocations.map((load) => {
                                const { loadId, pickUpLocation, dropOffLocation, id } = load;

                                return (
                                    <React.Fragment>
                                        {pickUpLocation && (
                                            <CustomMarker
                                                position={{
                                                    lat: pickUpLocation.latitude,
                                                    lng: pickUpLocation.longitude,
                                                }}
                                                opacity={opacity(id)}
                                                id={id}
                                                key={id}
                                                zIndex={2}
                                                icon={{ ...icon }}
                                                fadeOthers={fadeOthers}
                                            >
                                                <span>{infoText(loadId)}</span>
                                            </CustomMarker>
                                        )}
                                        {dropOffLocation && (
                                            <CustomMarker
                                                position={{
                                                    lat: dropOffLocation.latitude,
                                                    lng: dropOffLocation.longitude,
                                                }}
                                                opacity={opacity(id)}
                                                id={id}
                                                key={id}
                                                zIndex={2}
                                                icon={{ ...icon }}
                                                fadeOthers={fadeOthers}
                                            >
                                                <span>{infoText(loadId)}</span>
                                            </CustomMarker>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                    </React.Fragment>
                );
            })}
            {trucks
                .filter((i) => i.currentLocation)
                .map((truck, index) => {
                    const {
                        currentLocation,
                        name,
                        driverName,
                        currentTicketId,
                        currentJobOrderId,
                        heading,
                        status,
                        id,
                    } = truck;
                    const infoText = (
                        <div>
                            <div>
                                {name}
                                {driverName ? ` (${driverName})` : ""}
                            </div>
                            <div>
                                {currentTicketId
                                    ? `Current ticket - #${currentTicketId}`
                                    : currentTicketId === null
                                    ? "No open ticket"
                                    : +currentTicketId === 0 && "No ticket number"}
                            </div>
                            <div>Current Job Order - {currentJobOrderId ? `#${currentJobOrderId}` : "None"}</div>
                        </div>
                    );

                    return (
                        <CustomMarker
                            key={id}
                            position={{
                                lat: currentLocation.latitude,
                                lng: currentLocation.longitude,
                            }}
                            zIndex={1}
                            icon={{
                                // anchor: new google.maps.Point(0, 32),
                                scaledSize: new google.maps.Size(
                                    GOOGLE_MAPS_TRUCK_ICON_SIZE,
                                    GOOGLE_MAPS_TRUCK_ICON_SIZE,
                                ),
                                url: getTrucksForMap(status, heading),
                            }}
                        >
                            {infoText}
                        </CustomMarker>
                    );
                })}
            <PickUpMapData notFadeMarkers={notFadeMarkers} pickUpSite={pickUpSite} />
            <DropOffMapData activeRoute={activeRoute} dropOffSites={dropOffSites} notFadeMarkers={notFadeMarkers} />
        </GoogleMap>
    ) : (
        !parentLoading && <h2 className="--text-center">No Data to display map</h2>
    );
});

JobOrderMapComponent.propTypes = {
    mapData: PropTypes.object.isRequired,
    jobOrder: PropTypes.object.isRequired,
    setParentLoader: PropTypes.func.isRequired,
    coordinatesForTruckId: PropTypes.number,
};

const JobOrderMap = (props) => {
    const [state, setState] = useState({ isLoading: false, mapData: {}, showMap: true });
    const { jobOrder, coordinatesForTruckId } = props;
    const { isLoading, mapData, showMap } = state;
    const getMapData = (id) => {
        setState({ ...state, isLoading: true });
        getJobOrderMapData(id)
            .then((data) => {
                setState({ ...state, isLoading: false, mapData: data.data });
            })
            .catch(() => {
                setState({ ...state, isLoading: false });
            });
    };

    const setLoader = (show) => {
        setState({ ...state, isLoading: show });
    };

    // const classes = useStyles(mapData);

    useEffect(() => {
        jobOrder.id && getMapData(jobOrder.id);
    }, [jobOrder.id]);

    useEffect(() => {
        const time =
            process.env.REACT_APP_ENVIRONMENT === ENV_MODE_PROD ? FREQUENCY_UPDATE_PROD : FREQUENCY_UPDATE_TEST_DEMO;
        const interval = setInterval(() => {
            jobOrder.id && getMapData(jobOrder.id);
        }, time);

        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{ minHeight: 400 }} className={clsx((isLoading || _.isEmpty(mapData)) && LOADER_WHITE_OVERLAY)}>
            {showMap && !_.isEmpty(mapData) && (
                <React.Fragment>
                    <JobOrderMapComponent
                        coordinatesForTruckId={coordinatesForTruckId}
                        mapData={mapData}
                        jobOrder={jobOrder}
                        parentLoading={isLoading}
                        setParentLoader={setLoader}
                    />
                </React.Fragment>
            )}
            <Divider sx={{ my: 2 }} />
        </div>
    );
};

JobOrderMap.propTypes = {
    jobOrder: PropTypes.object.isRequired,
    coordinatesForTruckId: PropTypes.number,
};

JobOrderMap.defaultProps = {
    jobOrder: {},
};

export default JobOrderMap;
