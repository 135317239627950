import React, { Component } from "react";

export const fixedLoaderStyles = () => {
    let styles = {};

    if (document.documentElement.scrollTop > 50) {
        styles = { ...styles, position: "fixed" };
    }

    return styles;
};

class Loader extends Component {
    render() {
        const { style } = this.props;

        return (
            <div className="container-loader" style={style}>
                <div className="sk-circle">
                    <div className="sk-circle1 sk-child" />
                    <div className="sk-circle2 sk-child" />
                    <div className="sk-circle3 sk-child" />
                    <div className="sk-circle4 sk-child" />
                    <div className="sk-circle5 sk-child" />
                    <div className="sk-circle6 sk-child" />
                    <div className="sk-circle7 sk-child" />
                    <div className="sk-circle8 sk-child" />
                    <div className="sk-circle9 sk-child" />
                    <div className="sk-circle10 sk-child" />
                    <div className="sk-circle11 sk-child" />
                    <div className="sk-circle12 sk-child" />
                </div>
            </div>
        );
    }
}

export class HorizontalLoader extends Component {
    render() {
        return (
            <div className="loader-indicator">
                <div className="loading-dot" />
                <div className="loading-dot" />
                <div className="loading-dot" />
            </div>
        );
    }
}

export default Loader;
