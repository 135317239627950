import React from "react";
import { useSelector } from "react-redux";
import { hasSubmitSucceeded, isDirty } from "redux-form";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import ConfirmationModal from "../core/modals/ConfirmationModal";

export default function FormDialog({
    OpenModalComponent,
    dialogTitle,
    form,
    dialogTitleAlign,
    maxWidth,
    children,
    fullWidth,
    successLabel,
    isOpen,
    closeModal,
}) {
    const [open, setOpen] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = React.useState(false);

    const hasSubmitSuccess = useSelector(hasSubmitSucceeded(form));
    const dirty = useSelector(isDirty(form));

    React.useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    React.useEffect(() => {
        if (hasSubmitSuccess) {
            setOpen(false);
            closeModal && closeModal();
            successLabel && setSuccess(successLabel);
        }
    }, [hasSubmitSuccess]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (dirty) {
            setIsOpenConfirmModal(true);
        } else {
            setOpen(false);
            closeModal && closeModal();
        }
    };

    const handleCloseConfirmationModal = () => {
        setOpen(false);
        closeModal && closeModal();
        setIsOpenConfirmModal(false);
    };

    return (
        <>
            {OpenModalComponent && <OpenModalComponent onClick={handleClickOpen} />}
            <Dialog maxWidth={maxWidth || "md"} fullWidth={fullWidth} open={open} onClose={handleClose}>
                {dialogTitle && <DialogTitle sx={{ textAlign: dialogTitleAlign || "left" }}>{dialogTitle}</DialogTitle>}
                {children && children({ handleCloseModal: handleClose })}
            </Dialog>
            <ConfirmationModal
                yesHandler={handleCloseConfirmationModal}
                noHandler={() => setIsOpenConfirmModal(false)}
                isOpen={isOpenConfirmModal}
            />
            <Snackbar
                open={!!success}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setSuccess(false)}
            >
                <Alert elevation={6} severity="success" sx={{ width: "100%" }}>
                    {success}
                </Alert>
            </Snackbar>
        </>
    );
}
