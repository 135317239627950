import React from "react";
import { reduxForm, Field, hasSubmitSucceeded, formValueSelector } from "redux-form";
import { useSelector } from "react-redux";
import { compose } from "redux";

import { Stack, Typography } from "@mui/material";

import TextInput from "../../../components/core/form/TextInput";
import LoadingButton from "../../../components/core/buttons/LoadingButton";
import { SimpleLink } from "../../../components/core/link";
import { required } from "../../../utils/validation";
import { SEND_EMAIL } from "../../../constants/forms";
import { ROUTE_SIGNIN } from "../../../routes/routesStrings";
import { sendPasswordRestoreEmail } from "../../../actionCreators/auth";
import { withHandleFormError } from "../../../hoc";

function SucceededSendEmail({ email }) {
    return (
        <>
            <Stack direction="column" alignItems="center">
                <Typography variant="h2">Email was successfully sent to {email}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="center" sx={{ my: 2 }}>
                <SimpleLink to={ROUTE_SIGNIN}>Return to Login page</SimpleLink>
            </Stack>
        </>
    );
}

function EnterEmail({ handleSubmit, submitting, form }) {
    const hasSubmitSuccess = useSelector(hasSubmitSucceeded(form));
    const email = useSelector((state) => formValueSelector(form)(state, "email"));

    if (hasSubmitSuccess) return <SucceededSendEmail email={email} />;

    return (
        <form onSubmit={handleSubmit}>
            <Stack direction="column" alignItems="center">
                <Typography>Enter your email that you used to register.</Typography>
                <Typography>We'll send you an email with your user name and a link to reset you password</Typography>
            </Stack>
            <Stack sx={{ my: 3 }}>
                <Field name="email" label="Email" component={TextInput} validate={required} />
            </Stack>
            <Stack direction="row" justifyContent="center" sx={{ my: 2 }}>
                <LoadingButton loading={submitting} type="submit" size="large">
                    RESET PASSWORD
                </LoadingButton>
            </Stack>
            <Stack direction="row" justifyContent="center" sx={{ my: 2 }}>
                <SimpleLink to={ROUTE_SIGNIN}>Return to Login page</SimpleLink>
            </Stack>
        </form>
    );
}

const onSubmit = (values, dispatch) => dispatch(sendPasswordRestoreEmail(values));

export default compose(reduxForm({ form: SEND_EMAIL, onSubmit }), withHandleFormError)(EnterEmail);
