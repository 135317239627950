import React from "react";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import PinDropIcon from "@mui/icons-material/PinDrop";
import Skeleton from "@mui/material/Skeleton";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import { useActions } from "../../../hooks";
import { selectCurrentSite, selectCurrentSiteMeta } from "../../../slices/sitesSlice";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global.js";
import Loader from "../../../components/core/loader";
import Map from "./SiteMap";
import HomeIcon from "@mui/icons-material/Home";

const ContainerStyled = styled(Box)(({ theme, siteParamId }) => ({
    [theme.breakpoints.down(950)]: {
        display: siteParamId ? "auto" : "none !important",
    },
}));

function SiteDetailsSkeleton(props) {
    return (
        <ContainerStyled
            siteParamId={props.siteParamId}
            component="main"
            sx={{ flex: 1, p: 2 }}
            className={LOADER_WHITE_OVERLAY}
        >
            <Loader />
            <Skeleton variant="h1" animation="wave" sx={{ fontSize: "20px", mt: "14px", mb: "22px" }} width="50%" />
            <Divider sx={{ my: 2 }} />
            <Skeleton variant="rectangular" animation="wave" height={280} width="100%" />
            <Divider sx={{ my: 2 }} />
            <Skeleton variant="h1" animation="wave" sx={{ fontSize: "16px" }} width="20%" />
            <Stack direction="column" sx={{ my: 2 }}>
                <Skeleton variant="h3" animation="wave" sx={{ fontSize: "10px" }} width="20%" />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: "10px" }} width="20%" />
            </Stack>
            <Stack direction="column" sx={{ my: 2 }}>
                <Skeleton variant="h3" animation="wave" sx={{ fontSize: "10px" }} width="20%" />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: "10px" }} width="15%" />
            </Stack>
            <Stack direction="column" sx={{ my: 2 }}>
                <Skeleton variant="h3" animation="wave" sx={{ fontSize: "10px" }} width="22%" />
                <Skeleton variant="text" animation="wave" sx={{ fontSize: "10px" }} width="30%" />
            </Stack>
        </ContainerStyled>
    );
}

function SiteDetails(props) {
    const { getSiteDetails, resetSiteDetails } = useActions();
    const currentSite = useSelector(selectCurrentSite);
    const { loading } = useSelector(selectCurrentSiteMeta);

    const { id: siteParamId } = useParams();

    React.useEffect(() => {
        if (siteParamId) {
            getSiteDetails(siteParamId);
        }
        return () => {
            resetSiteDetails();
        };
    }, [siteParamId]);

    if (loading) {
        return <SiteDetailsSkeleton siteParamId={siteParamId} />;
    }

    if (!currentSite) {
        return (
            <ContainerStyled siteParamId={siteParamId} component="main" sx={{ flex: 1, p: 2 }}>
                <Typography variant="h1" sx={{ textAlign: "center", mt: "12px", mb: "24px" }}>
                    No data to show
                </Typography>
            </ContainerStyled>
        );
    }

    return (
        <ContainerStyled
            siteParamId={siteParamId}
            component="main"
            sx={{ flex: 1, p: 2 }}
            className={loading && LOADER_WHITE_OVERLAY}
        >
            {loading && <Loader />}
            {siteParamId && (
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/projects-and-sites/sites">
                        Sites
                    </Link>
                    <Typography color="text.primary">Site #{siteParamId}</Typography>
                </Breadcrumbs>
            )}
            <Typography variant="h1" sx={{ mt: "12px", mb: "24px" }}>
                {currentSite?.name}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Box direction="row" alignItems="center" sx={{ my: 2 }}>
                {currentSite && <Map currentSite={currentSite} />}
            </Box>
            <Divider sx={{ my: 2 }} />
            <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
                <Typography variant="h1">Site Info</Typography>
                <Divider sx={{ my: 2 }} />
            </Stack>
            {!!currentSite.notes && (
                <Stack direction="row" sx={{ my: 2 }}>
                    <PinDropIcon />
                    <Stack direction="column" alignItems="flex-start" sx={{ ml: 1 }}>
                        <Typography variant="h3">Site Notes</Typography>
                        <Typography>{currentSite.notes}</Typography>
                    </Stack>
                </Stack>
            )}
            <Stack direction="row" sx={{ my: 2 }}>
                <PinDropIcon />
                <Stack direction="column" alignItems="flex-start" sx={{ ml: 1 }}>
                    <Typography variant="h3">Site Type</Typography>
                    <Typography>{currentSite.type}</Typography>
                </Stack>
            </Stack>
            <Stack direction="row" sx={{ my: 2 }}>
                <HomeIcon />
                <Stack direction="column" alignItems="flex-start" sx={{ ml: 1 }}>
                    <Typography variant="h3">Site Address</Typography>
                    <Typography>{currentSite.address}</Typography>
                </Stack>
            </Stack>
        </ContainerStyled>
    );
}

export default SiteDetails;
