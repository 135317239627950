import dataProvider from "./httpRequest";
import { COMPANY_ENDPOINTS, USER_ENDPOINTS } from "./endpoints";

export const fetchUsers = async (params) => {
    return await dataProvider({
        url: USER_ENDPOINTS.USERS(),
        method: "GET",
        params,
    });
};

export const fetchCompanies = async (params) => {
    return await dataProvider({
        url: COMPANY_ENDPOINTS.COMPANIES(),
        method: "GET",
        params,
    });
};

export const inviteCompany = async (data) => {
    return await dataProvider({
        url: COMPANY_ENDPOINTS.INVITE_COMPANY(),
        method: "POST",
        data,
    });
};

export const createUserRequest = (userData) => {
    return dataProvider({
        url: USER_ENDPOINTS.USERS(),
        method: "POST",
        data: userData,
    });
};
export const updateUserRequest = (userId, userData) => {
    return dataProvider({
        url: USER_ENDPOINTS.USER_BY_ID(userId),
        method: "PUT",
        data: userData,
    });
};
export const userSignUpDecisionRequest = (userId, data) => {
    return dataProvider({
        url: USER_ENDPOINTS.USER_SIGN_UP_DECISION(userId),
        method: "PUT",
        data,
    });
};
