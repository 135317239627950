import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Field, hasSubmitSucceeded, reduxForm } from "redux-form";
import { useSelector } from "react-redux";

import { Box, Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { LoadingButton, TextInput } from "../../../components";
import { required } from "../../../utils/validation";
import { SIGN_IN_FORM } from "../../../constants/forms";
import { signIn } from "../../../actionCreators/user";
import { SimpleLink } from "../../../components/core/link";
import { ROUTE_FORGOT_PASSWORD, ROUTE_REQUESTS } from "../../../routes/routesStrings";
import EmailField from "../../../components/core/form/EmailField/emailField";

function SignInForm(props) {
    const navigate = useNavigate();

    const hasSubmitSuccess = useSelector(hasSubmitSucceeded(SIGN_IN_FORM));

    useEffect(() => {
        if (hasSubmitSuccess) {
            navigate(ROUTE_REQUESTS);
        }
    }, [hasSubmitSuccess]);

    return (
        <form onSubmit={props.handleSubmit}>
            {/* <Stack direction="row" spacing={2}>
                <GoogleButton color="inherit" variant="outlined">
                    <GoogleIconWrapper>
                        <GoogleIcon />
                    </GoogleIconWrapper>
                    <GoogleLabel variant="body2">Google</GoogleLabel>
                </GoogleButton>
            </Stack> */}
            {/* <Divider sx={{ my: 3 }}>
                <Typography variant="body2" sx={{ color: "text.secondary", fontWeight: "bold" }}>
                    OR
                </Typography>
            </Divider> */}
            <Stack sx={{ my: 2 }}>
                <EmailField isRequired label="Work Email" />
            </Stack>
            <Stack sx={{ mb: 3 }}>
                <Box display="flex" justifyContent="flex-end" sx={{ mb: 1 }}>
                    <SimpleLink to={ROUTE_FORGOT_PASSWORD}>Forgot password?</SimpleLink>
                </Box>
                <Field name="password" label="Password" type="password" validate={[required]} component={TextInput} />
            </Stack>
            <LoadingButton type="submit" disabled={props.invalid} loading={props.submitting}>
                Log in
            </LoadingButton>
            <Snackbar
                open={props.error}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                autoHideDuration={1000}
            >
                <Alert severity="error" sx={{ width: "100%" }}>
                    {props.error}
                </Alert>
            </Snackbar>
        </form>
    );
}

const GoogleButton = styled(Button)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    paddingTop: 4,
    paddingRight: 30,
    paddingBottom: 4,
    paddingLeft: 4,
    alignItems: "center",
    border: "none",
    borderRadius: 3,
    backgroundColor: "rgb(55 114 237 / 93%) !important",
}));

const GoogleIconWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: "#fff",
    marginRight: 20,
    padding: 8,
    borderRadius: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));

const GoogleLabel = styled(Typography)(({ theme }) => ({
    textTransform: "capitalize",
    fontSize: 14,
    fontWeight: "bold",
    color: "#fff",
}));

const onSubmit = async (values, dispatch) => dispatch(signIn(values));

export default reduxForm({ form: SIGN_IN_FORM, onSubmit })(SignInForm);
