import _ from "lodash";
import { UNIT_OF_MEASURE_BY_ID } from "../constants/global";

export const getPrice = (perUnitPrice, unitOfMeasure) =>
    `${
        perUnitPrice
            ? ` - $${perUnitPrice && perUnitPrice.toFixed(2)} / ${
                  unitOfMeasure && UNIT_OF_MEASURE_BY_ID[unitOfMeasure].toUpperCase()
              }`
            : ""
    }`;

export const getProjectItemLabel = ({ payload, perUnitPrice, unitOfMeasure }) =>
    `${payload ? payload : ""}${getPrice(perUnitPrice, unitOfMeasure)}`;

export const getHoursQty = (quantity) =>
    `${Math.trunc(quantity)}h ${((quantity - Math.trunc(quantity)) * 60).toFixed(0)}m`;

export const getUnitOfMeasureValue = (unitOfMeasure) => unitOfMeasure && unitOfMeasure.replace(/\D/g, "");

export const handleError = (error) => {
    let payload = "";
    if (typeof error === "string") {
        payload = error;

        return { message: payload, error: true };
    } else if (error && error.message && typeof error.message === "string") {
        payload = error.message;

        return { message: payload, error: true };
    } else if (error && error.message && _.isObject(error.message) && Object.keys(error.message).length) {
        payload = Object.keys(error.message).reduce(
            (accum, key) => `${accum ? accum + "; " : ""}${key}: ${error.message[key] || ""}`,
            "",
        );

        return { message: payload, error: true };
    } else if (_.isObject(error) && Object.keys(error).length) {
        payload = Object.keys(error).reduce(
            (accum, key) => `${accum ? accum + "; " : ""}${key}: ${error[key] || ""}`,
            "",
        );

        return { message: payload, error: true };
    }
};
