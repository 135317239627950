import React from "react";
import moment from "moment";
import { debounce } from "lodash";
import { reduxForm, Field, getFormValues } from "redux-form";
import { useSelector } from "react-redux";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/material/styles";

import { useDebounce } from "../../../hooks";
import DatePicker from "../../../components/core/DatePicker";
import CommonFilters from "../../../components/core/FilterSelector";

const FILTERS = [
    {
        key: "status",
        label: "Project Status",
        items: [
            {
                label: "Active",
                value: 1,
            },
            {
                label: "Closed",
                value: 2,
            },
            {
                label: "Archived",
                value: 3,
            },
        ],
    },
];

const StyledDateInput = styled(TextField)(({}) => ({
    ".MuiInputBase-input": { padding: "7px 0px 7px 7px" },
    ".MuiSvgIcon-root": { width: 20 },
}));

const RenderTextField = ({ handleTextChange, input, meta }) => {
    return (
        <StyledDateInput
            {...input}
            placeholder="Type to search"
            variant="outlined"
            onChange={(e) => {
                input.onChange(e);
                handleTextChange && handleTextChange();
            }}
        />
    );
};

const _format = "YYYY-MM-DD";

function Filters({ form, dispatch, change, request, reset }) {
    const handleTextChange = useDebounce(() => {
        request && request();
    }, 500);

    const resetFilters = () => {
        reset();
        request && request();
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
                <Stack sx={{ flex: 1 }}>
                    <Field name="keywords" handleTextChange={handleTextChange} component={RenderTextField} />
                </Stack>
                <Field name="filters" request={request} reset={resetFilters} filters={FILTERS} component={CommonFilters} />
                <IconButton onClick={resetFilters}>
                    <RefreshIcon />
                </IconButton>
            </Stack>
        </LocalizationProvider>
    );
}

export default reduxForm({})(Filters);
