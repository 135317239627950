import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { styled } from "@mui/material/styles";

import { UNIT_OF_MEASURE_HOUR, UNIT_OF_MEASURE_LOAD, UNIT_OF_MEASURE_BY_ID } from "../../constants/global";

const LeftQuantityStyled = styled(Typography)(() => ({
    fontSize: 19,
    color: "#979797",
    fontWeight: 600,
}));

const RightQuantityStyled = styled(Typography)(() => ({
    display: "flex",
    fontSize: 19,
    color: "#393939",
    fontWeight: 600,
    marginRight: 5,
}));

const Divider = styled(Box)(() => ({
    padding: "0px 4px",
}));

const UnitOfMeasure = (props) => {
    const { value, isPlural } = props;

    return (
        <Box style={{ marginLeft: 4, textTransform: "uppercase" }}>
            {value}
            {isPlural && "s"}
        </Box>
    );
};

export const getHoursQty = (quantity) =>
    `${Math.trunc(quantity)}h ${((quantity - Math.trunc(quantity)) * 60).toFixed(0)}m`;

function JobQuantity({
    unitOfMeasure,
    completedQuantity,
    showCompletedCubage,
    unlimited,
    totalQuantity,
    dispatchedQuantity,
    completedCubage,
}) {
    const completedQuantityText =
        unitOfMeasure.id === UNIT_OF_MEASURE_HOUR ? getHoursQty(completedQuantity) : +completedQuantity?.toFixed(2);

    const loadQuantityText = dispatchedQuantity ? +dispatchedQuantity?.toFixed(2) : 0;

    const hourQuantityText = getHoursQty(totalQuantity);

    const getTotalLimitedQuantity = () => {
        switch (unitOfMeasure.id) {
            case UNIT_OF_MEASURE_LOAD: {
                return (
                    <>
                        {loadQuantityText} <UnitOfMeasure isPlural value={unitOfMeasure.name} />
                    </>
                );
            }
            case UNIT_OF_MEASURE_HOUR: {
                return <>{hourQuantityText}</>;
            }
            default: {
                return (
                    <>
                        {+totalQuantity?.toFixed(2)} <UnitOfMeasure isPlural value={unitOfMeasure.name} />
                    </>
                );
            }
        }
    };

    return (
        <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
                <LeftQuantityStyled>
                    {completedQuantityText}{" "}
                    {showCompletedCubage && <Typography> ({completedCubage} CUBIC YARDS)</Typography>}
                </LeftQuantityStyled>
                <Divider>/</Divider>
                {unlimited ? (
                    <RightQuantityStyled>
                        <AllInclusiveIcon /> <UnitOfMeasure isPlural value={unitOfMeasure.name} />
                    </RightQuantityStyled>
                ) : (
                    <RightQuantityStyled>{getTotalLimitedQuantity()}</RightQuantityStyled>
                )}
            </Box>
            {!unlimited && unitOfMeasure.id === UNIT_OF_MEASURE_LOAD && (
                <RightQuantityStyled>
                    {+dispatchedQuantity !== +totalQuantity && `(${+totalQuantity.toFixed(2)} Total Requested)`}
                </RightQuantityStyled>
            )}
        </Box>
    );
}

export default JobQuantity;
