import React from "react";
import { Field, reduxForm } from "redux-form";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { useActions, useDebounce } from "../../../hooks";
import SearchTextField from "../../../components/core/filters/SearchTextField";
import { MY_COMPANIES_FILTERS } from "../../../constants/forms";
import { Divider } from "@mui/material";
import FormDialog from "../../../components/core/FormDialog";
import { InviteCompanyForm } from "../../../components/forms";

function MyCompaniesFilters({ reset }) {
    const { refreshMyCompaniesList } = useActions();

    const handleTextChange = useDebounce(() => {
        refreshMyCompaniesList();
    }, 500);

    const resetFilters = () => {
        reset();
        refreshMyCompaniesList();
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid container sx={{ my: 1, alignItems: "center" }}>
                    <Grid item xs={12} sm={6}>
                        <FormDialog
                            dialogTitle={`INVITE COMPANY`}
                            form={`INVITE COMPANY`}
                            fullWidth
                            maxWidth="xs"
                            dialogTitleAlign="center"
                            successLabel={"Company Was Invited"}
                            OpenModalComponent={(props) => (
                                <Stack direction="row" alignItems="center">
                                    <Button variant="text" startIcon={<AddCircleOutlineIcon />} {...props}>
                                        INVITE COMPANY
                                    </Button>
                                </Stack>
                            )}
                        >
                            {(props) => <InviteCompanyForm {...props} />}
                        </FormDialog>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Stack direction="row">
                            <Field name="keywords" handleTextChange={handleTextChange} component={SearchTextField} />
                            <IconButton onClick={resetFilters}>
                                <RefreshIcon />
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
            </LocalizationProvider>
            <Divider />
        </>
    );
}

export default reduxForm({
    form: MY_COMPANIES_FILTERS,
})(MyCompaniesFilters);
