import httpRequest from "./httpRequest";
import dataProvider from "./httpRequest";
import { JOB_ORDER_ENDPOINTS } from "./endpoints";
import { GLOBAL_ENDPOINTS } from "../constants/endpoints/global";

export const getRequests = async (params) => {
    return await httpRequest({
        url: JOB_ORDER_ENDPOINTS.REQUESTS(),
        method: "GET",
        params: {
            ...params,
            order: "-id",
            perPage: 20,
            flat: true,
            withTrucks: true,
        },
    });
};

export const getRequestDetails = async (requestId) => {
    return await httpRequest({
        url: JOB_ORDER_ENDPOINTS.REQUEST_DETAILS(requestId),
        method: "GET",
    });
};

export const getRequstTurnTime = async (requestId) => {
    return await httpRequest({
        url: JOB_ORDER_ENDPOINTS.REQUEST_TURNTIME(requestId),
        method: "GET",
    });
};

export const getJobOrderRoutes = (joId) => {
    return httpRequest({
        url: JOB_ORDER_ENDPOINTS.REQUESTS_ROUTES(joId),
        method: "GET",
    });
};

export const getJobOrderMapData = (joId) => {
    return httpRequest({
        url: JOB_ORDER_ENDPOINTS.REQUESTS_MAP(joId),
        method: "GET",
    });
};

export const getStatsTabData = (requestId) => {
    return httpRequest({
        url: JOB_ORDER_ENDPOINTS.REQUEST_STATS_TAB(requestId),
        method: "GET",
    });
};

export const getJobOrderETAData = (params = {}) => {
    return httpRequest({
        url: JOB_ORDER_ENDPOINTS.REQUEST_ETA(),
        method: "GET",
    });
};

export const getStates = (params) => {
    return dataProvider({
        url: GLOBAL_ENDPOINTS.GET_STATES(),
        method: "GET",
        params,
    });
};

export const fetchCreateRequest = (data) => {
    return dataProvider({
        url: JOB_ORDER_ENDPOINTS.REQUESTS(),
        method: "POST",
        data,
    });
};

export const fetchUpdateRequest = (id, data) => {
    return dataProvider({
        url: JOB_ORDER_ENDPOINTS.UPDATE_REQUEST(id),
        method: "PUT",
        data,
    });
};
