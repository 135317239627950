import { store } from "../../app/store";
import { DEFAULT_DATE_TIME_FORMAT } from "../../constants/global";
import { processEvent } from "./processors/baseProcessor";
import { processAffiliatedCompanyEvent } from "./processors/affiliatedCompanyProcessor";
import { selectAccountTimezone } from "../../slices/userSlice";
import { processRequestEvent } from "./processors/requestProcessor";

const getState = store.getState;

const _prepareEvent = ({ event, timezone = null, dateTimeFormat = DEFAULT_DATE_TIME_FORMAT }, processors) => {
    const state = getState();
    return processors.reduce(
        (newEvent, processor) =>
            processor({
                event: newEvent,
                timezone: timezone || selectAccountTimezone(state),
                dateTimeFormat,
            }),
        event,
    );
};

export const prepareAffiliatedCompanyEvent = ({ event, ...options }) => {
    return _prepareEvent({ event, ...options }, [processEvent, processAffiliatedCompanyEvent]);
};

export const prepareRequestEvent = ({ event, ...options }) => {
    return _prepareEvent({ event, ...options }, [processEvent, processRequestEvent]);
};
