import React from "react";
import PropTypes from "prop-types";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { agreeWithTermsAndConditions, removeUserSession } from "../../actionCreators/user";
import { ButtonsGroup } from "../core/buttons/ButtonsGroup/buttonsGroup";
import { PrimaryButton, SecondaryButton } from "../index";
import { GLOBAL_ENDPOINTS } from "../../constants/endpoints/global";
import { compose } from "recompose";
import CustomCheckbox from "../core/form/СustomCheckbox";
import AppModal from "../core/modals/AppModal";
import { ACCEPT_TERMS_FORM } from "../../constants/forms";
import { useActions } from "../../hooks";
import withHandleFormError from "../../hoc/withHandleFormError";

const TermsDialogForm = (props) => {
    const { handleSubmit, isAccepted, handleClose, termsDialogOpen } = props;
    const { updateGlobalErrorMessage } = useActions();

    const closeModal = () => {
        removeUserSession();
        updateGlobalErrorMessage("To use app you must accept terms and conditions.");
        handleClose && handleClose();
    };

    return (
        <AppModal
            isOpen={termsDialogOpen}
            form={ACCEPT_TERMS_FORM}
            modalStyles={{ width: 800 }}
            closeModal={closeModal}
        >
            <h2 className="title">{"To use website you have to accept these Terms And Conditions"}</h2>
            <iframe
                title="Terms and Conditions"
                src={GLOBAL_ENDPOINTS.TERMS_AND_CONDITIONS()}
                frameBorder="0"
                height={250}
                className="iframe --full-width"
                id={"iframe"}
            />
            <form onSubmit={handleSubmit}>
                <Field
                    name="acceptTermsAndConditions"
                    type="checkbox"
                    label="I accept these Terms and Conditions."
                    component={CustomCheckbox}
                />
                <ButtonsGroup>
                    <SecondaryButton type="button" onClick={closeModal}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton type="submit" disabled={!isAccepted}>
                        Approve
                    </PrimaryButton>
                </ButtonsGroup>
            </form>
        </AppModal>
    );
};

TermsDialogForm.propTypes = {
    isAccepted: PropTypes.bool.isRequired,
    termsDialogOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

const onSubmit = (values, dispatch) => dispatch(agreeWithTermsAndConditions(values));

const onSubmitSuccess = (result, dispatch, { handleClose }) => {
    handleClose && handleClose();
};

export default compose(
    reduxForm({
        form: ACCEPT_TERMS_FORM,
        onSubmit,
        onSubmitSuccess,
    }),
    connect((state, props) => {
        const formSelector = formValueSelector(ACCEPT_TERMS_FORM);

        return {
            isAccepted: formSelector(state, "acceptTermsAndConditions"),
        };
    }),
    withHandleFormError,
)(TermsDialogForm);
