import { reducer as formReducer } from "redux-form";

import userSlice, { actions as userActions } from "./userSlice";
import requestsSlice, { actions as requestsActions } from "./requestSlice";
import projectsSlice, { actions as projectsActions } from "./projectsSlice";
import sitesSlice, { actions as sitesActions } from "./sitesSlice";
import myCompanySlice, { actions as myCompanyActions } from "./myCompanySlice";
import notificationsSlice, { actions as notificationsActions } from "./notificationsSlice";
import utilsSlice, { actions as utilsActions } from "./utilsSlice";
import * as commonActions from "./actions";

export const actions = {
    ...userActions,
    ...requestsActions,
    ...projectsActions,
    ...sitesActions,
    ...myCompanyActions,
    ...notificationsActions,
    ...commonActions,
    ...utilsActions,
};

const rootReducer = {
    user: userSlice,
    requests: requestsSlice,
    form: formReducer,
    projects: projectsSlice,
    sites: sitesSlice,
    myCompany: myCompanySlice,
    notifications: notificationsSlice,
    utils: utilsSlice,
};

export default rootReducer;
