import React from "react";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

export const MEDIUM_BUTTON_SIZE = 150;

const primaryButtonStyles = makeStyles((theme) => ({
    button: {
        width: "100% !important",
        "&:disabled": {
            color: "#fff",
            background: "rgba(0, 0, 0, 0.28)",
        },
    },
    text: {
        width: MEDIUM_BUTTON_SIZE,
        height: 55,
        padding: "8px 10px",
        textTransform: "initial",
        lineHeight: 1.3,
        boxShadow: "0 2px 4px 0 rgba(83, 83, 83, 0.5)",
    },
    textPrimary: {
        color: "#fff !important",
        background: `linear-gradient(129deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark}) 80%`,

        "&:hover, &:active": {
            color: "#fff",
            background: `linear-gradient(129deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.dark}) 80%`,
        },
    },
    textSizeSmall: {
        fontSize: theme.typography.button.fontSize,
    },
    textSizeLarge: {
        fontSize: theme.typography.button.fontSize,
    },
    sizeSmall: {
        width: 125,
        height: 35,
    },
    sizeLarge: {
        width: 280,
        height: 59,
    },
    fullWidth: {
        width: "100% !important",
    },
    loading: {
        color: "transparent !important",
    },
}));

const LoadingButtonComponent = (props) => {
    const {
        onClick,
        children,
        otherProps,
        size,
        disabled,
        type,
        className,
        classes: passedClasses,
        style,
        loading,
        loadingIndicator,
    } = props;
    const classes = passedClasses || primaryButtonStyles();
    return (
        <LoadingButton
            color={"primary"}
            type={type}
            style={style}
            loading={loading}
            loadingIndicator={loadingIndicator}
            className={clsx(classes.button, size === "xs" && classes.sizeXSmall, className)}
            disabled={disabled}
            classes={{
                text: classes.text,
                textPrimary: classes.textPrimary,
                textSizeSmall: classes.textSizeSmall,
                textSizeLarge: classes.textSizeLarge,
                sizeSmall: classes.sizeSmall,
                sizeLarge: classes.sizeLarge,
                loading: classes.loading,
            }}
            onClick={(e) => onClick && onClick(e)}
            size={size}
            {...otherProps}
        >
            {children}
        </LoadingButton>
    );
};

LoadingButtonComponent.propTypes = {
    onClick: PropTypes.func,
    to: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any,
    otherProps: PropTypes.object.isRequired,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    disabled: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    classes: PropTypes.object,
    style: PropTypes.object,
};

LoadingButtonComponent.defaultProps = {
    otherProps: {},
    style: {},
    size: "medium",
    type: "button",
    color: "primary",
    disabled: false,
};

export default LoadingButtonComponent;
