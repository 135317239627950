import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { TABLE_PRIMARY } from "../../../styles/reusableStyles";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import TableHeaderSortingCell from "./TableHeaderSortingCell";
import WindowScrollableListWaypoint from "../WindowScrollableListWaypoint";

const UNAVAILABLE_TABLE_ROW_CLASSNAME = "row-unavailable";

const useStyles = makeStyles((theme) => ({
    unavailable: {
        backgroundColor: `${theme.palette.secondary.superLight} !important`,
    },
    tableOverride: {
        [`& .${UNAVAILABLE_TABLE_ROW_CLASSNAME}`]: {
            "&:hover": {
                backgroundColor: `${theme.palette.secondary.superLight} !important`,
            },
        },
    },
    totalsAmountRow: {
        "& th": {
            height: "40px !important",
            lineHeight: "1",
        },
    },
    totalsAmountItem: {
        color: `${theme.palette.secondary.dark} !important`,
        textAlign: "center",
        fontSize: "12px !important",
        padding: "0px !important",
        fontWeight: "bold !important",

        "& .total-value": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            borderTop: `1px solid ${theme.palette.secondary.dark}`,
            borderBottom: `1px solid ${theme.palette.secondary.dark}`,
            wordBreak: "break-all",
        },
    },
    emptyRowItem: { padding: "1px!important" },
}));

const TableWithStickyHeader = (props) => {
    const tableStyles = TABLE_PRIMARY();
    const { rows, columns, fetchMore, totalIndex, tableAlign, handleSort, activeOrderParam, headerRowHeight } = props;
    const classes = useStyles();

    return (
        <Table stickyHeader aria-label="sticky table" className={clsx(tableStyles.primaryTable, classes.tableOverride)}>
            <TableHead>
                <TableRow>
                    {columns.map((column, index) => {
                        if (column.sortParam) {
                            return (
                                <TableHeaderSortingCell
                                    key={index}
                                    align={column.align || tableAlign}
                                    style={{ minWidth: column.minWidth || 0 }}
                                    handleSort={handleSort}
                                    sortParam={column.sortParam}
                                    label={column.label}
                                    activeOrderParam={activeOrderParam}
                                />
                            );
                        }

                        const cellStyle = {
                            minWidth: column.minWidth || 0,
                        };

                        if (headerRowHeight) {
                            cellStyle.height = headerRowHeight;
                        }

                        return (
                            <TableCell key={index} align={column.align || tableAlign} style={cellStyle}>
                                {column.label}
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => {
                    const isEmptyRow = totalIndex && index === 0;

                    return (
                        <React.Fragment key={index}>
                            {fetchMore && (
                                <tr>
                                    <td style={{ padding: 0 }}>
                                        <WindowScrollableListWaypoint
                                            fetchMore={fetchMore}
                                            index={index}
                                            items={rows}
                                        />
                                    </td>
                                </tr>
                            )}
                            <TableRow
                                role="checkbox"
                                tabIndex={-1}
                                className={
                                    row.truckName &&
                                    !row.isAvailable &&
                                    clsx(UNAVAILABLE_TABLE_ROW_CLASSNAME, classes.unavailable)
                                }
                            >
                                {columns.map((column) => {
                                    const value = row[column.id];

                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            className={isEmptyRow && classes.emptyRowItem}
                                        >
                                            {value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </React.Fragment>
                    );
                })}
            </TableBody>
        </Table>
    );
};

TableWithStickyHeader.defaultProps = {
    tableAlign: "center",
    style: {},
    tableContainerClasses: "",
    rows: [],
    columns: [],
};

export default TableWithStickyHeader;
