import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { fetchCompanies } from "../api/myCompany";
import { selectAccountRole, selectCompanyId } from "../slices/userSlice";
import { IS_ADMIN_USER } from "../constants/global";
import { PARENT_COMPANY_ID } from "../constants/customersMapper/customersMapper";
import { showErrorSnackBar } from "../actionCreators/notifications";
import { handleError } from "../utils";
import { removeUserSession } from "../actionCreators/user";
import { WRONG_PORTAL_MESSAGE } from "../constants/errors";

export const useIsWrongCompanyPortal = () => {
    const dispatch = useDispatch();
    const role = useSelector(selectAccountRole);
    const companyId = useSelector(selectCompanyId);

    const isAdmin = IS_ADMIN_USER(role);

    const handleWrongCompanyUserSignIn = () => {
        removeUserSession();
        dispatch(showErrorSnackBar(WRONG_PORTAL_MESSAGE));
    };

    useEffect(() => {
        (async () => {
            try {
                if (isAdmin) {
                    if (companyId !== PARENT_COMPANY_ID) {
                        return handleWrongCompanyUserSignIn();
                    }
                    return;
                }
                const { data: companies } = await fetchCompanies({
                    companiesIds: companyId,
                });
                const userCompany = companies && companies[0];
                const userParentCompany = userCompany?.parentCompany?.id;

                if (userParentCompany !== PARENT_COMPANY_ID) {
                    return handleWrongCompanyUserSignIn();
                }
            } catch (error) {
                dispatch(showErrorSnackBar(handleError(error).message));
            }
        })();
    }, []);
};
