import { styled } from "@mui/material/styles";
import { Box, Container } from "@mui/material";
import SignUpForm from "./SignUpForm/SignUpForm";

const SignUpStyled = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(3, 0),
}));

export default function SignUp() {
    return (
        <Box title="Login">
            <Container maxWidth="sm">
                <SignUpStyled>
                    <SignUpForm />
                </SignUpStyled>
            </Container>
        </Box>
    );
}
