import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";

import clsx from "clsx";
import Axios from "axios";
import { makeStyles } from "@mui/styles";
import { useDebounce } from "../../../hooks/useDebounce";
import { AddCircleOutline, ArrowDropDown, DoneAll } from "@mui/icons-material";
import { FieldNote } from "../../index";

export const SELECT_ALL_OPTIONS_ID = "isSelectAll";
export const CREATE_NEW_OPTION_ID = "isCreateNew";

const useStyles = makeStyles((theme) => ({
    autoCompleteComponent: {
        position: "relative",

        "&.async-select-wrapper": {
            width: "100%",
        },
    },
    asyncSelect: {
        minHeight: 50,

        "&:hover": {
            borderRadius: theme.shape.borderRadius,
            borderColor: "transparent",
        },

        "& .___value-container, & .___control": {
            border: "none",

            "&:hover": {
                borderRadius: theme.shape.borderRadius,
                borderColor: "transparent",

                "&.___control--is-focused": {
                    // borderBottom: `2px solid ${theme.palette.general.green}`,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                },
            },
        },

        "& .custom-control .___control": {
            display: "flex",
            alignItems: "center",
        },

        "& .___control--is-focused": {
            // borderBottom: `2px solid ${theme.palette.general.green}`,
            borderRadius: theme.shape.borderRadius,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            boxShadow: "none",
            outline: "none",
        },

        "& .___control--is-disabled": {
            backgroundColor: "transparent",
            cursor: "not-allowed",

            "& .___single-value, & .___multi-value": {
                opacity: 0.4,
            },
        },

        "& .___control--is-disabled .___placeholder, .___control--is-disabled .___value-container": {
            backgroundColor: "transparent",
            color: theme.palette.secondary.superLight,
        },

        "& .___placeholder": {
            position: "absolute",
            backgroundColor: "#fff",
            color: theme.palette.secondary.superDark,
            fontSize: 16,
            fontWeight: theme.typography.fontWeightMedium,
            pointerEvents: "none",
            zIndex: 1,
            top: 4,
            transition: "font-size 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, top 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",

            "& + .___placeholder": {
                fontSize: "0 !important",
            },
        },

        "& .___control--menu-is-open, & .___value-container--has-value, & .___value-container--has-value.___value-container--is-multi":
            {
                "& .___placeholder": {
                    top: -18,
                    fontSize: "12px !important",
                    padding: "0 10px 0 5px",
                    margin: 0,
                    backgroundColor: "#fff",
                    transition: "top 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                },
            },

        "& .___value-container": {
            padding: 0,
            overflow: "visible",
            minHeight: 32,
        },

        "& .___value-container--is-multi": {
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-start",
            maxWidth: "100%",

            "&.___value-container--has-value": {
                // padding: '2px 0',
            },
        },

        "& .___multi-value": {
            fontSize: 16,
            maxWidth: "100%",
            margin: 1,
        },

        "& .___menu": {
            borderRadius: 0,
            borderColor: theme.palette.border.primary,
            zIndex: 10,
        },

        "& .___option": {
            padding: "10px 27px",
            fontSize: theme.typography.fontSize,
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.secondary.dark,
            textAlign: "left",

            "&.-create-new, &.-select-all": {
                color: theme.palette.primary.main,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",

                "& svg": {
                    color: "inherit",
                    width: 25,
                    height: 25,
                    marginRight: 10,
                },
            },

            "&.___option--is-focused, &.___option--is-selected": {
                backgroundColor: theme.palette.general.lightGrey,
            },
        },

        "& input": {
            fontSize: theme.typography.fontSize,
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.secondary.dark,
            border: "none",
            padding: 0,
        },

        "& .___indicators": {
            color: theme.palette.secondary.dark,

            "& svg": {
                color: "inherit",
            },
        },
        "& .___indicator": {
            padding: 4,
        },

        "& .___indicator-separator": {
            display: "none",
        },

        "&.form-view": {
            pointerEvents: "auto",
            border: `2px solid ${theme.palette.border.primary}`,
            borderRadius: theme.shape.borderRadius,
            width: "100%",

            "&._--is-disabled": {
                cursor: "not-allowed",
            },

            "& .custom-control .___control": {
                padding: "12px",
            },

            "&.validation-error": {
                borderColor: theme.palette.general.darkishRed,
            },

            "& .___single-value": {
                color: theme.palette.secondary.dark,
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: 16,
            },
        },
        "&.form-view.tiny-view": {
            minHeight: 0,
            border: `1px solid ${theme.palette.border.primary}`,

            "& .custom-control .___control": {
                padding: "0px 10px",
            },

            "& .___control--is-focused": {
                "& .___value-container--is-multi": {
                    padding: "3px 0",
                },
            },

            "& .___value-container--is-multi": {
                "&.___value-container--has-value": {
                    padding: "3px 0",
                },
            },

            "& .___single-value": {
                fontSize: 12,
            },

            "& .___placeholder": {
                fontSize: 12,

                "& + .___placeholder": {
                    fontSize: "0 !important",
                },
            },

            "& .___indicators svg": {
                width: 20,
                height: 20,
            },

            "& .___control--menu-is-open, & .___value-container--has-value, & .___value-container--has-value.___value-container--is-multi":
                {
                    "& .___placeholder": {
                        top: -13,
                        fontSize: "12px !important",
                    },
                },
        },
        "& .custom-control svg.drop-off, & .custom-control svg.pick-up": {
            top: 0,
            margin: 0,
        },
        "&.specific-view": {
            " & .___value-container--has-value": {
                "& .___placeholder": {
                    display: "none",
                },
            },
            "& .___value-container, & .___control--menu-is-open": {
                "& .___placeholder": {
                    fontSize: 14,
                    paddingLeft: 10,
                    position: "initial",
                },
            },
        },
    },
    predictiveSearch: {
        "& .custom-control .___control": {
            borderColor: theme.palette.border.primary,
            borderWidth: 2,
        },

        "& .custom-control .___control--is-focused": {
            border: `2px solid ${theme.palette.general.green}`,
            boxShadow: "none",
            borderRadius: theme.shape.borderRadius,

            "&:hover": {
                borderColor: theme.palette.general.green,
            },
        },
        "& .___placeholder": {
            color: "rgb(182,182,182)",
            fontSize: 16,

            "& + .___placeholder": {
                fontSize: "0 !important",
            },
        },
        "& .___value-container--has-value, & .___value-container--has-value.___value-container--is-multi": {
            "& .___placeholder": {
                display: "none",
            },
        },
    },
}));

const DropdownIndicator = () => {
    return <ArrowDropDown />;
};

const Control = (props) => {
    return (
        <div className="custom-control">
            <components.Control {...props}>
                {props.selectProps.startAdornment}
                {props.children}
            </components.Control>
        </div>
    );
};

const ValueContainer = (props) => {
    return (
        <components.ValueContainer {...props}>
            <div className="___placeholder">{props.selectProps.label || props.selectProps.placeholder}</div>
            {props.children}
        </components.ValueContainer>
    );
};

const getSelectComponents = (isPredictiveSearch) => {
    const components = {
        Control,
        Menu,
    };
    if (isPredictiveSearch) {
        components.DropdownIndicator = null;
    } else {
        components.ValueContainer = ValueContainer;
        components.DropdownIndicator = DropdownIndicator;
    }

    return components;
};

const Menu = (props) => {
    return (
        <components.Menu {...props}>
            {props.selectProps.selectAllOption && (
                <div className="___option -select-all" onClick={props.selectProps._selectAllHandler}>
                    <DoneAll />
                    {props.selectProps.selectAllOption.label || "Select All"}
                </div>
            )}
            {props.children}
            {props.selectProps.createNewOption && (
                <div className="___option -create-new" onClick={props.selectProps._createNewHandler}>
                    <AddCircleOutline />
                    {props.selectProps.createNewOption.label}
                </div>
            )}
        </components.Menu>
    );
};

const CustomCreatableSelect = (props) => {
    return (
        <CreatableSelect
            formatCreateLabel={(inputValue) => `${inputValue}`}
            createOptionPosition={"first"}
            allowCreateWhileLoading={true}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 150 }) }}
            ref={props.selectRef}
            {...props}
        />
    );
};

let requestPromise;
let cancelPromise = null;

export const AutocompleteComponent = (props) => {
    const {
        input,
        placeholder,
        loadOptions,
        createNewOption,
        createNewHandler,
        fieldNote,
        startAdornment,
        isMulti,
        disabled,
        className,
        label,
        meta: { touched, error },
        noOptionsMessage,
        wrapperClassName,
        isClearable,
        selectAllHandler,
        selectAllOption,
        defaultOptions,
        key,
        classes,
        reloadList,
        onFieldChange,
        isPredictiveSearch,
        clearAfterCloseMenu,
        maxMenuHeight,
        isSearchable,
    } = props;
    const selectRef = useRef();
    const [loadedOptions, setOptions] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isFocused, setFocusState] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [firstTimeOpen, setFirstTimeOpen] = useState(true);
    const [page, setPage] = useState(1);
    const isError = touched && error;
    const loadMore = (inputValue, nextPage, concat, initialLoad) => {
        if (requestPromise && cancelPromise) {
            cancelPromise.cancel();
            cancelPromise = null;
        }

        setLoading(true);

        const cancelToken = Axios.CancelToken.source();
        cancelPromise = cancelToken;

        requestPromise = loadOptions(
            inputValue,
            {
                params: { page: nextPage || page, cancelToken: cancelToken },
                loadedCount: concat && loadedOptions.length,
            },
            concat,
            initialLoad,
        );

        return requestPromise
            .then(({ options, hasMore, page }) => {
                requestPromise = null;

                setOptions(concat ? [...loadedOptions, ...options] : options);
                setHasMore(hasMore);
                setPage(page);
                setLoading(false);

                return { options, hasMore, page };
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const loadMoreWithSearch = useDebounce(loadMore);

    const onMenuScrollToBottom = (value) => {
        if (hasMore) {
            const inputValue = selectRef?.current?.props?.inputValue;
            loadMoreWithSearch(inputValue, page + 1, true);
        }
    };

    const onInputChange = (value, { action }) => {
        if (action === "input-change") {
            let page;
            // if (inputValue !== value) {
            //     page = 1;
            // }
            loadMoreWithSearch(value, page);
            // was removed in https://github.com/TruckITllc/truckit-frontend/issues/186
            // because passing value in input resets prev selected input value
            // input.onChange(value);
        }
        // added blur using useRef because of known issue of react-select
        // https://github.com/JedWatson/react-select/issues/3104
        if (action === "input-blur") {
            setTimeout(() => selectRef.current.select?.blur());
        }
    };

    const addParams = () => {
        let restParams = {};
        if (onFieldChange) restParams.onChange = onFieldChange;

        return { ...restParams };
    };

    const onMenuClose = () => {
        setPage(1);
        setHasMore(false);
        setFirstTimeOpen(true);
        clearAfterCloseMenu && setOptions([]);
    };

    const _createNewHandler = () => {
        createNewHandler && createNewHandler();
    };

    const _selectAllHandler = () => {
        selectAllHandler && selectAllHandler(input.name);
    };

    // @todo workaround before https://github.com/JedWatson/react-select/pull/3897 will be merged
    const onMenuOpen = () => {
        firstTimeOpen && setFirstTimeOpen(false);

        if (firstTimeOpen && !defaultOptions && !isPredictiveSearch) {
            loadMore("");
        }
    };

    useEffect(() => {
        reloadList && loadMore("");
    }, [reloadList]);

    useEffect(() => {
        defaultOptions && loadMore("", null, false, true);
    }, []);

    const SelectComponent = isPredictiveSearch ? CustomCreatableSelect : Select;
    const customComponents = getSelectComponents(isPredictiveSearch);

    return (
        <div
            className={clsx(
                classes.autoCompleteComponent,
                wrapperClassName,
                "async-select-wrapper",
                isFocused && "-focused",
            )}
        >
            <SelectComponent
                {...input}
                openMenuOnClick={!isPredictiveSearch}
                options={loadedOptions}
                key={key}
                className={clsx(
                    className,
                    isError && "validation-error",
                    isPredictiveSearch ? classes.predictiveSearch : clsx(classes.asyncSelect, "async-select"),
                )}
                ref={selectRef}
                selectRef={selectRef} // pass as props to CustomCreatableSelect
                placeholder={placeholder || label}
                onMenuScrollToBottom={onMenuScrollToBottom}
                components={customComponents}
                onInputChange={onInputChange}
                onMenuClose={onMenuClose}
                isMulti={isMulti}
                isDisabled={disabled}
                // fix for https://github.com/TruckITllc/truckit-frontend/issues/381
                filterOption={(options) => options}
                // defaultMenuIsOpen={true}
                onMenuOpen={onMenuOpen}
                noOptionsMessage={() => noOptionsMessage}
                isLoading={isLoading}
                startAdornment={startAdornment}
                label={label}
                backspaceRemovesValue={isClearable}
                isClearable={isClearable}
                createNewOption={createNewOption}
                selectAllOption={selectAllOption}
                _createNewHandler={_createNewHandler}
                _selectAllHandler={_selectAllHandler}
                classNamePrefix="_"
                maxMenuHeight={maxMenuHeight}
                isSearchable={isSearchable}
                onFocus={() => setFocusState(true)}
                onBlur={() => setFocusState(false)}
                {...addParams()}
            />
            {isError && <FieldNote fieldNote={error} isError={true} />}
            {fieldNote && <FieldNote fieldNote={fieldNote} />}
        </div>
    );
};

AutocompleteComponent.defaultProps = {
    noOptionsMessage: "No options",
    isClearable: false,
    defaultOptions: false,
    reloadList: false,
    maxMenuHeight: 300,
    isSearchable: true,
};

export const AUTOCOMPLETE_FORM_VIEW_CLASS = "form-view";

export const SELECT_ACTION_TYPE = "select-option";
export const REMOVE_ACTION_TYPE = "remove-value";

const AsyncAutocompleteComponent = (props) => {
    const classes = useStyles();

    return <AutocompleteComponent {...props} classes={classes} />;
};

export default AsyncAutocompleteComponent;
