import React from "react";

import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";

import EmptySubNav from "../../components/Navigation/EmptySubNav/EmptySubNav";
import SitesList from "./SitesList/SitesList";
import SiteDetails from "./SiteDetails/SiteDetails";

function Sites(props) {
    return (
        <>
            <EmptySubNav />
            <Container maxWidth="xl">
                <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                    <SitesList />
                    <SiteDetails />
                </Stack>
            </Container>
        </>
    );
}

export default Sites;
