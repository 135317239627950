import {
    WEBSOCKET_EVENT_TYPE_REQUEST_CLOSED,
    WEBSOCKET_EVENT_TYPE_REQUEST_CREATED,
    WEBSOCKET_EVENT_TYPE_REQUEST_FILL,
} from "../../../constants/global";

const notificationTitles = {
    [WEBSOCKET_EVENT_TYPE_REQUEST_CLOSED]: "Request |#{requestId}| was closed successfully",
    [WEBSOCKET_EVENT_TYPE_REQUEST_CREATED]:
        "New request |#{requestId}| created by {firstName} {lastName} ({companyName})",
    [WEBSOCKET_EVENT_TYPE_REQUEST_FILL]: "Request |#{requestId}| filled by {firstName} {lastName} ({companyName})",
};

const getNotificationTitle = (event) => {
    const requestId = event.jobOrderId;

    return notificationTitles[event.type]
        .replace("{requestId}", requestId || "Unknown")
        .replace("{firstName}", event.firstName)
        .replace("{lastName}", event.lastName)
        .replace("{companyName}", event.companyName);
};

export const processRequestEvent = ({ event }) => {
    return {
        ...event,
        notificationTitle: getNotificationTitle(event),
    };
};
