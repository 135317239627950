import * as React from "react";
import moment from "moment";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { MobileDatePicker as MUIMobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";

const _format = "YYYY-MM-DD";

export default function MobileDatePicker({ handleDateChange, renderInput, input, meta }) {
    const onChange = (date, value) => {
        input.onChange(moment(date).format(_format));
        handleDateChange && handleDateChange(date, value);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack direction="row">
                <MUIMobileDatePicker
                    {...input}
                    renderInput={(props) => (renderInput && renderInput(props)) || <TextField {...props} />}
                    value={input.value}
                    onChange={onChange}
                />
            </Stack>
        </LocalizationProvider>
    );
}
