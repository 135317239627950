import React from "react";
import { FieldNote } from "../../index";

const HiddenInput = (props) => {
    const {
        input,
        meta: { error, touched },
        errorAlign,
    } = props;

    return (
        <div>
            <input {...input} type="hidden" />
            {error && touched && <FieldNote textAlign={errorAlign} isError={true} fieldNote={error} />}
        </div>
    );
};

HiddenInput.defaultProps = {
    errorAlign: "left",
};

export default HiddenInput;
