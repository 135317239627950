export const getValidCoordinates = (input) => {
    if (input) {
        const coordsArray = input.split(", ");
        if (coordsArray.length === 2 && coordsArray[0] && coordsArray[1]) {
            const lat = parseFloat(coordsArray[0]),
                lng = parseFloat(coordsArray[1]);
            if (lat && lng)
                return {
                    lat,
                    lng,
                };
        }
    }

    return null;
};

export const getSiteValue = (data) => ({
    value: data.id,
    label: `${data.name} - ${data.address}`,
    longitude: data.longitude,
    latitude: data.latitude,
});
