import { createSlice } from "@reduxjs/toolkit";
import { resetState } from "./actions";
import _ from "lodash";

const initialState = {
    myTeam: {
        data: null,
        meta: {
            loading: false,
            error: false,
            page: 1,
            count: 0,
            hasMore: true,
        },
        successMessage: null,
        activeOrder: "lastName,firstName",
        activeTab: 0,
    },
    unapprovedUsersCount: 0,

    myCompanies: {
        data: null,
        meta: {
            loading: false,
            error: false,
            page: 1,
            count: 0,
            hasMore: true,
        },
    },
};
export const myCompanySlice = createSlice({
    name: "myCompany",
    initialState,
    reducers: {
        requestMyTeam: (state) => {
            state.myTeam.meta.loading = true;
        },
        resultMyTeam: (state, { payload }) => {
            if (payload.error) {
                state.myTeam.meta.error = payload.message;
                state.myTeam.meta.loading = false;
            } else {
                const { data, meta } = payload;
                state.myTeam.meta = {
                    ...meta,
                    loading: false,
                    page: meta.page + 1,
                    hasMore: !(data.length < 20),
                };
                if (meta.page === 1) {
                    state.myTeam.data = data;
                } else {
                    state.myTeam.data.push(...data);
                }
            }
        },
        updateMyTeamSortingParam: (state, { payload }) => {
            const updatedOrderParam = payload;
            const activeTeamUsersOrder = state.myTeam.activeOrder;

            if (activeTeamUsersOrder && updatedOrderParam === activeTeamUsersOrder) {
                state.myTeam.activeOrder = `-${updatedOrderParam}`;
            } else {
                state.myTeam.activeOrder = updatedOrderParam;
            }
        },
        resetMyTeamSortingParam: (state) => {
            state.myTeam.activeOrder = initialState.myTeam.activeOrder;
        },
        refreshMyTeam: (state) => {
            state.myTeam.data = initialState.myTeam.data;
            state.myTeam.meta = initialState.myTeam.meta;
        },
        refreshMyTeamMeta: (state) => {
            state.myTeam.meta = initialState.myTeam.meta;
        },
        resetMyTeam: (state) => {
            state.myTeam = initialState.myTeam;
        },
        requestMyCompanies: (state) => {
            state.myCompanies.meta.loading = true;
        },
        resultMyCompanies: (state, { payload }) => {
            const myCompaniesData = state.myCompanies.data;
            if (payload.error) {
                state.myCompanies.meta.error = payload.message;
                state.myCompanies.meta.loading = false;
            } else {
                const { data, meta } = payload;

                // pagination with no page field in response meta
                const nextPage = _.isEmpty(myCompaniesData)
                    ? initialState.myCompanies.meta.page + 1
                    : state.myCompanies.meta.page + 1;

                state.myCompanies.meta = {
                    ...meta,
                    loading: false,
                    page: nextPage,
                    hasMore: !(data.length < 20),
                };
                if (_.isEmpty(myCompaniesData)) {
                    state.myCompanies.data = data;
                } else {
                    state.myCompanies.data.push(...data);
                }
            }
        },
        resetMyCompaniesState: (state) => {
            state.myCompanies = initialState.myCompanies;
        },
        updateMyTeamSuccessMessage: (state, { payload }) => {
            state.myTeam.successMessage = payload;
        },
        updateMyTeamActiveTab: (state, { payload }) => {
            state.myTeam.activeTab = payload;
        },
        updateUnapprovedUsersCount: (state, { payload }) => {
            state.unapprovedUsersCount = payload;
        },
    },
    extraReducers: (builder) => {
        return builder.addCase(resetState, () => initialState);
    },
});

const { actions, reducer } = myCompanySlice;
export { actions };

export const selectMyTeamData = (state) => state.myCompany.myTeam.data;
export const selectMyTeamActiveOrder = (state) => state.myCompany.myTeam.activeOrder;
export const selectMyTeamMeta = (state) => state.myCompany.myTeam.meta;
export const selectMyTeamSuccessMessage = (state) => state.myCompany.myTeam.successMessage;
export const selectMyTeamActiveTab = (state) => state.myCompany.myTeam.activeTab;
export const selectUnapprovedUsersCount = (state) => state.myCompany.unapprovedUsersCount;

export const selectMyCompaniesData = (state) => state.myCompany.myCompanies.data;
export const selectMyCompaniesMeta = (state) => state.myCompany.myCompanies.meta;

export default reducer;
