import React, { createContext, useContext } from "react";
import { reduxForm, Field, getFormValues } from "redux-form";
import { useSelector } from "react-redux";
import moment from "moment";

import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/material/styles";

import { useDebounce } from "../../hooks";
import RenderFilterSelectorField from "../../components/core/FilterSelector";
import DatePicker from "../../components/core/DatePicker";
import MUIMobileDatePicker from "../../components/core/MobileDatePicker";
import { SERVER_DATE_FORMAT } from "../../constants/global";
import { selectIfIsMobileDimensions } from "../../slices/utilsSlice";

const FiltersContext = createContext();

function useFiltersContext() {
    const context = useContext(FiltersContext);
    if (!context) {
        throw new Error(`Filters compound components cannot be rendered outside the Filters component`);
    }
    return context;
}

function Filters({ children, form, dispatch, change, request, reset, ...restProps }) {
    const values = useSelector((state) => getFormValues(form)(state));

    const handleDateChange = (value, name) => {
        if (moment(value).isValid()) {
            const startDate = moment(values.startDate).format(SERVER_DATE_FORMAT);
            const endDate = moment(values.endDate).format(SERVER_DATE_FORMAT);
            if (name === "endDate" && moment(value).isBefore(startDate)) {
                dispatch(change("startDate", moment(value).format(SERVER_DATE_FORMAT)));
            }
            if (name === "startDate" && moment(value).isAfter(endDate)) {
                dispatch(change("endDate", moment(value).format(SERVER_DATE_FORMAT)));
            }
            request && request();
        }
    };

    const createRequest = (changeFormValues) => () => {
        changeFormValues && changeFormValues();
        request && request();
    };

    const handleTextChange = useDebounce(createRequest(), 500);

    const resetFilters = createRequest(reset);

    const clearSearchValue = () => change("keywords", "");
    const resetSearch = createRequest(clearSearchValue);

    const clearFiltersValue = () => change("filters", []);
    const clearFilters = createRequest(clearFiltersValue);

    return (
        <FiltersContext.Provider
            value={{
                values,
                request,
                clearSearchValue,
                handleTextChange,
                resetFilters,
                resetSearch,
                clearFilters,
                handleDateChange,
            }}
        >
            <LocalizationProvider dateAdapter={AdapterMoment}>{children}</LocalizationProvider>
        </FiltersContext.Provider>
    );
}

const StyledTextField = styled(TextField)(({ theme }) => ({
    [theme.breakpoints.down(1190)]: {
        width: "100%",
    },
    ".MuiInputBase-input": { padding: "7px 0px 7px 7px" },
    ".MuiSvgIcon-root": { width: 20 },
}));

const RenderTextField = ({ handleTextChange, input, meta, ...restProps }) => {
    return (
        <StyledTextField
            {...input}
            placeholder="Type to search"
            variant="outlined"
            onChange={(e) => {
                input.onChange(e);
                handleTextChange && handleTextChange();
            }}
            {...restProps}
        />
    );
};

const ClearSearhButtonStyled = styled(IconButton)(() => ({
    width: 25,
    height: 25,
}));

const ClearSearchButton = ({ onClick, value }) =>
    value && (
        <ClearSearhButtonStyled onClick={onClick}>
            <CloseIcon />
        </ClearSearhButtonStyled>
    );

function SearchField(props) {
    const { resetSearch, handleTextChange, values } = useFiltersContext();
    return (
        <Field
            name="keywords"
            handleTextChange={handleTextChange}
            component={RenderTextField}
            InputProps={{
                endAdornment: <ClearSearchButton onClick={resetSearch} value={values?.keywords} />,
            }}
        />
    );
}

function RefreshButton(props) {
    const { resetFilters } = useFiltersContext();
    return (
        <Tooltip title="Reset to default filter">
            <IconButton onClick={resetFilters}>
                <RefreshIcon />
            </IconButton>
        </Tooltip>
    );
}

function FilterSelector({ filters, defaultFilters }) {
    const { request, clearFilters } = useFiltersContext();
    return (
        <Field
            name="filters"
            request={request}
            clear={clearFilters}
            filters={filters}
            component={RenderFilterSelectorField}
        />
    );
}

const StyledDateInput = styled(TextField)(({ isMobile }) => ({
    width: "100%",
    ".MuiInputBase-input": { padding: "7px 0px 7px 7px" },
    ".MuiSvgIcon-root": { width: 20 },
}));

function DateField({ name }) {
    const { handleDateChange } = useFiltersContext();
    const isWebMode = useMediaQuery("(min-width:950px)");
    const isMobile = useSelector(selectIfIsMobileDimensions);

    return (
        <Field
            name={name}
            handleDateChange={handleDateChange}
            component={isWebMode ? DatePicker : MUIMobileDatePicker}
            renderInput={(props) => <StyledDateInput isMobile={isMobile} {...props} />}
        />
    );
}

Filters.SearchField = SearchField;
Filters.RefreshButton = RefreshButton;
Filters.FilterSelector = FilterSelector;
Filters.DateField = DateField;

export default reduxForm({
    destroyOnUnmount: false,
})(Filters);
