import _ from "lodash";
import moment from "moment";

export const convertDateToUserTimeZone = (date, timezone, format = null) => {
    let userDate;
    date = _.isDate(date) ? date : new Date(date);

    if (timezone) {
        userDate = moment().tz(timezone);
    } else {
        userDate = moment().tz();
    }
    userDate.milliseconds(1);
    userDate.seconds(date.getSeconds());
    userDate.minutes(date.getMinutes());
    userDate.hours(date.getHours());
    userDate.year(date.getFullYear());
    userDate.date(1);
    userDate.month(date.getMonth());
    userDate.date(date.getDate());

    return userDate.format(format);
};

export const convertTimeToJsFormat = (date, timezone) => {
    let utc;
    let newDate;
    let monentDate = moment(date);
    if (timezone) {
        utc = monentDate.tz(timezone);
    } else {
        utc = monentDate.tz("utc");
    }

    utc.utcOffset(0, true);

    newDate = new Date();
    newDate.setMilliseconds(1);
    newDate.setSeconds(utc.seconds());
    newDate.setMinutes(utc.minutes());
    newDate.setHours(utc.hours());
    newDate.setFullYear(utc.year());
    newDate.setDate(1);
    newDate.setMonth(utc.month());
    newDate.setDate(utc.date());

    return newDate;
};
