import React, { useEffect } from "react";
import clsx from "clsx";
import _ from "lodash";

import Loader, { fixedLoaderStyles } from "../../../components/core/loader";
import { COMPANY_TYPES, LOADER_WHITE_OVERLAY } from "../../../constants/global";
import TableWithStickyHeader from "../../../components/core/tables/TableWithStickyHeader";
import { useActions } from "../../../hooks";
import { useSelector } from "react-redux";
import { selectMyCompaniesData, selectMyCompaniesMeta } from "../../../slices/myCompanySlice";
import ErrorMessage from "../../../components/core/messages/ErrorMessage";

const MyCompaniesList = (props) => {
    const { getMyCompanies, resetMyCompaniesState } = useActions();
    const myCompanies = useSelector(selectMyCompaniesData);
    const { loading, error } = useSelector(selectMyCompaniesMeta);

    const getTableData = () => {
        const rows = myCompanies.map((company, index) => {
            const { id, name, type } = company;

            return {
                index,
                id,
                name,
                type: COMPANY_TYPES[type],
            };
        });
        let columns = [
            {
                id: "id",
                label: "ID",
                // sortParam: "id"
            },
            {
                id: "name",
                label: "NAME",
                // sortParam: "name"
            },
        ];

        return { rows: rows, columns: columns };
    };

    useEffect(() => {
        getMyCompanies();

        return () => {
            resetMyCompaniesState();
        };
    }, []);

    return (
        <React.Fragment>
            <div className={clsx(loading && `${LOADER_WHITE_OVERLAY}`, _.isEmpty(myCompanies) && "-min-height")}>
                {loading && <Loader style={fixedLoaderStyles()} />}
                {!_.isEmpty(myCompanies) && (
                    <TableWithStickyHeader
                        rows={getTableData().rows}
                        fetchMore={getMyCompanies}
                        columns={getTableData().columns}
                        // handleSort={handleTeamUsersSort}
                        // activeOrderParam={activeTeamUsersOrder}
                    />
                )}
            </div>
            {_.isEmpty(myCompanies) && !loading && (
                <React.Fragment>
                    <br />
                    <h1 className="--text-center">No companies match your search / filter parameters.</h1>
                    <br />
                </React.Fragment>
            )}
            <ErrorMessage error={error} onClose={resetMyCompaniesState} />
        </React.Fragment>
    );
};

export default MyCompaniesList;
