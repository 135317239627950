/* global google */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import clsx from "clsx";
import { compose, withProps } from "recompose";
import { withScriptjs } from "react-google-maps";
import { MAP } from "react-google-maps/lib/constants";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { CREATE_SITE_FORM } from "../../../constants/forms";
import { GOOGLE_MAPS_URL } from "../../../constants";
import TextInputComponent from "../../../components/core/form/TextInput";
import { Validation } from "../../../utils/validation";
import AutocompleteAddress from "../../../components/core/form/AutocompleteAddress/AutocompleteAddress";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
} from "../../../components/core/form/AsyncAutocompleteComponent";
import { PrimaryButton, SecondaryButton } from "../../../components";
import { LOADER_WHITE_OVERLAY, TRUCK_IT_COMPANY_ID } from "../../../constants/global";
import { LOCATION_TYPES } from "../../../constants/sites";
import { ButtonsGroup } from "../../../components/core/buttons/ButtonsGroup/buttonsGroup";
import Loader from "../../../components/core/loader";
import ErrorMessage from "../../../components/core/messages/ErrorMessage";
import CreateSiteFormMap from "./CreateSiteFormMap";
import { loadCurrentCoordinates } from "../../../helpers/location";
import { getValidCoordinates } from "../../../helpers/sites";
import { PROCESS_SERVER_ERROR } from "../../../helpers/global";
import { createSite } from "../../../api/sites";

const useStyles = makeStyles(() => ({
    siteForm: {
        backgroundColor: "#fff",
        padding: 24,
        "& .title": { textAlign: "center" },
    },
}));

const CreateSiteForm = compose(
    withProps({
        googleMapURL: GOOGLE_MAPS_URL,
        loadingElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
)((props) => {
    const {
        closeModal,
        defaultCoordinates,
        handleSubmit,
        setFieldValue,
        formValues: { id, coordinates, company },
        createSiteHandler,
        fieldName,
        createForDropdown,
        handleCloseModal,
        onSuccess,
    } = props;
    const classes = useStyles();
    const geocoder = new google.maps.Geocoder();
    const [plService, setPlacesService] = useState();
    let placesService;
    const [map, setMap] = useState();
    const [isLoading, setLoading] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [coordinatesChanged, setCoordinatesChanged] = useState(false);
    const [error, setError] = useState(null);

    const _onMapClick = (event, map) => {
        setCurrentLocation(event.latLng);
        geocoder.geocode({ location: event.latLng }, (result, status) => {
            if (status === "OK" && !_.isEmpty(result))
                _changeLocation(
                    {
                        placeId: result[0].place_id,
                        lat: event.latLng.lat(),
                        lng: event.latLng.lng(),
                    },
                    map,
                    false,
                    false,
                );
        });
    };

    const _changeLocation = (
        { placeId, lat, lng },
        map,
        centerMap = true,
        changeCoords = true,
        changeLocation = true,
    ) => {
        placesService = placesService || plService;
        if (placeId && map && placesService) {
            placesService.getDetails({ placeId: placeId }, (placeDetails, status) => {
                if (status === "OK") {
                    setFieldValue("address", {
                        value: 1,
                        label: placeDetails.formatted_address || null,
                    });

                    changeLocation &&
                        setFieldValue(
                            "coordinates",
                            `${lat || placeDetails.geometry.location.lat()}, ${
                                lng || placeDetails.geometry.location.lng()
                            }`,
                        );

                    changeCoords && setCurrentLocation(placeDetails.geometry.location);

                    if (centerMap) map.setCenter(placeDetails.geometry.location);

                    if (placeDetails.address_components)
                        placeDetails.address_components.forEach((component) => {
                            const postal_index = component.types.indexOf("postal_code");
                            const locality_index = component.types.indexOf("locality");

                            if (postal_index !== -1) setFieldValue("postalCode", component.long_name);

                            if (locality_index !== -1) setFieldValue("city", component.long_name);
                        });
                }
            });
        }
    };

    const loadLocationTypes = () => {
        const results = LOCATION_TYPES.map((type, index) => ({
            value: index,
            label: type,
        }));

        return Promise.resolve(results).then(() => {
            return {
                options: results,
                hasMore: false,
                page: 1,
            };
        });
    };

    const onSubmit = (values) => {
        const { name, address, city, coordinates, notes, locationType, postalCode } = values;

        if (coordinatesChanged) {
            setError(["Use must click 'Proceed' button after coordinate value change."]);

            return;
        }

        const validatedCoordinates = getValidCoordinates(coordinates);

        let dataForSave = {
            name: name,
            address: address.label,
            latitude: validatedCoordinates.lat,
            longitude: validatedCoordinates.lng,
            notes: notes || undefined,
            cityName: city,
            postalCode: postalCode || undefined,
            type: locationType.label.toLowerCase(),
        };

        if (values.id) dataForSave.parentSite = null;

        setError(null);
        setLoading(true);

        return handleSave(values, dataForSave);
    };

    const handleSave = (values, dataForSave) => {
        const data = { ...dataForSave };

        const handleSave = createSite(data);

        return handleSave
            .then((response) => {
                if (createForDropdown) {
                    closeModal();
                    handleCloseModal && handleCloseModal()
                    setLoading(false);
                    createSiteHandler && createSiteHandler(response.data, fieldName);
                }
            })
            .catch((errorResponse) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(errorResponse));
            });
    };

    const onCoordinatesChange = () => {
        const location = getValidCoordinates(coordinates);
        setCoordinatesChanged(false);
        if (location) {
            geocoder.geocode(
                {
                    location: location,
                },
                (result, status) => {
                    if (status === "OK" && !_.isEmpty(result)) {
                        _changeLocation({ placeId: result[0].place_id }, map.context[MAP]);
                    }
                },
            );

            return;
        }
        setError(["Invalid coordinates format"]);
    };

    const onAddressChange = (input) => {
        if (input) {
            _changeLocation({ placeId: input.placeId }, map.context[MAP], true, true, true);
        }
    };

    const onMessageClose = () => {
        setError(null);
    };

    const isDisabledToggle = company && company.value !== TRUCK_IT_COMPANY_ID;

    useEffect(() => {
        isDisabledToggle && setFieldValue("isPublic", false);
    }, [company]);

    useEffect(() => {
        if (map) {
            placesService = new google.maps.places.PlacesService(map.context[MAP]);
            setPlacesService(placesService);
            if (!_.isEmpty(defaultCoordinates)) {
                setCurrentLocation(defaultCoordinates);
                map.context[MAP].setCenter(defaultCoordinates);
            } else {
                loadCurrentCoordinates().then((response) => {
                    map.context[MAP].setCenter({
                        lat: response.latitude,
                        lng: response.longitude,
                    });
                });
            }

            google.maps.event.addDomListener(map.context[MAP], "click", (event) => {
                if (map) {
                    _onMapClick(event, map.context[MAP]);
                }
            });
        }
    }, [map]);

    return (
        <div className={clsx(classes.siteForm, isLoading && LOADER_WHITE_OVERLAY)}>
            <h2 className="title">{id ? "Edit Site" : "Create Site"}</h2>
            <form
                noValidate={true}
                onSubmit={(e) => {
                    e.stopPropagation();
                    handleSubmit(onSubmit)(e);
                }}
            >
                <CreateSiteFormMap
                    currentLocation={currentLocation}
                    setMap={setMap}
                    googleMapURL={GOOGLE_MAPS_URL}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: "30vh" }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
                <br />
                <Field
                    type="text"
                    name="name"
                    validate={[Validation.required, Validation.minLength4, Validation.noSpecialSymbols]}
                    label="Site Name"
                    fullWidth={true}
                    needShowEndAdornment={false}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <AutocompleteAddress onAddressChange={onAddressChange} className={AUTOCOMPLETE_FORM_VIEW_CLASS} />
                <br />
                <Field
                    type="text"
                    name="city"
                    validate={[Validation.noSpecialSymbols]}
                    label="City"
                    fullWidth={true}
                    needShowEndAdornment={false}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <Field
                    type="text"
                    validate={[Validation.noSpecialSymbols]}
                    name="postalCode"
                    label="Postal Code"
                    fullWidth={true}
                    needShowEndAdornment={false}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <Grid container component="div" justifyContent="space-between">
                    <Grid item xs={8}>
                        <Field
                            type="text"
                            name="coordinates"
                            validate={[Validation.required, Validation.coordinates]}
                            label="Coordinates"
                            placeholder="Latitude, Longitude"
                            fullWidth={true}
                            onChange={() => setCoordinatesChanged(true)}
                            needShowEndAdornment={true}
                            fieldNote="Coordinates should be separated by a comma"
                            component={TextInputComponent}
                        />
                    </Grid>
                    <SecondaryButton onClick={onCoordinatesChange}>Proceed</SecondaryButton>
                </Grid>
                <br />
                <br />
                <Field
                    type="text"
                    multiline={true}
                    name="notes"
                    rows={3}
                    label="Site Notes"
                    component={TextInputComponent}
                />
                <br />
                <br />
                <Field
                    name="locationType"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    validate={[Validation.required]}
                    label="Site Type"
                    loadOptions={loadLocationTypes}
                    component={AsyncAutocompleteComponent}
                />
                <br />
                <ButtonsGroup>
                    <SecondaryButton onClick={closeModal || handleCloseModal}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </ButtonsGroup>
            </form>
            {isLoading && <Loader />}
            <ErrorMessage error={error} onClose={onMessageClose} />
        </div>
    );
});

CreateSiteForm.propTypes = {
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    defaultCoordinates: PropTypes.object,
    error: PropTypes.any,
    formValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    createSiteHandler: PropTypes.func,
    fieldName: PropTypes.string,
    createForDropdown: PropTypes.bool,
};

CreateSiteForm.defaultPops = {
    createForDropdown: false,
};

export default compose(
    reduxForm({
        form: CREATE_SITE_FORM,
    }),
    connect(
        (state) => {
            const formSelector = formValueSelector(CREATE_SITE_FORM);

            return {
                formValues: formSelector(state, "alertZone", "isSubSite", "id", "coordinates", "company"),
            };
        },
        (dispatch) => ({
            setFieldValue: (field, value) => dispatch(change(CREATE_SITE_FORM, field, value)),
        }),
    ),
)(CreateSiteForm);
