/*global google */
import _ from "lodash";

import { PHONE_USA_CODE } from "../constants/global";
import {
    ALERT_ZONE_TYPE_CIRCLE,
    ALERT_ZONE_TYPE_POLYGON,
    alertZoneFillOpacity,
    alertZoneStrokeOpacity,
    alertZoneStrokeWeight,
    getAlertZoneType,
} from "../constants/sites";

export const preparePhone = (phone) => {
    if (!phone) {
        return undefined;
    }

    phone = phone.split(" ").join("");

    return phone.startsWith(PHONE_USA_CODE) ? phone : `${PHONE_USA_CODE}${phone.replace(/-/g, "")}`;
};

const processObjectErrorMessage = (error) => {
    let message = "\n";
    for (const [key, value] of Object.entries(error)) {
        message = `${message} ${key} ${value} \n`;
    }

    return message.toLocaleLowerCase();
};

export const PROCESS_SERVER_ERROR = (error, customMessage = "Something Wrong") => {
    if (_.isEmpty(error)) {
        return null;
    }

    return _.isString(error.message)
        ? [error.message]
        : _.isObject(error.message)
        ? [processObjectErrorMessage(error.message)]
        : [customMessage];
};

// export const getFullCompanyAddress = (values) =>
//     values.secondaryAddress
//         ? `${values.streetAddress} ${values.secondaryAddress}, ${values.city}, ${values.state.label}, ${values.zipCode}`
//         : `${values.streetAddress}, ${values.city}, ${values.state.label}, ${values.zipCode}`;

export const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";

    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
};

export const getFullCompanyAddress = (values) =>
    values.secondaryAddress
        ? `${values.streetAddress} ${values.secondaryAddress}, ${values.city}, ${values.zipCode}`
        : `${values.streetAddress}, ${values.city}, ${values.zipCode}`;

export const getAlertZoneOptions = () => {
    const color = getRandomColor();

    return {
        color: color,
        strokeOpacity: alertZoneStrokeOpacity,
        fillOpacity: alertZoneFillOpacity,
        strokeWeight: alertZoneStrokeWeight,
        fillColor: color,
    };
};

export const parseAlertZones = (alertZones) => {
    const circleZones = [],
        polygonZones = [];

    alertZones.forEach((alertZone) => {
        const options = getAlertZoneOptions();

        if (getAlertZoneType(alertZone.type) === ALERT_ZONE_TYPE_CIRCLE) {
            circleZones.push({
                options: options,
                center: {
                    lat: alertZone.coordinates[0],
                    lng: alertZone.coordinates[1],
                },
                id: alertZone.id,
                radius: alertZone.radius,
                name: alertZone.name,
                siteId: alertZone.siteId,
                type: alertZone.type,
            });
        } else if (getAlertZoneType(alertZone.type) === ALERT_ZONE_TYPE_POLYGON)
            polygonZones.push({
                options: options,
                id: alertZone.id,
                paths: alertZone.coordinates.map((coords) => ({
                    lat: coords[0],
                    lng: coords[1],
                })),
                name: alertZone.name,
                siteId: alertZone.siteId,
                type: alertZone.type,
            });
    });

    return [circleZones, polygonZones];
};

export const polygonCenter = (paths) => {
    const poly = new google.maps.Polygon({
        paths: paths,
    });

    var lowx,
        highx,
        lowy,
        highy,
        lats = [],
        lngs = [],
        vertices = poly.getPath();

    for (var i = 0; i < vertices.length; i++) {
        lngs.push(vertices.getAt(i).lng());
        lats.push(vertices.getAt(i).lat());
    }

    lats.sort();
    lngs.sort();
    lowx = lats[0];
    highx = lats[vertices.length - 1];
    lowy = lngs[0];
    highy = lngs[vertices.length - 1];
    const center_x = lowx + (highx - lowx) / 2;
    const center_y = lowy + (highy - lowy) / 2;

    return new google.maps.LatLng(center_x, center_y);
};
