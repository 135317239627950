import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

import { PrimaryButton } from "../../../components";
import SecondaryButton from "../../../components/core/buttons/SecondaryButton";
import ConfirmationModal from "../../../components/core/modals/ConfirmationModal";
import { useActions } from "../../../hooks";
import AppModal from "../../../components/core/modals/AppModal";
import ApproveSignUpFormWithCompanyUpdating from "./ApproveSignUpFormWithCompanyUpdating";
import { APPROVE_SIGN_UP_FROM, CREATE_SITE_FORM } from "../../../constants/forms";

const StyledButtonsGroup = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",

    "& button": {
        padding: 0,
        height: 25,
        width: 40,
        fontSize: "12px",
    },
}));

export const DECISION_KEY = "decision";
export const DECISION_APPROVE = "approve";
export const DECISION_REJECT = "reject";

const SignUpApprovalButtons = ({ userId, registrationCompanyName }) => {
    const { approveUserSignUp, rejectUserSignUp } = useActions();
    const [approveModalShow, setApproveModalShow] = useState(false);
    const [rejectModalShow, setRejectModalShow] = useState(false);

    const handleApproveModalClose = () => {
        setApproveModalShow(false);
    };
    const handleRejectClick = () => {
        setRejectModalShow(true);
    };
    const handleApproveClick = () => {
        setApproveModalShow(true);
    };

    const handleUserReject = async () => {
        await rejectUserSignUp(userId);
        setRejectModalShow(false);
    };
    const handleUserApprove = async () => {
        await approveUserSignUp(userId);
        setApproveModalShow(false);
    };

    return (
        <>
            <StyledButtonsGroup>
                <Grid container justifyContent="space-around" spacing={0.7} maxWidth={180}>
                    <Grid item>
                        <PrimaryButton type="submit" onClick={handleApproveClick}>
                            approve
                        </PrimaryButton>
                    </Grid>
                    <Grid item>
                        <SecondaryButton onClick={handleRejectClick}>reject</SecondaryButton>
                    </Grid>
                </Grid>
            </StyledButtonsGroup>
            {!!registrationCompanyName ? (
                <AppModal form={APPROVE_SIGN_UP_FROM} closeModal={handleApproveModalClose} isOpen={approveModalShow}>
                    <ApproveSignUpFormWithCompanyUpdating
                        userId={userId}
                        newCompanyUserModalIsOpen={approveModalShow}
                        closeModal={handleApproveModalClose}
                        registrationCompanyName={registrationCompanyName}
                    />
                </AppModal>
            ) : (
                <ConfirmationModal
                    isOpen={approveModalShow}
                    question={"Are you sure you want to approve user sign up"}
                    yesHandler={handleUserApprove}
                    noHandler={handleApproveModalClose}
                />
            )}

            <ConfirmationModal
                isOpen={rejectModalShow}
                question={"Are you sure you want to reject user sign up"}
                yesHandler={handleUserReject}
                noHandler={() => setRejectModalShow(false)}
            />
        </>
    );
};

export default SignUpApprovalButtons;
