import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Navigation } from "../index";
import * as routes from "../../routes/routesStrings";
import { RequestDetails, Requests, SiteDetails, Sites } from "../../pages";
import { myCompanyRoute, ROUTE_MY_COMPANY, ROUTE_REQUESTS } from "../../routes/routesStrings";
import MyCompany from "../../pages/MyCompany/MyCompany";
import MyTeam from "../../pages/MyCompany/MyTeam/MyTeam";
import Companies from "../../pages/MyCompany/MyCompanies/MyCompanies";
import TermsDialogWrapper from "../Terms/TermsDialogWrapper";
import { useIsWrongCompanyPortal } from "../../hooks/useIsWrongCompanyPortal";

const AuthenticatedRoutes = () => {
    useIsWrongCompanyPortal();

    return (
        <>
            <Navigation />
            <Routes>
                <Route path={routes.ROUTE_REQUESTS} element={<Requests />} />
                <Route path={routes.ROUTE_PROJECTS_AND_SITES.SITES} element={<Sites />} />
                <Route path={routes.ROUTE_REQUEST_DETAILS} element={<RequestDetails />} />
                <Route path={routes.ROUTE_SITE_DETAILS} element={<SiteDetails />} />
                <Route exext path={myCompanyRoute} element={<MyCompany />}>
                    <Route path={ROUTE_MY_COMPANY.MY_TEAM} element={<MyTeam />} />
                    <Route path={ROUTE_MY_COMPANY.ALL_COMPANIES} element={<Companies />} />
                </Route>
                <Route path="*" element={<Navigate to={ROUTE_REQUESTS} />} />
            </Routes>
            <TermsDialogWrapper />
        </>
    );
};

export default AuthenticatedRoutes;
