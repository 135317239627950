import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    fieldNote: {
        fontSize: 10,
        color: theme.palette.secondary.superDark,
        marginLeft: 14,
        marginTop: 3,

        "&.error": {
            color: theme.palette.general.darkishRed,
        },
    },
}));

const Component = (props) => {
    const { fieldNote, isError, textAlign, classes, className } = props;

    return (
        <div>
            {fieldNote && (
                <Typography align={textAlign} className={clsx(classes.fieldNote, isError && "error", className)}>
                    {fieldNote}
                </Typography>
            )}
        </div>
    );
};

Component.defaultProps = {
    isError: false,
    textAlign: "left",
};

const FieldNote = (props) => {
    const classes = useStyles();

    return <Component {...props} classes={classes} />;
};

export default FieldNote;
