export const GLOBAL_STYLES = (theme) => ({
    "::-webkit-scrollbar": {
        width: 6,
    },
    "::-webkit-scrollbar-track": {
        borderRadius: theme.shape.borderRadius * 2,
    },
    "::-webkit-scrollbar-thumb": {
        borderRadius: theme.shape.borderRadius * 2,
        backgroundColor: "#6e6e6e",
    },
});

export const INPUT_STYLES = (theme) => ({
    "::-webkit-input-placeholder, :-ms-input-placeholder, ::placeholder": {
        color: theme.palette.secondary.dark,
        fontSize: 16,
    },
    input: {
        fontFamily: theme.typography.fontFamily,
    },
    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
    },
    "input[type=number]": {
        "-moz-appearance": "textfield",
    },
});
