import React from "react";
import { Validation } from "../../../utils/validation";
import TextInputComponent from "../../../components/core/form/TextInput";
import { Field } from "redux-form";
import { Grid } from "@mui/material";
import EmailField from "../../../components/core/form/EmailField/emailField";
import PhoneInput from "../../../components/core/form/PhoneInput/PhoneInput";
import PasswordField from "../../../components/core/form/PasswordField";

const UserInfoForm = () => {
    return (
        <>
            <br />
            <Grid container justify={"center"} alignItems={"center"}>
                <Grid item xs={12}>
                    <Field
                        name="firstName"
                        validate={[Validation.required, Validation.noSpecialSymbols]}
                        type="text"
                        label="First Name"
                        component={TextInputComponent}
                    />
                    <br />
                    <br />
                    <Field
                        name="lastName"
                        validate={[Validation.required, Validation.noSpecialSymbols]}
                        type="text"
                        label="Last Name"
                        component={TextInputComponent}
                    />
                    <br />
                    <br />
                    <PhoneInput isRequired />
                    <br />
                    <br />
                    <EmailField isRequired />
                    <br />
                    <br />
                    <PasswordField />
                    <br />
                    <br />
                    <Field
                        name="passwordConfirm"
                        validate={[Validation.required, Validation.password]}
                        type="password"
                        label="Password Again"
                        component={TextInputComponent}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default UserInfoForm;
