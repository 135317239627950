import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { IconButton, InputAdornment, TextField } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import ErrorIcon from "@mui/icons-material/Error";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import FieldNote from "../FieldNote";

const useStyles = makeStyles((theme) => ({
    textInput: {
        "&.-disabled input": {
            cursor: "not-allowed",
            opacity: 0.4,
            color: theme.palette.secondary.superLight,
        },

        "& .input-error": {
            color: theme.palette.general.darkishRed,
        },
        "& .input-valid": {
            color: theme.palette.general.green,
        },

        "& .fieldset, input, .MuiInputBase-formControl": {
            backgroundColor: "#fff",
            fontSize: 16,
        },

        "& fieldset legend span": {
            display: "block",
            padding: 0,
            margin: "0 8px",
        },

        "&.job-order-input-field": {
            width: 100,

            "&.-disabled input": {
                cursor: "not-allowed",
                opacity: 0.4,
                borderBottom: `2px solid ${theme.palette.secondary.dark}`,
            },

            "&:hover input": {
                backgroundColor: theme.palette.ternary.superLight,
                borderBottom: `2px solid ${theme.palette.secondary.dark}`,
            },

            "& fieldset": {
                border: "none",
            },

            "& input": {
                padding: 5,
                backgroundColor: theme.palette.ternary.superLight,
                textAlign: "right",
                color: theme.palette.secondary.dark,
                fontWeight: theme.typography.fontWeightMedium,
                transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                borderBottom: `2px solid ${theme.palette.primary.main}`,

                "&:placeholder-shown": {
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                },

                "&:focus": {
                    borderBottom: `2px solid ${theme.palette.general.green}`,
                },
            },
        },
        "&.radius-input-field": {
            position: "relative",
            bottom: "4px",
            marginRight: "5px",
            "& label, & label.Mui-focused, & label.Mui-error": {
                fontSize: 16,
                color: theme.palette.secondary.superDark,
                fontWeight: theme.typography.fontWeightMedium,
                backgroundColor: "transparent",
                paddingRight: 5,
            },
            "& input": {
                padding: 7,
                verticalAlign: "1px",
                color: theme.palette.secondary.dark,
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    },
    textFieldsWrapper: {
        border: 0,
        margin: 0,
        display: "inline-flex",
        padding: 0,
        position: "relative",
        minWidth: 0,
        flexDirection: "column",
        verticalAlign: "top",

        "&.-full-width": {
            width: "100%",
        },
    },
}));

export const DefaultTextStyles = (theme) => ({
    "& .Mui-disabled": {
        cursor: "not-allowed",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.border.primary,
        borderWidth: 2,
    },
    "& input:disabled": {
        color: theme.palette.secondary.dark,
    },
    "& .MuiOutlinedInput-input": {
        padding: "18px 14px",
    },
    "& .MuiOutlinedInput-inputAdornedStart": {
        paddingLeft: 7,
    },
    "& .Mui-disabled .MuiOutlinedInput-notchedOutline legend": {
        backgroundColor: "#fff",
    },
    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.border.primary,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.general.darkishRed,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.general.green,
    },
    "& label, & label.Mui-focused, & label.Mui-error": {
        fontSize: 16,
        color: theme.palette.secondary.superDark,
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: "#fff",
        paddingRight: 5,
    },
    "& label.Mui-disabled": {
        color: theme.palette.secondary.superDark,
    },
    "& .MuiFormHelperText-root.Mui-error": {
        fontSize: 10,
        color: theme.palette.general.darkishRed,
        marginTop: 3,
    },
    "& ::-webkit-input-placeholder, & :-ms-input-placeholder, & ::placeholder": {
        color: theme.palette.secondary.dark,
        fontSize: 16,
    },
    "& .MuiInputAdornment-positionEnd p": {
        fontSize: 14,
        color: theme.palette.secondary.dark,
        whiteSpace: "nowrap",
    },
    "& .MuiInputAdornment-positionStart p": {
        fontSize: 16,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.secondary.dark,
    },
});

const CustomTextField = withStyles((theme) => ({
    root: { ...DefaultTextStyles(theme) },
}))(TextField);

const TextInputComponent = (props) => {
    const classes = useStyles();
    const {
        label,
        type,
        variant,
        className,
        meta: { error, touched },
        input,
        fullWidth,
        multiline,
        placeholder,
        rows,
        fieldNote,
        autoFocus,
        disabled,
        endAdornment,
        startAdornment,
        needShowEndAdornment,
        onBlur,
        onChange,
        ref,
        id,
        errorClassName,
        shrink,
        readOnly,
    } = props;
    const isError = error && touched;
    const [values, setValues] = useState({
        showPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({ showPassword: !values.showPassword });
    };

    useEffect(() => {
        const numberField = document.getElementById(input.name);
        const listenWheelEvent = (e) => numberField.addEventListener("wheel", disableWheel, { passive: false });
        const doNotListenWheel = (e) => numberField.removeEventListener("wheel", disableWheel);
        const disableWheel = (e) => e.preventDefault();

        if (numberField) {
            numberField.addEventListener("focus", listenWheelEvent);
            numberField.addEventListener("blur", doNotListenWheel);
        }

        return () => {
            if (numberField) {
                numberField.removeEventListener("focus", listenWheelEvent);
                numberField.removeEventListener("blur", listenWheelEvent);
                numberField.removeEventListener("wheel", disableWheel);
            }
        };
    }, []);

    return (
        <div className={clsx(classes.textFieldsWrapper, "text-field-wrapper", fullWidth && "-full-width")}>
            <CustomTextField
                {...input}
                label={label}
                fullWidth={fullWidth}
                id={id || input.name}
                onFocus={input.onFocus}
                onBlur={input.onBlur}
                placeholder={placeholder}
                disabled={disabled}
                autoFocus={autoFocus}
                multiline={multiline}
                readOnly={readOnly}
                rows={rows}
                shrink={"false"}
                className={clsx(classes.textInput, className, disabled && "-disabled")}
                type={values.showPassword ? "text" : type}
                InputProps={{
                    readOnly: readOnly,
                    endAdornment: needShowEndAdornment
                        ? endAdornment ||
                          (!!isError && (
                              <InputAdornment position="end">
                                  <ErrorIcon className="input-error" />
                              </InputAdornment>
                          ))
                        : type === "password" && (
                              <InputAdornment position="end">
                                  <IconButton onClick={handleClickShowPassword} size="large">
                                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                              </InputAdornment>
                          ),
                    startAdornment: startAdornment,
                    onChange: (e) => {
                        input.onChange(e);
                        onChange && onChange(e);
                    },
                    onBlur: (e) => {
                        input.onBlur(e);
                        onBlur && onBlur(e);
                    },
                }}
                InputLabelProps={{
                    shrink: shrink,
                }}
                inputRef={ref}
                error={isError}
                variant={variant}
            />
            {isError && <FieldNote fieldNote={error} isError={true} className={errorClassName} />}
            {fieldNote && <FieldNote fieldNote={fieldNote} />}
        </div>
    );
};

TextInputComponent.defaultProps = {
    variant: "outlined",
    type: "text",
    needShowEndAdornment: false,
    autoFocus: false,
    adornmentIcon: <ErrorIcon className="input-error" />,
    disabled: false,
    fullWidth: true,
};

export default TextInputComponent;
