import {
    WEBSOCKET_EVENT_TYPE_AFFILIATED_COMPANY_USER_SIGN_UP,
    WEBSOCKET_EVENT_TYPE_REQUEST_CLOSED,
    WEBSOCKET_EVENT_TYPE_REQUEST_CREATED,
    WEBSOCKET_EVENT_TYPE_REQUEST_FILL,
    WEBSOCKET_EVENT_TYPE_UNKNOWN,
} from "../../constants/global";

export const getEventTypeFromDescription = (description) => {
    return (
        {
            // Job Requests
            close_job_request: WEBSOCKET_EVENT_TYPE_REQUEST_CLOSED,
            create_job_request: WEBSOCKET_EVENT_TYPE_REQUEST_CREATED,
            fill_request_job: WEBSOCKET_EVENT_TYPE_REQUEST_FILL,

            // affiliated company
            signup_to_affiliated_company: WEBSOCKET_EVENT_TYPE_AFFILIATED_COMPANY_USER_SIGN_UP,
        }[description] || WEBSOCKET_EVENT_TYPE_UNKNOWN
    );
};
