import React from "react";
import { GLOBAL_ENDPOINTS } from "../../../constants/endpoints/global";
import { Field } from "redux-form";
import CustomCheckbox from "../../../components/core/form/СustomCheckbox";
import HiddenInput from "../../../components/core/form/HiddenInput";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    termsAndConditions: {
        width: "100%",

        "& .iframe": {
            width: "100%",
        },
    },
}));

const TermsAndConditionsForm = () => {
    const classes = useStyles();

    return (
        <>
            <br />
            <div className={classes.termsAndConditions}>
                <div>
                    <div>
                        <iframe
                            title="Terms and Conditions"
                            src={GLOBAL_ENDPOINTS.TERMS_AND_CONDITIONS()}
                            height={300}
                            className="iframe"
                        />
                    </div>
                    <br />
                    <br />
                    <Field
                        name="acceptTermsAndConditions"
                        type="checkbox"
                        label="I accept these Terms and Conditions."
                        component={CustomCheckbox}
                    />
                    <Field name="thirdStepError" errorAlign="center" component={HiddenInput} />
                </div>
            </div>
        </>
    );
};

export default TermsAndConditionsForm;
