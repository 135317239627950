import { createSlice } from "@reduxjs/toolkit";

import { resetState } from "./actions";

const initialState = {
    data: null,
    meta: {
        loading: false,
        error: null,
        page: 1,
        count: 0,
        hasMore: true,
    },
    currentSite: {
        data: null,
        meta: {
            loading: false,
            error: null,
        },
    },
    newZoneData: {
        markers: [],
        polylinePath: [],
        createdPolygon: {},
    },
};
export const sitesSlice = createSlice({
    name: "sites",
    initialState,
    reducers: {
        requestSites: (state) => {
            state.meta.loading = true;
        },
        resultSites: (state, { payload }) => {
            if (payload.error) {
                state.meta.error = payload.message;
                state.meta.loading = false;
            } else {
                const { data, meta } = payload;
                state.meta = {
                    ...meta,
                    loading: false,
                    page: meta.page + 1,
                    hasMore: !(data.length < 20),
                };
                if (meta.page === 1) {
                    state.data = data;
                } else {
                    state.data.push(...data);
                }
            }
        },
        refreshSites: (state) => {
            state.data = initialState.data;
            state.meta = initialState.meta;
        },
        requestCurrentSiteDetails: (state) => {
            state.currentSite.meta.loading = true;
        },
        resultCurrentSiteDetails: (state, { payload }) => {
            state.currentSite.data = payload;
            state.currentSite.meta.loading = false;
        },
        refreshCurrentSiteDetails: (state) => {
            state.currentSite = {
                ...initialState.currentSite,
                meta: {
                    ...initialState.currentSite.meta,
                    loading: true,
                },
            };
        },
        resetSiteDetails: (state) => {
            state.currentSite = initialState.currentSite;
        },
        setNewAlertZoneCoordinates: (state, action) => {
            state.newZoneData = action.payload;
        },
        initializeSites: (state) => {
            state.data = initialState.data;
            state.meta = initialState.meta;
            state.currentSite = initialState.currentSite;
            state.newZoneData = initialState.newZoneData;
        },
    },
    extraReducers: (builder) => {
        return builder.addCase(resetState, () => initialState);
    },
});

const actions = sitesSlice.actions;

export const selectSites = (state) => state.sites.data;
export const selectSitesMeta = (state) => state.sites.meta;
export const selectCurrentSite = (state) => state.sites.currentSite.data;
export const selectCurrentSiteMeta = (state) => state.sites.currentSite.meta;

export { actions };

export default sitesSlice.reducer;
