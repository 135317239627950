import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import { CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material";

import { MAIN_THEME } from "./styles/globalThemeConfig";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { GLOBAL_STYLES } from "./styles/globalStyles";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <CssBaseline />
            <ThemeProvider theme={MAIN_THEME}>
                <GlobalStyles styles={GLOBAL_STYLES} />
                <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                    <App />
                </SnackbarProvider>
            </ThemeProvider>
        </Provider>
    </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
