import * as React from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TocIcon from "@mui/icons-material/Toc";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import RoomIcon from "@mui/icons-material/Room";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import { useActions } from "../../../hooks";
import { getHoursQty, getProjectItemLabel } from "../../../utils";
import { UNIT_OF_MEASURE_BY_ID, UNIT_OF_MEASURE_HOUR } from "../../../constants/global";
import { SecondaryButton } from "../../../components";
import { TYPOGRAPHY_ICONS_SITE } from "../../../styles/icons";
import { SimpleLink } from "../../../components/core/link";
import Waypoint from "../../../components/core/Waypoint";
import UnitOfMeasure from "../../../components/core/UnitOfMeasure";
import { PROJECTS_AND_SITES_ROUTE, ROUTE_CREATE_REQUEST } from "../../../routes/routesStrings";

const PayloadCard = ({
    unitOfMeasure,
    filledQuantity,
    unlimited,
    totalQuantity,
    payload,
    pickUpSite,
    dropOffSites,
    request,
}) => {
    const siteIconsClasses = TYPOGRAPHY_ICONS_SITE();
    return (
        <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
                <Stack direction="row" alignItems="center">
                    <TocIcon />
                    <Typography variant="h3">
                        {getProjectItemLabel({
                            payload: payload && payload.name,
                            //  perUnitPrice: grantedAccessProject ? null : perUnitPrice,
                            unitOfMeasure: payload && payload.unitOfMeasure,
                        })}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
                <Stack direction="row" alignItems="center">
                    <Stack direction="row" alignItems="center">
                        <Typography variant="h3">
                            {unitOfMeasure === UNIT_OF_MEASURE_HOUR
                                ? filledQuantity && getHoursQty(filledQuantity)
                                : filledQuantity.toFixed(2)}
                            {"/"}
                        </Typography>
                    </Stack>
                    {unlimited ? (
                        <Stack direction="row" alignItems="center">
                            <AllInclusiveIcon />
                            <UnitOfMeasure variant="h3" value={UNIT_OF_MEASURE_BY_ID[unitOfMeasure]} />
                        </Stack>
                    ) : (
                        <Stack direction="row" alignItems="center">
                            <Typography variant="h3">
                                {unitOfMeasure === UNIT_OF_MEASURE_HOUR
                                    ? totalQuantity && getHoursQty(totalQuantity)
                                    : totalQuantity && totalQuantity.toFixed(2)}
                            </Typography>
                            {!(unitOfMeasure === UNIT_OF_MEASURE_HOUR) && (
                                <UnitOfMeasure variant="h3" value={UNIT_OF_MEASURE_BY_ID[unitOfMeasure]} />
                            )}
                        </Stack>
                    )}
                </Stack>
            </Grid>
            <Grid item xs={12} md={2}>
                {pickUpSite && (
                    <Stack direction="row" alignItems="center">
                        <RoomIcon className={clsx(siteIconsClasses.iconSite, "pick-up")} />
                        <SimpleLink to={PROJECTS_AND_SITES_ROUTE}>{pickUpSite.name}</SimpleLink>
                    </Stack>
                )}
                {dropOffSites.map((site) => {
                    return (
                        <Stack direction="row" alignItems="center">
                            <RoomIcon className={clsx(siteIconsClasses.iconSite, "drop-off")} />
                            <SimpleLink to={PROJECTS_AND_SITES_ROUTE}>{site.name}</SimpleLink>
                        </Stack>
                    );
                })}
            </Grid>
            <Grid item xs={12} md={3}>
                <SecondaryButton onClick={request} size={"small"}>
                    request
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

const ScrollableAncestor = () => <div id="end"></div>;

export default function Payloads({ poLiItems, projectName, projectId }) {
    const { setRequestFormInititialValues, getPayloads } = useActions();
    const navigate = useNavigate();
    const request = (payload) => {
        setRequestFormInititialValues({ ...payload, projectName, projectId });
        navigate(ROUTE_CREATE_REQUEST);
    };

    return (
        <Stack sx={{ width: "100%" }}>
            <Box sx={{ maxHeight: 400, overflowY: poLiItems ? "scroll" : "auto" }}>
                {poLiItems &&
                    poLiItems.map((item, index) => {
                        return (
                            <>
                                <PayloadCard {...item} request={() => request(item)} />
                                <Divider sx={{ my: 2 }} />
                                <Waypoint fetchMore={getPayloads} index={index} items={poLiItems} />
                            </>
                        );
                    })}
            </Box>
        </Stack>
    );
}
