import { ENV_MODE_PROD, ENV_MODE_TEST, IS_PROD_ENV, S3_PROD_ENV, S3_TEST_ENV } from "../index";
import { I80_TEST_HOSTNAME, I80_TEST_PORTAL_DATA } from "./I80Config/I80TestConfig";
import { I80_PROD_HOSTNAME, I80_PROD_PORTAL_DATA } from "./I80Config/I80ProdConfig";
import { TESTEGOR_TEST_HOSTNAME, TESTEGOR_TEST_PORTAL_DATA } from "./testegorConfig/testegorConfig";

const hostname = window.location.hostname;

const getTestCustomerData = (hostname) => {
    switch (hostname) {
        case "localhost":
            return I80_TEST_PORTAL_DATA;
        case I80_TEST_HOSTNAME:
            return I80_TEST_PORTAL_DATA;
        case TESTEGOR_TEST_HOSTNAME:
            return TESTEGOR_TEST_PORTAL_DATA;
        default:
            return I80_TEST_PORTAL_DATA;
    }
};

const getProdCustomerData = (hostname) => {
    switch (hostname) {
        case I80_PROD_HOSTNAME:
            return I80_PROD_PORTAL_DATA;
        default:
            return I80_PROD_PORTAL_DATA;
    }
};

const getCustomerData = (hostname) => {
    if (IS_PROD_ENV) {
        return getProdCustomerData(hostname);
    }

    return getTestCustomerData(hostname);
};

const customerData = getCustomerData(hostname);
const s3BaseUrl = `https://ti-customers.s3.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}`;

export const PARENT_COMPANY_ID = customerData.parentCompanyId;
export const PARENT_COMPANY_UUID = customerData.parentCompanyUUID;
export const TAB_TITLE = customerData.tabTitle;
export const CUSTOMER_ROLE_NAME = customerData.customerRole;

export const TERMS_AND_CONDITIONS_HTML = `${s3BaseUrl}/${PARENT_COMPANY_UUID}/terms-and-conditions/TermsAndConditions.html`;
export const LOGO_SVG = `${s3BaseUrl}/${PARENT_COMPANY_UUID}/images/logo.svg`;

console.log(hostname);
console.log(PARENT_COMPANY_ID);
console.log(PARENT_COMPANY_UUID);
console.log(TERMS_AND_CONDITIONS_HTML);
console.log(LOGO_SVG);
