import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { THEME_ICONS } from "../../styles/icons.js";

import { styled } from "@mui/material/styles";


const GeneralTruck = styled("i")(({ theme, backgroundColor }) => ({
    ...THEME_ICONS.generalTruck,
}));

const Text = styled(Typography)(({ theme, backgroundColor }) => ({
    fontSize: 12,
    fontWeight: 500,
}));



const TrucksRatio = (props) => {
    const { assignedTrucks, requestedTrucks, justifyContent } = props;

    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Text>{`${assignedTrucks} / ${requestedTrucks}`}</Text>
            <GeneralTruck />
        </Box>
    );
};

export default TrucksRatio;
