export const LOCATION_TYPE_QUARRY = "Quarry";
export const LOCATION_TYPE_DUMP = "Dump";
export const LOCATION_TYPE_TRACT = "Tract";
export const LOCATION_TYPE_PLANT = "Plant";
export const LOCATION_TYPE_JOB = "Job";
export const LOCATION_TYPE_MILL = "Mill";
export const LOCATION_TYPE_OTHER = "Other";
export const LOCATION_TYPES = [
    LOCATION_TYPE_QUARRY,
    LOCATION_TYPE_DUMP,
    LOCATION_TYPE_PLANT,
    LOCATION_TYPE_JOB,
    LOCATION_TYPE_OTHER,
];

export const ALERT_ZONE_TYPE_CIRCLE = "circle";
export const ALERT_ZONE_TYPE_POLYGON = "polygon";
export const getAlertZoneType = (zone) => zone.toLowerCase();

// params for drawing alert zones
export const alertZoneStrokeOpacity = 0.8;
export const alertZoneStrokeWeight = 2;
export const alertZoneFillOpacity = 0.35;

export const SITE_CIRCLE_WIDTH = 1.5;
export const SITE_CIRCLE_OPACITY = 0.2;
