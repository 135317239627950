import React from "react";
import { TableCell, TableSortLabel } from "@mui/material";

const TableHeaderSortingCell = ({
    sortParam,
    handleSort,
    align,
    label,
    minWidth,
    activeOrderParam,
    borderBottom,
    padding,
    className,
    direction,
}) => {
    return (
        <TableCell
            align={align}
            className={className}
            style={{
                minWidth: minWidth || 0,
                borderBottom: borderBottom,
                padding: padding,
            }}
            onClick={() => handleSort(sortParam)}
        >
            <TableSortLabel
                direction={direction ? direction : activeOrderParam?.includes("-") ? "desc" : "asc"}
                active={activeOrderParam?.replace("-", "") === sortParam}
            >
                {label}
            </TableSortLabel>
        </TableCell>
    );
};

export default TableHeaderSortingCell;
