import { createSlice } from "@reduxjs/toolkit";

import { resetState } from "./actions";

const initialState = {
    isMobileDimensions: false,
};
export const sitesSlice = createSlice({
    name: "utils",
    initialState,
    reducers: {
        setMobileMode(state) {
            state.isMobileDimensions = true;
        },
        setWebMode(state) {
            state.isMobileDimensions = false;
        },
    },
});

const actions = sitesSlice.actions;

export { actions };

export const selectIfIsMobileDimensions = (state) => state.utils.isMobileDimensions;
export const selectIfIsWebDimensions = (state) => !state.utils.isMobileDimensions;

export default sitesSlice.reducer;
