import { ENV_MODE_LOCAL, ENV_MODE_PROD, ENV_MODE_TEST } from "./constants";

export const hostname = window.location.hostname;

export const api = {
    localhost: "https://test-api.truckit.com:443/api",

    "i80-prod-frontend-fg-438946767.us-east-1.elb.amazonaws.com": "https://api.truckit.com/api",
    "order.i80concrete.com": "https://api.truckit.com/api",
    "order.i80materials.com": "https://api.truckit.com/api",

    "i80-test-front-128694205.us-east-1.elb.amazonaws.com": "https://test-api.truckit.com/api",
};

export const getApiBase = (env) => {
    switch (env) {
        case ENV_MODE_PROD:
            return "https://api.truckit.com/api";
        case ENV_MODE_TEST:
            return "https://test-api.truckit.com:443/api";
        case ENV_MODE_LOCAL:
            return "https://test-api.truckit.com:443/api";
        default:
            return "https://test-api.truckit.com:443/api";
    }
};

export const getWebsocketBase = (env) => {
    switch (env) {
        case ENV_MODE_PROD:
            return "wss://api.truckit.com/ws/notifications";
        case ENV_MODE_TEST:
            return "wss://test-api.truckit.com/ws/notifications";
        case ENV_MODE_LOCAL:
            return "wss://test-api.truckit.com/ws/notifications";
        default:
            return "wss://test-api.truckit.com/ws/notifications";
    }
};

export const apiBase = getApiBase(process.env.REACT_APP_ENVIRONMENT);
export const websocketBase = getWebsocketBase(process.env.REACT_APP_ENVIRONMENT);
