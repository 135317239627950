import React from "react";
import { Field } from "redux-form";
import { Validation } from "../../../../utils/validation";
import TextInputComponent from "../TextInput";

const PhoneInput = (props) => {
    const { name, label, fieldNote, className, isRequired } = props;
    let validation = [
        Validation.noSpecialSymbols,
        Validation.maxLength16,
        Validation.minLength6,
        Validation.phoneNumberSymbols,
    ];

    if (isRequired) {
        validation = [Validation.required, ...validation];
    }

    return (
        <Field
            type="text"
            name={name}
            label={label}
            className={className}
            placeholder="+1XXXXXXXXXX"
            validate={validation}
            fieldNote={fieldNote}
            component={TextInputComponent}
        />
    );
};

PhoneInput.defaultProps = {
    name: "phone",
    label: "Phone",
    fieldNote: "",
};

export const PRONE_FIELD_NOTE =
    "Used for system generated notifications. User can manage personal notification preferences in app";

export default PhoneInput;
