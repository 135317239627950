import React from "react";
import { useSelector } from "react-redux";

import { Container } from "@mui/material";

import MyTeamList from "./MyTeamList";
import MyTeamFilters from "./MyTeamFilters";
import SuccessMessage from "../../../components/core/messages/SuccessMessage";
import { useActions } from "../../../hooks";
import { selectMyTeamSuccessMessage } from "../../../slices/myCompanySlice";
import MyTeamTabs from "./MyTeamTabs";
import { selectIsAdmin } from "../../../slices/userSlice";

const MyTeam = () => {
    const { updateMyTeamSuccessMessage } = useActions();
    const successMessage = useSelector(selectMyTeamSuccessMessage);
    const isAdmin = useSelector(selectIsAdmin);

    return (
        <Container maxWidth={false} sx={{ paddingX: "8px" }} disableGutters>
            <MyTeamFilters />
            {isAdmin && <MyTeamTabs />}
            <MyTeamList />
            <SuccessMessage message={successMessage} onClose={() => updateMyTeamSuccessMessage(null)} />
        </Container>
    );
};

export default MyTeam;
