import asteriskOrange from "../assets/images/asterisk-orange.svg";
import iconAccount from "../assets/images/account-circle.svg";
import iconBell from "../assets/images/bell.svg";
import logo from "../assets/images/logo.svg";
import plus from "../assets/images/plus.svg";
import generalTrucks from "../assets/images/general-trucks.svg";
import generalTrucksDark from "../assets/images/general-trucks-dark.svg";
import generalTrucksWhite from "../assets/images/general-trucks-white.svg";
import success from "../assets/images/success.svg";
import { MAIN_THEME } from "./globalThemeConfig";
import { makeStyles } from "@mui/styles";

const defaultStyles = {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "inline-block",
};

export const THEME_ICONS = {
    logo: {
        ...defaultStyles,
        backgroundImage: `url(${logo})`,
        width: 101,
        height: 105,
        backgroundSize: "70%",
    },
    notifications: {
        ...defaultStyles,
        backgroundImage: `url(${iconBell})`,
        width: 20,
        height: 24.4,
        backgroundSize: "100%",
    },
    account: {
        ...defaultStyles,
        backgroundImage: `url(${iconAccount})`,
    },
    asteriskOrange: {
        ...defaultStyles,
        backgroundImage: `url(${asteriskOrange})`,
        width: 24,
        height: 24,
    },
    plus: {
        ...defaultStyles,
        backgroundImage: `url(${plus})`,
        width: 16,
        height: 16,
    },
    eventIcon: {
        width: 24,
        height: 24,
    },
    generalTruck: {
        ...defaultStyles,
        backgroundImage: `url(${generalTrucks})`,
        width: 24,
        height: 24,
    },
    generalTruckDark: {
        ...defaultStyles,
        backgroundImage: `url(${generalTrucksDark})`,
        width: 24,
        height: 24,
    },
    generalTruckWhite: {
        ...defaultStyles,
        backgroundImage: `url(${generalTrucksWhite})`,
        width: 24,
        height: 24,
    },
    success: {
        ...defaultStyles,
        backgroundImage: `url(${success})`,
        width: 250,
        height: 201,
    },
    tocIconsStyles: {
        color: MAIN_THEME.palette.secondary.dark,
        display: "block",
        width: 25,
        height: 25,
    },
};

export const ICONS_SITE = makeStyles((theme) => ({
    iconSite: {
        width: theme.icon.width,
        height: theme.icon.height,
        position: "relative",
        marginRight: 16,

        "&.pick-up": {
            color: theme.palette.general.softGreen,
        },
        "&.drop-off": {
            color: theme.palette.general.paleRed,
        },
    },
}));

export const TYPOGRAPHY_ICONS_SITE = makeStyles((theme) => {
    return {
        iconSite: {
            width: 15,
            height: 15,
            position: "relative",

            "&.pick-up": {
                color: theme.palette.general.softGreen,
            },
            "&.drop-off": {
                color: theme.palette.general.paleRed,
            },
        },
    };
});

export const MATERIAL_UI_ICONS_COLOR = "rgba(0, 0, 0, 0.54)";
